import { Spinner, useToast } from '@chakra-ui/react';
import {
  addBundleSKU,
  deleteBundleSKU,
  getBundle,
  updateBundle,
  updateBundleSKU,
} from 'api/Dashboard/bundles';
import BundleFormPage from 'components/Dashboard/SKUGroups/BundleFormPage';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { handleNotFoundPage } from 'utils/notFoundPage';
import { showErrorToast } from 'utils/toast';

const BundleDetails = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams();

  const {
    data: bundle = {},
    isLoading,
    refetch,
  } = useQuery(['bundle', id], () => getBundle(id), {
    onError: handleNotFoundPage,
  });

  const showSuccessToast = (message) => {
    toast({
      title: message,
      status: 'success',
      duration: 3000,
    });
  };

  const { mutate: updateBundleMutation, isLoading: isUpdateBundleLoading } =
    useMutation((payload) => updateBundle(id, payload), {
      onSuccess: (response) => {
        showSuccessToast(t('bundleUpdatedSuccessfully'));
        refetch();
      },
      onError: (axiosError) => {
        const errorMessage = axiosError?.response?.data?.errors[0];
        showErrorToast(errorMessage);
      },
    });

  const { mutate: addBundleSKUMutation, isLoading: isAddBundleSKULoading } =
    useMutation((payload) => addBundleSKU(id, payload), {
      onSuccess: () => {
        showSuccessToast(t('skuAddedSuccessfully'));
        refetch();
      },
    });

  const {
    mutate: deleteBundleSKUMutation,
    isLoading: isDeleteBundleSKULoading,
  } = useMutation((skuId) => deleteBundleSKU(id, skuId), {
    onSuccess: () => {
      showSuccessToast(t('skuDeletedSuccessfully'));
      refetch();
    },
  });

  const { mutate: updateBundleSKUMutation, isLoading: isSKUUpdateLoading } =
    useMutation(({ skuId, payload }) => updateBundleSKU(id, skuId, payload), {
      onSuccess: () => {
        showSuccessToast(t('skuUpdatedSuccessfully'));
        refetch();
      },
    });

  const isSKUChangeLoading =
    isSKUUpdateLoading || isAddBundleSKULoading || isDeleteBundleSKULoading;

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const bundleSkus = bundle.bundle_skus ?? [];

  const handleSKUSubmit = (sku, onSuccess) => {
    const isSKUAlreadyInBundle = bundleSkus.some(
      (item) => item.id === sku.sku_id
    );

    if (isSKUAlreadyInBundle) {
      return showErrorToast('skuAlreadyInBundle');
    }

    const payload = {
      sku_id: sku.sku_id,
      quantity: +sku.quantity,
    };

    addBundleSKUMutation(payload, {
      onSuccess,
    });
  };

  const handleSKUDelete = (skuId) => {
    const bundleSku = bundleSkus.find(
      (bundleSku) => bundleSku.sku.id === skuId
    );

    deleteBundleSKUMutation(bundleSku.id);
  };

  const handleSKUChange = (skuId, { key, value }) => {
    const bundleSku = bundleSkus.find(
      (bundleSku) => bundleSku.sku.id === skuId
    );

    updateBundleSKUMutation({
      skuId: bundleSku.id,
      payload: {
        [key]: value,
      },
    });
  };

  const handleSubmit = (data, onSuccess) => {
    const payload = {
      ...data,
      bundle_skus_attributes: undefined,
    };

    updateBundleMutation(payload, {
      onSuccess,
    });
  };

  return (
    <BundleFormPage
      onSubmit={handleSubmit}
      defaultValues={bundle}
      bundleSkus={bundleSkus}
      onSKUSubmit={handleSKUSubmit}
      onSKUDelete={handleSKUDelete}
      onSKUEdit={handleSKUChange}
      isFetching={isLoading}
      isSubmitting={isUpdateBundleLoading}
      isSKUChangeLoading={isSKUChangeLoading}
    />
  );
};

export default BundleDetails;
