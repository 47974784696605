import { ReactComponent as Reports } from 'assets/icons/diagram.svg';
import { ReactComponent as SkuGroup } from 'assets/icons/frame.svg';
import { ReactComponent as GridSquare } from 'assets/icons/grid-square.svg';
import { ReactComponent as Inbound } from 'assets/icons/inbound.svg';
import { ReactComponent as Addresses } from 'assets/icons/location-minus.svg';
import { ReactComponent as Outbound } from 'assets/icons/outbound.svg';
import { ReactComponent as Sku } from 'assets/icons/scanner.svg';
import { ReactComponent as Transportation } from 'assets/icons/truck-fast.svg';
import { ReactComponent as Suppliers } from 'assets/icons/users.svg';
import { ReactComponent as Warehouse } from 'assets/icons/warehouse.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as EcommerceIcon } from 'assets/icons/bag.svg';
import { isProduction } from 'utils/environment';

const pages = [
  { name: 'overview', icon: GridSquare, path: '/' },
  {
    name: 'warehouses',
    icon: Warehouse,
    isStrokeIcon: true,
    path: '/warehouses',
    pageTitle: 'warehousesAndQuotes',
    children: [
      {
        path: '/quotations',
      },
      {
        path: '/quote/:id',
        pageTitle: 'requestQuote',
      },
      {
        path: '/success',
        pageTitle: 'allWarehouses',
      },
    ],
  },
  {
    name: 'inbounds',
    icon: Inbound,
    isStrokeIcon: true,
    path: '/inbounds',
    children: [
      {
        pageTitle: 'inboundDetails',
        path: '/:id',
      },
      {
        pageTitle: 'inboundPages.requestNewInbound',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'inboundPages.requestNewInbound',
        path: '/:id/assign',
        isCreate: true,
      },
      {
        pageTitle: 'editInbound',
        path: '/:id/edit',
        isCreate: true,
      },
    ],
  },
  {
    name: 'outbounds',
    icon: Outbound,
    isStrokeIcon: true,
    path: '/outbounds',
    children: [
      {
        pageTitle: 'outboundDetails',
        path: '/:id',
      },
      {
        pageTitle: 'outboundPages.requestNewOutbound',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'outboundPages.requestNewOutbound',
        path: '/:id/assign',
        isCreate: true,
      },
      {
        pageTitle: 'editOutbound',
        path: '/:id/edit',
        isCreate: true,
      },
    ],
  },
  {
    name: 'transportations',
    icon: Transportation,
    isStrokeIcon: true,
    path: '/transportations',
    pageTitle: 'transportationPages.transportationOrders',
    children: [
      {
        pageTitle: 'requestNewTransportationOrder',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'transportationDetails',
        path: '/:id',
      },
      {
        pageTitle: 'transportationOrderSummary',
        path: '/:id/summary',
        isCreate: true,
      },
    ],
  },
  {
    name: 'skus',
    icon: Sku,
    path: '/skus',
    pageTitle: 'skus',
    children: [
      {
        pageTitle: 'skuDetails',
        path: '/:id',
      },
      {
        pageTitle: 'skusPages.createNewSKU',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'editSku',
        path: '/:id/edit',
        isCreate: true,
      },
      {
        pageTitle: 'associatedMovements',
        path: '/:id/associated-movements',
      },
    ],
  },
  {
    name: 'skuGroups',
    icon: SkuGroup,
    path: '/sku-groups',
    isAccordion: true,
    adminsOnly: true,
    children: [
      {
        name: 'permissionGroups',
        path: '/permission-groups',
        pageTitle: 'permissionGroups',
      },
      {
        name: 'permissionGroupsCreate',
        path: '/permission-groups/create',
        pageTitle: 'createNewPermissionGroup',
        isCreate: true,
        isInnerPage: true,
      },
      {
        name: 'permissionGroupDetails',
        path: '/permission-groups/:id',
        pageTitle: 'permissionGroupDetails',
        isCreate: true,
        isInnerPage: true,
      },
      {
        name: 'bundles',
        path: '/bundles',
        pageTitle: 'bundlePages.skuBundles',
        featureFlag: isProduction ? 'fulfillment_enabled' : undefined,
      },
      {
        name: 'bundlesCreate',
        path: '/bundles/create',
        pageTitle: 'bundlePages.createNewBundle',
        isCreate: true,
        isInnerPage: true,
      },
      {
        name: 'bundleDetails',
        path: '/bundles/:id',
        pageTitle: 'bundleDetails',
        isInnerPage: true,
      },
    ],
  },
  {
    name: 'fulfillment',
    icon: EcommerceIcon,
    path: '/fulfillment',
    isAccordion: true,
    featureFlag: isProduction ? 'fulfillment_enabled' : undefined,
    children: [
      {
        name: 'orders.orders',
        path: '/orders',
        pageTitle: 'orders.fulfillmentOrders',
      },
      {
        name: 'orders',
        path: '/orders/create',
        pageTitle: 'orders.requestNewOrder',
        isCreate: true,
        isInnerPage: true,
      },
      {
        name: 'orders.fulfillmentOrderDetails',
        path: '/orders/:id',
        pageTitle: 'orders.fulfillmentOrderDetails',
        isInnerPage: true,
      },
      {
        name: 'customers.customers',
        path: '/customers',
        pageTitle: 'fulfillmentCustomers',
      },
      {
        name: 'customers.fulfillmentCustomerDetails',
        path: '/customers/:id',
        pageTitle: 'customers.fulfillmentCustomerDetails',
        isInnerPage: true,
      },
      {
        name: 'integrations.integrations',
        pageTitle: 'integrations.fulfillmentIntegrations',
        path: '/integrations',
      },
      {
        name: 'integrations.integrationDetails',
        path: '/integrations/:id',
        pageTitle: 'integrations.integrationDetails',
        isInnerPage: true,
      },
    ],
  },
  {
    name: 'addresses',
    icon: Addresses,
    isStrokeIcon: true,
    path: '/addresses',
    children: [
      {
        pageTitle: 'addressesPage.addNewAddress',
        path: '/create',
      },
      {
        pageTitle: 'addressesPage.addressDetails',
        path: '/:id',
      },
    ],
  },
  { name: 'suppliers', icon: Suppliers, path: '/suppliers' },
  { name: 'Reports', icon: Reports, isStrokeIcon: true, path: '/reports' },
  {
    name: 'profile',
    path: '/profile',
    pageTitle: 'myProfile',
    isChildPage: true,
  },
  {
    name: 'settings',
    path: '/settings',
    icon: SettingsIcon,
    isStrokeIcon: true,
    bottom: true,
    noPadding: true,
    children: [
      {
        path: '/organization',
      },
      {
        path: '/user-management',
      },
      {
        path: '/account',
      },
      {
        path: '/push-notifications',
      },
      {
        path: '/billing',
      },
    ],
  },
];

export default pages;
