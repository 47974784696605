import useTable from 'hooks/useTable';
import { SearchInput } from '../TablePage';
import { useEffect } from 'react';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  useBoolean,
} from '@chakra-ui/react';
import { deliveryTypeEnum, truckSizeEnum } from 'constants/dashboard';
import Table from '../Table';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from '../Select';
import { ReactComponent as TruckFastIcon } from 'assets/icons/truck-fast.svg';
import { ReactComponent as LaborerIcon } from 'assets/icons/laborer.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { createColumnHelper } from '@tanstack/react-table';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { getCarriers, getTruckTypes } from 'api/Dashboard/transportation';
import { getFormSelectDefaultValue } from 'utils/select';
import AsyncSelect from '../AsyncSelect';
import { QuestionIcon } from '@chakra-ui/icons';
import useLanguage from 'hooks/useLanguage';
import { ReactComponent as PalletIcon } from 'assets/icons/pallet.svg';
import NoImgPlaceholder from 'assets/images/no-image-placeholder-small.png';

const StyledEditIcon = styled(EditIcon)`
  path {
    stroke: #03989e;
  }
`;

const StyledTruckFastIcon = styled(TruckFastIcon)`
  path {
    stroke: #4a5568;
  }
`;

const TransportationFormField = ({
  icon,
  label,
  value,
  showInput,
  onChange,
  min,
  errorMessage,
}) => {
  return (
    <Flex
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      alignItems="center"
      gap={showInput ? 4 : 8}
      fontWeight={500}
    >
      <HStack spacing={2}>
        {icon}
        <Text color="gray.700">{label}</Text>
      </HStack>

      {showInput ? (
        <Box maxWidth={{ base: '90px', md: '100px' }}>
          <DebouncedNumberInput
            defaultValue={value}
            onChange={onChange}
            size="md"
            min={min}
            errorMessage={errorMessage}
          />
        </Box>
      ) : (
        <Text color="gray.900">{value}</Text>
      )}
    </Flex>
  );
};

const transportationFormContainerStyles = {
  paddingBlock: { base: 6, md: 3 },
  paddingInline: { base: 3, md: 6 },
  backgroundColor: 'primary.50',
  marginTop: 6,
  borderRadius: 'lg',
  minHeight: '72px',
  alignItems: 'center',
};

const TransportationForm = ({
  defaultValues,
  onSubmit,
  showLaborers,
  isDeliveryTypeByPallet,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [showInputs, setShowInputs] = useBoolean(isDisabled ? false : true);

  const { handleSubmit, formState, control } = useForm({
    defaultValues,
  });

  const submit = (data) => {
    setShowInputs.off();
    onSubmit(data);
  };

  return (
    <SimpleGrid
      sx={transportationFormContainerStyles}
      as="form"
      onSubmit={handleSubmit(submit)}
      columns={{ base: 1, md: showLaborers ? 3 : 2 }}
      spacing={6}
      noValidate
    >
      <Controller
        control={control}
        name="num_of_trucks"
        rules={{
          required: 'thisFieldIsRequired',
          min: {
            value: 1,
            message: `${t('minIs')} 1`,
          },
        }}
        render={({ field, fieldState }) => (
          <TransportationFormField
            label={t(
              isDeliveryTypeByPallet ? 'numberOfPallets' : 'numberOfTrucks'
            )}
            icon={
              isDeliveryTypeByPallet ? <PalletIcon /> : <StyledTruckFastIcon />
            }
            value={defaultValues.num_of_trucks}
            onChange={field.onChange}
            showInput={showInputs}
            errorMessage={fieldState.error?.message}
            min={1}
          />
        )}
      />

      {showLaborers && (
        <Controller
          control={control}
          name="num_of_labors"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field }) => (
            <TransportationFormField
              label={t('numberOfLaborers')}
              icon={<LaborerIcon />}
              value={defaultValues.num_of_labors}
              onChange={field.onChange}
              showInput={showInputs}
              min={0}
            />
          )}
        />
      )}

      {!isDisabled && (
        <Flex justifyContent="flex-end">
          {showInputs ? (
            <Flex gap={4} width={{ base: 'full', md: 'auto' }}>
              <Button
                colorScheme="red"
                variant="ghost"
                bgColor="white"
                onClick={setShowInputs.off}
                flex={{ base: 1, md: 'auto' }}
              >
                {t('cancel')}
              </Button>
              <Button
                isDisabled={!formState.isDirty}
                colorScheme="primary"
                type="submit"
                flex={{ base: 1, md: 'auto' }}
              >
                {t('update')}
              </Button>
            </Flex>
          ) : (
            <Button
              onClick={setShowInputs.toggle}
              size="lg"
              leftIcon={<StyledEditIcon />}
              sx={{
                color: 'primary.500',
                colorScheme: 'primary',
                width: { base: 'full', md: 'auto' },
                backgroundColor: 'primary.200',
                _hover: {
                  backgroundColor: 'primary.300',
                },
              }}
            >
              {t('editQuantities')}
            </Button>
          )}
        </Flex>
      )}
    </SimpleGrid>
  );
};

const columnHelper = createColumnHelper();

const createURLQueryParams = (obj, additionalQueryString) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }

  const additionalParams = new URLSearchParams(additionalQueryString);
  for (const [key, value] of additionalParams.entries()) {
    params.append(key, value);
  }

  return params.toString();
};

const PriceCell = ({ price, label }) => {
  const { t } = useTranslation();
  const isNA = price === 'N/A';

  return (
    <Text fontWeight={500}>
      {isNA ? 'N/A' : `${price} ${t('SAR')} / ${t(label)}`}
    </Text>
  );
};

const TransportationRate = ({
  transportationOrderId,
  location,
  defaultValues,
  onSelectCarrier,
  onQuantitySubmit,
  isSelectCarrierLoading,
  onFiltersChange,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  const filterDefaultValues = {
    truck_type: defaultValues.truck_type,
    truck_size: defaultValues.truck_size,
    delivery_type: defaultValues.delivery_type,
  };

  const { formState, control, setValue } = useForm({
    defaultValues: filterDefaultValues,
  });

  const watchTransportationFilters = useWatch({ control });

  const watchDeliveryType = watchTransportationFilters.delivery_type;

  const selectedCarrierId = defaultValues.selectedCarrierId;

  const isDeliveryTypeByPallet =
    watchDeliveryType === deliveryTypeEnum.by_pallet;

  const truckSizeOptions = Object.keys(truckSizeEnum)
    .filter(
      (key) =>
        isDeliveryTypeByPallet
          ? key === 'ltl' // Only show LTL for by_pallet
          : key !== 'ltl' // Hide LTL for dedicated_truck
    )
    .map((key) => ({
      label: t(`transportationOrderEnums.${key}`),
      value: key,
    }));

  const deliveryTypeOptions = Object.keys(deliveryTypeEnum).map((key) => ({
    label: t(`transportationOrderEnums.${key}`),
    value: key,
  }));

  const {
    data: carriers = [],
    isLoading: isCarriersLoading,
    isRefetching,
    onSearchChange,
    onPaginationChange,
    onSortingChange,
    pagination,
    refetch: refetchCarriers,
  } = useTable({
    fetch: (tableParams) => {
      if (!location.originId || !location.destinationId) {
        return {};
      }

      const queryParams = createURLQueryParams(
        {
          ...watchTransportationFilters,
          ...(transportationOrderId && {
            transportation_order_id: transportationOrderId,
          }),
          origin_id: location.originId,
          destination_id: location.destinationId,
        },
        tableParams
      );

      return getCarriers(queryParams);
    },
    fetchKey: ['transportation-carriers'],
    searchKey: 'carrier_name_cont',
    disableURLParams: true,
    enabled: false,
  });

  const hasValidFilters = Object.values(watchTransportationFilters).every(
    (value) => value !== undefined && value !== null && value !== ''
  );

  useEffect(() => {
    if (hasValidFilters) {
      refetchCarriers();
    }
  }, [watchTransportationFilters, hasValidFilters, refetchCarriers]);

  useEffect(() => {
    onFiltersChange?.(watchTransportationFilters);
  }, [watchTransportationFilters, onFiltersChange]);

  const actionColumn = columnHelper.accessor('', {
    cell: ({ getValue, row }) => {
      const id = row.original.id;
      const isSelected = selectedCarrierId && selectedCarrierId === id;

      return (
        <Button
          variant="outline"
          color={isSelected ? 'red.600' : 'primary.700'}
          textTransform="capitalize"
          fontWeight={500}
          width={{ base: 'full', md: 'auto' }}
          onClick={() => {
            if (isSelected) {
              onSelectCarrier(null);
              return;
            }

            onSelectCarrier(id);
          }}
          isDisabled={selectedCarrierId && !isSelected}
          isLoading={isSelectCarrierLoading}
        >
          {t(isSelected ? 'unselect' : 'select')}
        </Button>
      );
    },
    header: t('actions'),
    meta: {
      fullWidthAction: true,
    },
    enableSorting: false,
  });

  const columns = [
    columnHelper.accessor('carrier_name', {
      cell: ({ getValue, row }) => {
        const carrierLogo = row.original.logo;

        return (
          <HStack spacing={3}>
            <Image
              boxSize="56px"
              borderRadius="xl"
              border="1px solid"
              borderColor="gray.200"
              alt={getValue()}
              src={carrierLogo}
              fallbackSrc={NoImgPlaceholder}
              backgroundColor={carrierLogo ? 'white' : 'gray.100'}
              objectFit={carrierLogo ? 'contain' : 'none'}
            />
            <Text fontWeight={600}>{getValue()}</Text>
          </HStack>
        );
      },
      header: t('carrierName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('insurance', {
      cell: ({ getValue }) => {
        return <Text fontWeight={500}>{t(!!getValue() ? 'yes' : 'no')}</Text>;
      },
      header: t('insurance'),
      enableSorting: false,
    }),
    columnHelper.accessor('sla', {
      cell: ({ getValue, row }) => {
        const slaList = getValue();

        if (!slaList?.length) {
          return <Text fontWeight={500}>N/A</Text>;
        }

        return (
          <UnorderedList
            sx={{
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              paddingLeft: 1,
              maxWidth: '100px',
              whiteSpace: 'wrap ',
            }}
          >
            {slaList?.map((slaText, index) => (
              <ListItem key={`${row.original.id}-sla-${index}`}>
                {slaText}
              </ListItem>
            ))}
          </UnorderedList>
        );
      },
      header: t('sla'),
      enableSorting: false,
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('truck_price', {
      cell: ({ getValue }) => (
        <Center>
          <PriceCell
            price={getValue()}
            label={isDeliveryTypeByPallet ? 'pallet' : 'truck'}
          />
        </Center>
      ),
      header: isDeliveryTypeByPallet ? t('palletPrice') : t('truckPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('labor_price', {
      cell: ({ getValue }) => (
        <Center>
          <PriceCell price={getValue()} label="laborer" />
        </Center>
      ),
      header: t('laborerPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    ...(isDisabled ? [] : [actionColumn]),
  ];

  const errors = {
    ...formState.errors,
  };

  const debouncedRefetch = useDebouncedCallback(() => {
    refetchCarriers();
  }, 300);

  const handleSearchChange = (newSearch) => {
    onSearchChange(newSearch);
    debouncedRefetch();
  };

  return (
    <Card size="lg">
      <CardHeader
        display="flex"
        justifyContent="space-between"
        alignItems={{ base: 'flex-end', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
        gap={{ base: 6, md: 4 }}
      >
        <Flex width="full" gap={1}>
          <SectionTitle title={t('transportationDetails')} hideDivider />

          <IconButton
            variant="ghost"
            size="sm"
            colorScheme="primary"
            isRound={true}
            icon={<QuestionIcon width={4} height={4} color="primary.600" />}
            onClick={() =>
              window.Intercom?.('showArticle', isAr ? 10295769 : 10295769)
            }
          />
        </Flex>

        {!isDisabled && (
          <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
            <SearchInput
              placeholder={t('searchByCarrierName')}
              onChange={(e) => {
                handleSearchChange(e.target.value);
              }}
              disabled={!hasValidFilters}
            />
          </Flex>
        )}
      </CardHeader>

      <CardBody>
        <Box marginBottom={5}>
          {!isDisabled && (
            <Text
              marginBottom={6}
              color="primary.700"
              fontSize="lg"
              fontWeight={500}
            >
              {t('pleaseSelectCarrierMessage')}
            </Text>
          )}

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
            <Controller
              control={control}
              name="delivery_type"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('deliveryType')}
                  placeholder={t('pleaseSelectTheDeliveryType')}
                  options={deliveryTypeOptions}
                  onChange={({ value }) => {
                    onChange(value);

                    if (value === deliveryTypeEnum.by_pallet) {
                      setValue('truck_size', 'ltl');
                      setValue('truck_type', null);
                    } else {
                      setValue('truck_size', null);
                      setValue('truck_type', null);
                    }
                  }}
                  defaultValue={getFormSelectDefaultValue(
                    filterDefaultValues.delivery_type,
                    deliveryTypeOptions
                  )}
                  error={errors?.vehicle_type}
                  isDisabled={isDisabled}
                  size="lg"
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="truck_size"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('truckSize')}
                  placeholder={t('pleaseSelectTheTruckSize')}
                  options={truckSizeOptions}
                  onChange={({ value }) => onChange(value)}
                  defaultValue={getFormSelectDefaultValue(
                    defaultValues.truck_size,
                    truckSizeOptions
                  )}
                  value={
                    getFormSelectDefaultValue(value, truckSizeOptions) ?? ''
                  }
                  error={errors?.truck_size}
                  isDisabled={
                    !watchDeliveryType ||
                    watchDeliveryType === deliveryTypeEnum.by_pallet ||
                    isDisabled
                  }
                  size="lg"
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="truck_type"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <AsyncSelect
                  placeholder={t('pleaseSelectTheTruckType')}
                  size="lg"
                  performSearchRequest={async () => {
                    const data = await getTruckTypes(
                      watchTransportationFilters.truck_size
                    );

                    const truckTypes = data?.truck_types;

                    const truckTypeOptions = truckTypes?.map((truckType) => ({
                      id: truckType,
                      name: t(truckType),
                    }));

                    return { data: truckTypeOptions };
                  }}
                  entityKey={[
                    'truckTypes',
                    watchTransportationFilters.truck_size,
                  ]}
                  label={t('truckType')}
                  onChange={(option) => onChange(option?.value)}
                  value={value}
                  error={errors?.truck_type}
                  disabled={
                    !watchTransportationFilters.truck_size || isDisabled
                  }
                  enableFetch
                  required
                />
              )}
            />
          </SimpleGrid>
        </Box>

        {hasValidFilters && (
          <Table
            data={carriers}
            columns={columns}
            onPaginationChange={(paginationParams) => {
              onPaginationChange(paginationParams);
              debouncedRefetch();
            }}
            onSortingChange={(sortParams) => {
              onSortingChange(sortParams);
              debouncedRefetch();
            }}
            pageCount={pagination.totalPages}
            pageIndex={pagination.pageIndex}
            isLoading={isCarriersLoading || isRefetching}
            hidePagination={isDisabled}
            enableSorting={!isDisabled}
          />
        )}

        {selectedCarrierId && (
          <Box marginBottom={4}>
            <TransportationForm
              isDisabled={isDisabled}
              defaultValues={{
                num_of_trucks: defaultValues.trucks,
                num_of_labors: defaultValues.laborers,
              }}
              onSubmit={onQuantitySubmit}
              showLaborers={
                filterDefaultValues.delivery_type ===
                deliveryTypeEnum.dedicated_truck
              }
              isDeliveryTypeByPallet={isDeliveryTypeByPallet}
            />
          </Box>
        )}

        <Text color="gray.700" fontSize="sm" fontWeight={500}>
          {t('carrierSelectionPricesNote')}
        </Text>
      </CardBody>
    </Card>
  );
};

export default TransportationRate;
