import {
  Button,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react';
import { ReactComponent as DotsHorizontalIcon } from 'assets/icons/dots-horizontal.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { ReactComponent as LocationIcon } from 'assets/icons/location-minus.svg';
import { createColumnHelper } from '@tanstack/react-table';

import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';

import { getAddresses } from 'api/Dashboard/addresses';
import useLanguage from 'hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { getMapsURL } from 'utils/location';

const StyledLocationIcon = styled(LocationIcon)`
  path {
    stroke: #fff;
  }
`;

const columnHelper = createColumnHelper();

const Addresses = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    selectedFilter,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getAddresses,
    fetchKey: 'addresses',
    searchKey: 'name_or_city_name_cont',
  });

  const columns = [
    columnHelper.accessor('id', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
    }),
    columnHelper.accessor('city', {
      cell: ({ getValue }) => {
        const city = getValue();
        return language === 'ar' ? city.nameLocale : city.name;
      },
      header: t('city'),
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<DotsHorizontalIcon />}
              variant="ghost"
            />
            <Portal>
              <MenuList>
                <MenuItem
                  icon={<EyeIcon width="24" height="24" />}
                  as="a"
                  href={getMapsURL(row.original)}
                  target="_blank"
                  textTransform="capitalize"
                >
                  {t('viewOnMap')}
                </MenuItem>
                <MenuItem
                  icon={<EditIcon width="24" height="24" />}
                  onClick={() => {
                    navigate(`/addresses/${row.original.id}`);
                  }}
                  textTransform="capitalize"
                >
                  {t('edit')}
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <TablePage
      data={data}
      columns={columns}
      selectedFilter={selectedFilter}
      pagination={pagination}
      title={t('addressesList')}
      // pageFilter={pageFilter}
      pageAction={
        <Button
          leftIcon={<StyledLocationIcon />}
          colorScheme="primary"
          textTransform="capitalize"
          size="lg"
          as={Link}
          to="create"
        >
          {t('addressesPage.addNewAddress')}
        </Button>
      }
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
      searchPlaceholder={t('addressesPage.tableSearchPlaceholder')}
      onSearchChange={onSearchChange}
    />
  );
};

export default Addresses;
