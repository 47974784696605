import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import ActionsButton from 'components/Dashboard/ActionsButton';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { Link, useNavigate } from 'react-router-dom';
import StatusTag from 'components/Dashboard/StatusTag';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { AddIcon } from '@chakra-ui/icons';
import { getOrders } from 'api/Dashboard/fulfillment/orders';
import { ReactComponent as EmptyOrdersIllustration } from 'assets/images/ecommerce-empty-table-illustration.svg';
import { ecommerceChannelOptions } from './OrderCreate';
import { getCustomers } from 'api/Dashboard/fulfillment/customers';
import { useQuery } from 'react-query';
import { valueFallback } from 'utils/string';
import { generateCustomerAddress } from 'utils/fulfillment';
import TruncatedText from 'components/shared/TruncatedText';
import usePaymentMethodOptions from 'hooks/usePaymentMethodOptions';
import useOrderStatusOptions from 'hooks/useOrderStatusOptions';

const columnHelper = createColumnHelper();

export const OrdersTablePage = ({
  data,
  pagination,
  pageAction,
  responseFilters,
  selectedFilter,
  onSearchChange,
  onFilterChange,
  onSortingChange,
  onPaginationChange,
  isLoading,
  isCustomerPage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { options: orderStatusOptions, colors: orderStatusColors } =
    useOrderStatusOptions();

  const { data: customersData } = useQuery(['fulfillment-customers'], () =>
    getCustomers('')
  );

  const paymentMethodOptions = usePaymentMethodOptions();

  const filters = [
    {
      label: t('createdAt'),
      name: 'created_at',
      type: 'date',
    },
    ...(isCustomerPage
      ? []
      : [
          {
            label: t('customer'),
            name: 'customer_id_in',
            options: customersData?.data?.map((customer) => ({
              label: `${customer.first_name} ${customer.last_name}`,
              value: customer.id,
            })),
            allLabel: 'Customers',
          },
        ]),
    {
      label: t('channel'),
      name: 'channel_in',
      options: ecommerceChannelOptions,
      allLabel: 'Channels',
    },
    {
      label: t('paymentMethod'),
      name: 'payment_method_in',
      options: paymentMethodOptions,
      allLabel: t('paymentMethods'),
    },
    {
      label: t('status'),
      name: 'status_in',
      options: orderStatusOptions,
    },
  ];

  const columns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ getValue }) => formatDate(getValue(), 'dd/MM/yyyy'),
      header: t('created'),
      meta: {
        fitContent: true,
      },
    }),
    ...(isCustomerPage
      ? []
      : [
          columnHelper.accessor('customer_name', {
            cell: ({ row }) => {
              const { customer_first_name, customer_last_name } = row.original;

              return `${customer_first_name} ${customer_last_name}`;
            },
            header: t('customer'),
            enableSorting: false,
          }),
        ]),
    columnHelper.accessor('destination', {
      cell: ({ row }) => {
        return generateCustomerAddress(row.original);
      },

      header: t('destination'),
      enableSorting: false,
    }),
    columnHelper.accessor('payment_method', {
      cell: ({ getValue }) => {
        const paymentMethod = paymentMethodOptions.find(
          (option) => option.value === getValue()
        );

        return (
          <Text as="span" textTransform="capitalize">
            {valueFallback(paymentMethod?.label)}
          </Text>
        );
      },
      header: t('paymentMethod'),
      enableSorting: false,
    }),
    columnHelper.accessor('channel', {
      cell: ({ getValue }) => (
        <Text as="span" textTransform="capitalize">
          {getValue()}
        </Text>
      ),
      header: t('channel'),
    }),
    columnHelper.accessor('channel_order_id', {
      cell: ({ getValue }) => {
        const value = getValue();

        return (
          <Tooltip label={value} isDisabled={!value}>
            <TruncatedText text={valueFallback(value)} maxWidth="100px" />
          </Tooltip>
        );
      },
      header: t('channelOrderId'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('external_reference_id', {
      cell: ({ getValue }) => {
        const value = getValue();

        return (
          <Tooltip label={value} isDisabled={!value}>
            <TruncatedText text={valueFallback(value)} maxWidth="100px" />
          </Tooltip>
        );
      },
      header: t('externalReferenceId'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => (
        <StatusTag
          colorScheme={orderStatusColors[getValue()]}
          textTransform="capitalize"
        >
          {t(`fulfillmentOrderStatus.${getValue()}`)}
        </StatusTag>
      ),
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => {
              navigate(`/fulfillment/orders/${row.original.id}`);
            }}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <TablePage
      data={data}
      columns={columns}
      filters={filters}
      selectedFilter={selectedFilter}
      pagination={pagination}
      title={t('orders.fulfillmentOrders')}
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      searchPlaceholder={t('orders.SearchPlaceholder')}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      pageAction={pageAction}
      emptyTable={
        <EmptyTable
          title={t('orders.emptyOrders')}
          subtitle={t('orders.emptyOrdersDescription')}
          illustration={<EmptyOrdersIllustration />}
        />
      }
      showEmptyInsideBody
      isFilterAsPopover
    />
  );
};

const Orders = () => {
  const { t } = useTranslation();

  const {
    data,
    isLoading,
    selectedFilter,
    responseFilters,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getOrders,
    fetchKey: 'fulfillment-orders',
    searchKey: 'identifier_or_channel_order_id_or_external_reference_id_cont',
    useRansack: true,
  });

  return (
    <OrdersTablePage
      data={data}
      pagination={pagination}
      pageAction={
        <Flex gap={4} flexFlow={{ base: 'column-reverse', md: 'row' }}>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="primary"
            textTransform="capitalize"
            size="lg"
            width={{ base: 'full', md: 'auto' }}
            as={Link}
            to="create"
          >
            {t('orders.createNewOrder')}
          </Button>
        </Flex>
      }
      responseFilters={responseFilters}
      selectedFilter={selectedFilter}
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
    />
  );
};

export default Orders;
