import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  HStack,
  Progress,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { showIntercom } from 'utils/intercom';

const LimitProgress = ({ label, value }) => {
  return (
    <VStack align="start" spacing={0} fontWeight={600} fontSize="sm">
      <Text>{label}</Text>
      <HStack w="100%">
        <Progress
          value={value}
          colorScheme="red"
          size="sm"
          w="100%"
          borderRadius="md"
        />
        <Text>{`${Math.round(value)}%`}</Text>
      </HStack>
    </VStack>
  );
};

const CapacityWarningModal = ({
  isOpen,
  onClose,
  capacityData = {},
  onProceed,
}) => {
  const { t } = useTranslation();

  const {
    sqm: { utilization_percentage: sqmUtilizationPercentage } = {},
    pallets: { utilization_percentage: palletsUtilizationPercentage } = {},
  } = capacityData;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="2xl">
        <ModalHeader>{t('capacityWarning')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} align="stretch">
            <Text fontWeight={500}>{t('capacityWarningMessage')}</Text>

            {palletsUtilizationPercentage >= 100 && (
              <LimitProgress
                label={t('pallets')}
                value={palletsUtilizationPercentage}
              />
            )}

            {sqmUtilizationPercentage >= 100 && (
              <LimitProgress
                label={t('sqM')}
                value={sqmUtilizationPercentage}
              />
            )}

            <Text mt={2}>
              <Trans
                i18nKey={t('capacityWarningContactSupport')}
                components={[
                  <Button
                    textTransform="none"
                    variant="link"
                    color="primary.500"
                    onClick={() => {
                      showIntercom();
                      onClose();
                    }}
                  />,
                ]}
              />
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button colorScheme="primary" onClick={onProceed}>
            {t('proceedAnyway')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CapacityWarningModal;
