import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

export const LineTabs = ({
  tabItems,
  tabListStyle,
  defaultIndex = 0,
  onChange,
}) => {
  return (
    <Tabs
      size={{ base: 'md', md: 'lg' }}
      colorScheme="primary"
      variant={{ base: 'soft-rounded', md: 'line' }}
      isLazy
      defaultIndex={defaultIndex}
      onChange={onChange}
    >
      <TabList sx={tabListStyle}>
        {tabItems.map((item, index) => (
          <Tab key={index} fontWeight={500}>
            {item.label}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabItems.map((item, index) => (
          <TabPanel key={index} paddingX={0}>
            {item.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default LineTabs;
