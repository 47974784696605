import { Center, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import useTable from 'hooks/useTable';
import { useTranslation } from 'react-i18next';
import Table from './Table';
import { getABTripChargeableActivities } from 'api/Dashboard/transportation';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import CollapsibleCard from './CollapsibleCard';
import { BillingSummary } from './Orders/ChargeableActivities';
import { useUser } from 'context/UserContext';

const columnHelper = createColumnHelper();

const TransportationChargeableActivities = ({ orderId }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const organizationId = user.organization.id;

  const {
    data = { transportation_chargeable_activities: [] },
    isLoading,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: (searchParams) =>
      getABTripChargeableActivities({
        organizationId,
        transportationOrderId: orderId,
        searchParams,
      }),
    fetchKey: ['ab-trip-chargeable-activities', orderId],
    disableURLParams: true,
  });

  const columns = [
    columnHelper.accessor('service_name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{t(getValue())}</Text>,
      header: t('serviceName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('service_unit_type', {
      cell: ({ getValue }) => <Center fontWeight={500}>{t(getValue())}</Center>,
      header: t('unit'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('price_per_unit', {
      cell: ({ getValue }) => (
        <Center fontWeight={500}>
          {getValue()}
          <Text as="span" fontWeight={600} marginInlineStart={1}>
            {t('SAR')}
          </Text>
        </Center>
      ),
      header: `${t('rate')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue }) => (
        <Text
          fontWeight={{ base: 400, md: 600 }}
          color={{ base: 'gray.900', md: 'gray.600' }}
          textAlign="center"
        >
          {getValue()}
        </Text>
      ),
      header: t('quantity'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('total_price', {
      cell: ({ getValue }) => (
        <Center fontWeight={500}>
          {getValue()}
          <Text as="span" fontWeight={600} marginInlineStart={1}>
            {t('SAR')}
          </Text>
        </Center>
      ),
      header: `${t('total')} (${t('SAR')})`,
      meta: {
        centerHeader: true,
      },
    }),
  ];

  return (
    <CollapsibleCard
      title={<SectionTitle title={t('chargeableActivities')} hideDivider />}
      body={
        <>
          <Table
            data={data.transportation_chargeable_activities}
            columns={columns}
            pagination={pagination}
            onPaginationChange={onPaginationChange}
            pageCount={pagination.totalPages}
            enableSorting={false}
            isLoading={isLoading}
          />

          <BillingSummary
            grossTotal={data.total_chargeable_activities}
            vat={data.total_vat}
            total={data.total_with_vat}
          />
        </>
      }
      isOpen
    />
  );
};

export default TransportationChargeableActivities;
