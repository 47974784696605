import { createBundle } from 'api/Dashboard/bundles';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import BundleFormPage from 'components/Dashboard/SKUGroups/BundleFormPage';
import { showErrorToast } from 'utils/toast';
import { useState } from 'react';

const BundleCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const queryClient = useQueryClient();
  const [bundleSkus, setBundleSkus] = useState([]);

  const { mutate: createBundleMutation, isLoading: isCreateBundleLoading } =
    useMutation(createBundle, {
      onSuccess: (response) => {
        navigate(`/sku-groups/bundles/${response.data.id}`);
        toast({
          title: t('bundleCreatedSuccessfully'),
          status: 'success',
        });
      },
      onError: (axiosError) => {
        const errorMessage = axiosError?.response?.data?.errors[0];
        showErrorToast(errorMessage);
      },
    });

  const handleSKUSubmit = (sku, onSuccess) => {
    const isSKUAlreadyInBundle = bundleSkus.some(
      (bundleSku) => bundleSku.sku.id === sku.sku_id
    );

    if (isSKUAlreadyInBundle) {
      return showErrorToast('skuAlreadyInBundle');
    }

    const selectedSKUData = queryClient
      .getQueriesData(['skus'])[0]?.[1]
      ?.data.find((skuData) => skuData.id === sku.sku_id);

    const selectedSKU = {
      sku: selectedSKUData,
      quantity: +sku.quantity,
    };

    setBundleSkus((prev) => [...prev, selectedSKU]);
    onSuccess?.();
  };

  const handleSKUDelete = (skuId) => {
    setBundleSkus((prev) =>
      prev.filter((bundleSku) => bundleSku.sku.id !== skuId)
    );
  };

  const handleSKUChange = (id, { key, value }) => {
    setBundleSkus((prev) =>
      prev.map((bundleSKU) =>
        bundleSKU.sku.id === id ? { ...bundleSKU, [key]: value } : bundleSKU
      )
    );
  };

  return (
    <BundleFormPage
      bundleSkus={bundleSkus}
      onSKUSubmit={handleSKUSubmit}
      onSKUDelete={handleSKUDelete}
      onSKUEdit={handleSKUChange}
      onSubmit={createBundleMutation}
      isSubmitting={isCreateBundleLoading}
    />
  );
};

export default BundleCreate;
