import api from 'utils/axios';

export const getBundles = async (searchParams) => {
  try {
    const response = await api.get(`/bundles?${searchParams}`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const createBundle = async (payload) => {
  try {
    const response = await api.post('/bundles', payload);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getBundle = async (id) => {
  try {
    const response = await api.get(`/bundles/${id}`);
    const { data } = response;

    return data?.data;
  } catch (error) {
    throw error;
  }
};

export const updateBundle = async (id, payload) => {
  try {
    const response = await api.put(`/bundles/${id}`, payload);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const addBundleSKU = async (id, bundle_sku) => {
  try {
    const response = await api.post(`/bundles/${id}/bundle_skus`, {
      bundle_sku,
    });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteBundleSKU = async (id, skuId) => {
  try {
    const response = await api.delete(`/bundles/${id}/bundle_skus/${skuId}`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateBundleSKU = async (id, skuId, bundle_sku) => {
  try {
    const response = await api.put(`/bundles/${id}/bundle_skus/${skuId}`, {
      bundle_sku,
    });

    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
