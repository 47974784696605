import { useState } from 'react';
import { Select } from 'chakra-react-select';
import { useQuery } from 'react-query';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { inputSizes } from 'constants/theme';
import OptionalIndicator from 'components/shared/OptionalIndicator';
import useLanguage from 'hooks/useLanguage';
import useDebounce from 'hooks/useDebounce';

const AsyncSelect = ({
  performSearchRequest,
  entityKey,
  error,
  required,
  label,
  onChange,
  defaultValue,
  value,
  isMulti,
  size = 'md',
  placeholder,
  searchKey = 'name_cont',
  arLabelKey,
  disabled,
  isClearable = true,
  enableFetch,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  const [inputText, setInputText] = useState('');
  const debouncedInputText = useDebounce(inputText, 500);

  const {
    isLoading,
    isRefetching,
    error: fetchError,
    data = {},
  } = useQuery(
    Array.isArray(entityKey)
      ? [...entityKey, debouncedInputText]
      : [entityKey, debouncedInputText],
    async () => {
      const urlSearchParams = new URLSearchParams({
        [`q[${searchKey}]`]: debouncedInputText,
        per_page: 200,
      }).toString();

      return await performSearchRequest(urlSearchParams);
    },
    {
      refetchOnMount: true,
      enabled: !disabled || enableFetch,
      refetchOnWindowFocus: false,
    }
  );

  const options =
    data.data?.map((option) => ({
      value: option.id,
      label: isAr ? option?.[arLabelKey ?? 'name'] : option.name,
    })) ?? [];

  const handleInputChange = (inputText, meta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      setInputText(inputText);
    }
  };

  // TODO: check better approach
  const currentValue =
    (isMulti
      ? options?.filter((option) => value?.some((val) => val === option.value))
      : options?.find((option) => option.value === value)) ?? '';

  return (
    <FormControl isInvalid={error} isRequired={!!required}>
      {label && (
        <FormLabel
          textTransform="capitalize"
          color="gray.700"
          requiredIndicator={false}
          optionalIndicator={<OptionalIndicator />}
        >
          {label}
        </FormLabel>
      )}

      <Select
        options={options}
        isClearable={isClearable}
        inputValue={inputText}
        onInputChange={handleInputChange}
        onChange={onChange}
        isLoading={isLoading || isRefetching}
        filterOption={null}
        styles={{
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 1401,
          }),
        }}
        chakraStyles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: 'gray.50',
            textTransform: 'capitalize',
            borderRadius: 'xl',
            fontSize: ['lg', 'md'].includes(size) ? 'md' : 'sm',
            minHeight: inputSizes[size],
            height: isMulti ? 'auto' : inputSizes[size],
            borderWidth: '1px',
            borderColor: 'gray.200',
            boxShadow: 'none',
            _invalid: {
              boxShadow: 'none',
              borderColor: 'red.500',
            },
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none !important',
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: 'sm',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            marginTop: 1,
            zIndex: 1401,
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            display: 'flex',
            flexDirection: 'column',
            paddingInline: 2,
            gap: 2,
            borderRadius: 'xl',
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 'md',
            textTransform: 'capitalize',
            fontSize: 'sm',
            _selected: {
              backgroundColor: 'primary.500',
              color: 'white',
            },
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            background: 'primary.500',
          }),
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
          }),
          multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
          }),
          loadingIndicator: (baseStyles) => ({
            ...baseStyles,
            color: 'primary.500',
          }),
        }}
        isMulti={isMulti}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={currentValue}
        isDisabled={disabled}
      />

      <FormErrorMessage>{t(error?.message)}</FormErrorMessage>
      {fetchError && (
        <Text color="red.500" fontSize="sm" mt={2}>
          Error in fetching data
        </Text>
      )}
    </FormControl>
  );
};

export default AsyncSelect;
