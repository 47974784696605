import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

const Link = ({ url, children, color = 'primary.700', ...props }) => (
  <ChakraLink
    textDecoration="underline"
    color={color}
    to={url}
    as={ReactRouterLink}
    {...props}
  >
    {children}
  </ChakraLink>
);

export default Link;
