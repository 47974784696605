import api from 'utils/axios';

export const getIntegrations = async () => {
  const response = await api.get('/integrations');
  return response?.data?.data;
};

export const getConnectedIntegrations = async () => {
  const response = await api.get('/organization_ecommerce_integrations');
  return response?.data?.data;
};

export const connectSallaIntegration = async (params) => {
  const response = await api.post('/salla/oauth/callback', params);
  return response?.data;
};

export const getIntegrationById = async (id) => {
  const response = await api.get(`/organization_ecommerce_integrations/${id}`);
  return response?.data?.data;
};

const getProductsURL = (integrationId) =>
  `/organization_ecommerce_integrations/${integrationId}/ecommerce_channel_skus`;

export const fetchProducts = async (
  integrationId,
  searchParams,
  isMapped = false
) => {
  const allParams = new URLSearchParams(searchParams);
  allParams.append(isMapped ? 'mapped' : 'unmapped', true);

  const response = await api.get(getProductsURL(integrationId), {
    params: allParams,
  });

  return response?.data;
};

export const fetchMappedProducts = (integrationId, searchParams) =>
  fetchProducts(integrationId, searchParams, true);

export const fetchUnmappedProducts = (integrationId, searchParams) =>
  fetchProducts(integrationId, searchParams, false);

export const mapProductToSKU = async (integrationId, productId, payload) => {
  const response = await api.put(
    `/organization_ecommerce_integrations/${integrationId}/ecommerce_channel_skus/${productId}`,
    { ecommerce_channel_sku: payload }
  );

  return response?.data;
};
