import { Box, Grid, GridItem, SimpleGrid, VStack } from '@chakra-ui/react';

import CurrentSpaceUsed from 'components/Dashboard/Overview/CurrentSpaceUsed';
import PendingOrders from 'components/Dashboard/Overview/PendingOrders';
import SpaceUtilization from 'components/Dashboard/Overview/SpaceUtilization';
import StorageOverview from 'components/Dashboard/Overview/StorageOverview';
import TableCard from 'components/Dashboard/Overview/TableCard';
import TotalMovements from 'components/Dashboard/Overview/TotalMovements';
import TotalSpaceRented from 'components/Dashboard/Overview/TotalSpaceRented';

import { getOverview } from 'api/Dashboard/overview';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useOutletContext } from 'react-router-dom';

import CardSlider from 'components/Dashboard/Overview/CardSlider';
import PageActions from 'components/Dashboard/Overview/PageActions';
import useIsMobile from 'hooks/useIsMobile';
import OverviewSkeleton from 'components/Dashboard/Overview/OverviewSkeleton';

export const cardSpacing = { base: 3, md: 5 };

const Overview = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { data: overviewData = {}, isLoading } = useQuery(
    'dashboard-overview',
    getOverview,
    {
      refetchOnMount: true,
    }
  );

  const { pageTitle } = useOutletContext();

  const hideOnMobileProps = {
    display: {
      base: 'none',
      md: 'block',
    },
  };

  return (
    <Box>
      <PageActions />

      {isLoading ? (
        <OverviewSkeleton />
      ) : (
        <>
          {isMobile && (
            <SimpleGrid columns={1} spacing={4} mb="4">
              <PageTitle title={pageTitle} />
              <CardSlider overviewData={overviewData} />
            </SimpleGrid>
          )}

          <VStack spacing={cardSpacing} alignItems="stretch">
            <Grid
              templateRows={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
              templateColumns="repeat(6, 1fr)"
              gap={cardSpacing}
            >
              <GridItem
                colSpan={{ base: 6, md: 2, sm: 3 }}
                {...hideOnMobileProps}
              >
                <TotalMovements
                  movements={overviewData.movements}
                  weeksMovements={overviewData.weeks_movements}
                />
              </GridItem>
              <GridItem
                colSpan={{ base: 6, md: 2, sm: 3 }}
                {...hideOnMobileProps}
              >
                <PendingOrders
                  pendingOrders={overviewData.pending_orders}
                  pendingOrdersPerWeek={overviewData.pending_orders_per_week}
                />
              </GridItem>
              <GridItem colSpan={{ base: 6, md: 2 }} {...hideOnMobileProps}>
                <StorageOverview
                  storageOverview={overviewData.storage_overview}
                />
              </GridItem>

              <GridItem colSpan={{ base: 6, md: 4 }} rowSpan={2}>
                <SpaceUtilization
                  monthlyUtilization={overviewData.monthly_utilization}
                />
              </GridItem>

              <GridItem
                colSpan={{ base: 6, md: 2, sm: 3 }}
                {...hideOnMobileProps}
              >
                <TotalSpaceRented
                  rentedSpaces={overviewData.rented_spaces}
                  rentedSpacesPerWeek={overviewData.rented_spaces_per_week}
                />
              </GridItem>
              <GridItem
                colSpan={{ base: 6, md: 2, sm: 3 }}
                {...hideOnMobileProps}
              >
                <CurrentSpaceUsed
                  utilizedSpaces={overviewData.utilized_spaces}
                  utilizedSpacesPercentage={
                    overviewData.utilized_spaces_percentage
                  }
                  utilizedSpacesPerWeek={overviewData.utilized_spaces_per_week}
                />
              </GridItem>
            </Grid>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={cardSpacing}>
              <TableCard
                title={t('inbounds')}
                icon={<ImportIcon />}
                data={overviewData.inbounds}
                to="inbounds"
              />

              <TableCard
                title={t('outbounds')}
                icon={<ExportIcon />}
                data={overviewData.outbounds}
                to="outbounds"
              />
            </SimpleGrid>
          </VStack>
        </>
      )}
    </Box>
  );
};

export default Overview;
