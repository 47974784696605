import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import Button from 'components/shared/Button';
import InputField from 'components/shared/Inputs/InputField';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { createMagicLink } from 'api/auth';
import { useToast } from '@chakra-ui/react';

const New = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: createMagicLinkMutation,
    isLoading: isCreateMagicLinkLoading,
    error: createMagicLinkError,
  } = useMutation(createMagicLink, {
    onSuccess: ({ data }) => {
      toast({
        title: t('emailSent'),
        status: 'success',
        duration: 3000,
      });
      navigate('/sign-in');
    },
  });

  const onSubmit = (data) => {
    const { email } = data;

    createMagicLinkMutation({
      email,
    });
  };

  return (
    <RegistrationLayout
      title={t('passwordlessLogin')}
      imageTitle={t('signInImageTitle')}
      imageContent={t('signInImageContent')}
      content={
        <form
          className="space-y-4"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <InputField
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            label={t('email')}
            placeholder="name@sirdab.co"
            register={register}
            errors={errors}
            required={'validation.emailRequired'}
            pattern={{
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'validation.invalidEmail',
            }}
          />

          {createMagicLinkError && (
            <div className="mt-1 text-sm text-red-700">
              {createMagicLinkError.response?.data?.error}
            </div>
          )}

          <Button
            label={t('sendLink')}
            type="submit"
            disabled={errors.agree?.message}
            isLoading={isCreateMagicLinkLoading}
          />

          <div className="mt-2">
            {`${t('Or')} `}
            <Link
              to="/sign-in"
              className="text-sm font-semibold text-primary hover:text-primary/[.85]"
            >
              {t('loginWithEmailAndPassword')}
            </Link>
          </div>
        </form>
      }
    />
  );
};

export default New;
