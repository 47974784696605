import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const generateRansackFilterString = (filter) => {
  if (!filter) {
    return '';
  }

  const ransackFilters = Object.entries(filter).map(([key, value]) => {
    if (!value) return '';

    // Handle date filters
    if (key === 'created_at') {
      const day = new Date(value);
      const nextDay = new Date(value);
      nextDay.setDate(nextDay.getDate() + 1);

      return [
        `q[created_at_gteq]=${encodeURIComponent(day.toISOString())}`,
        `q[created_at_lt]=${encodeURIComponent(nextDay.toISOString())}`,
      ].join('&');
    }

    if (Array.isArray(value)) {
      return value
        .map((val) => `q[${key}][]=${encodeURIComponent(val)}`)
        .join('&');
    }

    return `q[${key}][]=${encodeURIComponent(value)}`;
  });

  return ransackFilters
    .filter(Boolean) // Remove empty strings
    .join('&');
};

const createSearchParams = ({
  tableState,
  searchKey,
  pageFilterKey,
  useRansack,
}) => {
  const { pagination, filter, sorting, search } = tableState;
  const sort = sorting[0];

  const queryParams = {
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
    ...(useRansack ? {} : filter), // ransack filters will be generated as string separately
    ...(sort ? { 'q[s]': `${sort.id} ${sort.desc ? 'desc' : 'asc'}` } : {}),
    ...(search ? { [`q[${searchKey}]`]: search } : {}),
  };

  const urlSearchParams = new URLSearchParams(queryParams);
  const selectedPageFilters = filter?.[pageFilterKey];

  if (selectedPageFilters?.length) {
    urlSearchParams.delete(pageFilterKey);
    selectedPageFilters.forEach((pageFilterValue) => {
      urlSearchParams.append(pageFilterKey, pageFilterValue);
    });
  }

  const ransackFilters = generateRansackFilterString(filter);

  return (
    urlSearchParams.toString() +
    (useRansack && ransackFilters ? `&${ransackFilters}` : '')
  );
};

const useTable = ({
  fetch,
  fetchKey,
  pageFilterKey,
  searchKey,
  disableURLParams,
  defaultState = {},
  onFetchSuccess,
  onFetchError,
  filters = [],
  enabled = true,
  useRansack = false,
  keepPreviousData = false,
}) => {
  const [filterList, setFilterList] = useState(filters);

  const [tableState, setTableState] = useState({
    filter: undefined,
    sorting: defaultState.sorting ?? [],
    pagination: {
      pageIndex: defaultState.pageIndex ?? 0,
      pageSize: defaultState.pageSize ?? 5,
      totalPages: 1,
    },
    search: '',
    rowSelection: {},
  });

  const defaultSearchParams = createSearchParams({
    tableState,
    searchKey,
    pageFilterKey,
    useRansack,
  });

  const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);

  const { filter, sorting, pagination, search, rowSelection } = tableState;

  const fetchFn = () => {
    const newSearchParams = createSearchParams({
      tableState,
      searchKey,
      pageFilterKey,
      useRansack,
    });

    setTableState((prevState) => ({
      ...prevState,
      rowSelection: {},
    }));

    const currentSearchParams = searchParams.toString();

    if (!disableURLParams && newSearchParams !== currentSearchParams) {
      setSearchParams(newSearchParams, {
        replace: true,
      });
    }

    return fetch(newSearchParams);
  };

  const onSuccess = ({ meta, data } = {}) => {
    if (!meta) {
      return;
    }

    setTableState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        totalPages: meta?.pagination?.total_pages,
      },
    }));

    if (!filterList.length && meta.select_options) {
      setFilterList(meta.select_options);
    }

    onFetchSuccess?.(data);
  };

  const {
    data = {},
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    [
      ...(Array.isArray(fetchKey) ? fetchKey : [fetchKey]),
      pagination.pageIndex,
      pagination.pageSize,
      filter,
      sorting[0],
      search,
    ],
    fetchFn,
    {
      onSuccess,
      onError: onFetchError,
      cacheTime: 500, // TODO: Change to 5 minutes
      refetchOnMount: true,
      enabled,
      keepPreviousData,
    }
  );

  const onFilterChange = (newFilter) => {
    setTableState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        ...newFilter,
      },
      pagination: {
        ...prevState.pagination,
        pageIndex: 0,
      },
    }));
  };

  const onSortingChange = (newSorting) => {
    setTableState((prevState) => ({
      ...prevState,
      sorting: newSorting,
    }));
  };

  const onPaginationChange = (newPagination) => {
    setTableState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        ...newPagination,
      },
    }));
  };

  const onSearchChange = (newSearch) => {
    setTableState((prevState) => ({
      ...prevState,
      search: newSearch,
    }));
  };

  const onRowSelectionChange = (updater) => {
    setTableState((prevState) => ({
      ...prevState,
      rowSelection:
        typeof updater === 'function'
          ? updater(prevState.rowSelection)
          : updater,
    }));
  };

  return {
    data: data.data,
    meta: data.meta,
    isLoading,
    isRefetching,
    responseFilters: filterList,
    selectedFilter: tableState.filter,
    totalPages: tableState.pagination.totalPages,
    pagination: tableState.pagination,
    search: tableState.search,
    rowSelection,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    onSearchChange,
    onRowSelectionChange,
    clearRowSelection: () => onRowSelectionChange({}),
    refetch,
  };
};

export default useTable;
