import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import eye from 'assets/icons/eye.svg';
import eyeSlash from 'assets/icons/eye-slash.svg';

// Input using Tailwind UI

const InputField = ({
  id,
  name,
  type,
  label,
  register,
  errors,
  pattern,
  minLength,
  maxLength,
  required,
  placeholder = '',
  autoComplete = '',
  onChange = () => {},
  validate,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const error = errors[name];

  return (
    <div>
      <label htmlFor={id} className="block font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2 relative">
        <input
          id={id}
          name={name}
          type={showPassword ? 'text' : type}
          autoComplete={autoComplete}
          className={`block w-full rounded-md border-0 py-2 shadow-sm ring-1 ring-inset bg-gray-50 text-gray-800 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6 ltr:pr-10 rtl:pl-10 ${
            error
              ? 'ring-red-700 bg-red-50 text-red-700 focus:ring-red-700'
              : ''
          }`}
          onChange={onChange}
          placeholder={placeholder}
          {...register(name, {
            required,
            pattern,
            minLength,
            maxLength,
            validate,
          })}
        />
        {type === 'password' && (
          <button
            type="button"
            className="absolute inset-y-0 ltr:right-0 rtl:left-0 ltr:pr-3 rtl:pl-3 flex items-center cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            <img src={showPassword ? eye : eyeSlash} alt="password icon" />
          </button>
        )}
      </div>
      {error && (
        <div className="mt-1 text-sm text-red-700">
          {error.type === 'minLength'
            ? `${t(error.message)} ${minLength.value}`
            : error.type === 'maxLength'
            ? `${t(error.message)} ${maxLength.value}`
            : t(error.message)}
        </div>
      )}
    </div>
  );
};

export default InputField;
