import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  useDisclosure,
  Box,
  Text,
  HStack,
  Stack,
  Button,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Portal,
} from '@chakra-ui/react';
import { approveQuote, downloadQuoteAsPDF } from 'api/onboarding';
import PageDrawer from 'components/Dashboard/PageDrawer';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import Table from 'components/Dashboard/Table';
import { formatDate } from 'utils/date';
import { createColumnHelper } from '@tanstack/react-table';
import { getQuotations } from 'api/Dashboard/warehouses';
import useTable from 'hooks/useTable';
import StatusTag from 'components/Dashboard/StatusTag';
import TablePage from 'components/Dashboard/TablePage';
import useLanguage from 'hooks/useLanguage';
import Tooltip from 'components/shared/Tooltip';
import { InfoIcon } from '@chakra-ui/icons';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as WarehouseEmptyTable } from 'assets/images/warehouses-empty-table.svg';
import styled from '@emotion/styled';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ReactComponent as QuotationsIcon } from 'assets/icons/bill.svg';
import { ReactComponent as DotsHorizontalIcon } from 'assets/icons/dots-horizontal.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { openPDF } from 'utils/pdf';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

const columnHelper = createColumnHelper();

const WhiteQuotationsIcon = styled(QuotationsIcon)`
  path {
    stroke: #fff;
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  path {
    fill: #6b7280;
  }
`;
export const quoteStatuses = {
  pending: 'pending',
  merchant_approved: 'merchant_approved',
  sirdab_approved: 'sirdab_approved',
  active_quote: 'active_quote',
  inactive: 'inactive',
  declined: 'declined',
  expired: 'expired',
};

const statusTagColors = {
  pending: 'blue',
  merchant_approved: 'yellow',
  sirdab_approved: 'purple',
  active_quote: 'green',
  inactive: 'red',
  declined: 'red',
  expired: 'gray',
};

const quoteStatusTranslations = {
  active_quote: 'active',
  merchant_approved: 'approved',
  sirdab_approved: 'sirdabApproved',
};

const defaultSorting = [
  {
    id: 'created_at',
    desc: true,
  },
];

const Quotations = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { language } = useLanguage();
  const isAR = language === 'ar';
  const navigate = useNavigate();

  const { onRequestQuote, onShowRequestQuoteButton } = useOutletContext();

  const [quoteToManage, setQuoteToManage] = useState();

  const { mutate: approveQuoteMutation, isLoading: isApproveQuoteLoading } =
    useMutation(
      () =>
        approveQuote({
          quoteId: quoteToManage.id,
        }),
      {
        onSuccess: () => {
          refetch();
          onClose();
        },
      }
    );

  const [quoteIdToDownload, setQuoteIdToDownload] = useState(null);

  const { isLoading: isDownloadPDFLoading } = useQuery(
    ['download-quote-pdf', quoteIdToDownload?.id, quoteIdToDownload?.language],
    async () => {
      if (!quoteIdToDownload) return;

      return downloadQuoteAsPDF(
        quoteIdToDownload.id,
        quoteIdToDownload.language
      );
    },
    {
      enabled: !!quoteIdToDownload?.id,
      onSuccess: (data) => {
        openPDF(
          data,
          `quote-${quoteIdToDownload?.id}-${quoteIdToDownload?.language}`
        );
      },
      onError: (error) => console.error('Error downloading PDF', error),
      onSettled: () => setQuoteIdToDownload(null),
    }
  );

  const handleDownloadPDF = (id, language) => {
    setQuoteIdToDownload({ id, language });
  };

  const {
    data,
    isLoading: isLoadingTable,
    onSortingChange,
    onPaginationChange,
    pagination,
    refetch,
  } = useTable({
    fetch: getQuotations,
    fetchKey: 'quotations',
    defaultState: {
      sorting: defaultSorting,
    },
    onFetchSuccess: (data) => {
      onShowRequestQuoteButton(!!data?.length);
    },
  });

  const isApproveQuoteAllowed = quoteToManage?.status === 'pending';

  const detailsColumns = [
    columnHelper.accessor('created_at', {
      cell: ({ getValue }) => (
        <Text fontWeight={500}>{formatDate(getValue())}</Text>
      ),
      header: t('issueDate'),
      enableSorting: false,
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('date', {
      cell: ({ getValue }) => (
        <Text fontWeight={500}>{formatDate(getValue())}</Text>
      ),
      header: t('startDate'),
      enableSorting: false,
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('ending_date', {
      cell: ({ getValue }) => (
        <Text fontWeight={500}>{formatDate(getValue()) ?? '-'}</Text>
      ),
      header: t('endDate'),
      enableSorting: false,
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('', {
      cell: ({ row }) => {
        return (
          <HStack spacing={4} fontWeight={500}>
            <Stack>
              <Text as="span">{row.original.number_of_pallets}</Text>
              <Text as="span">{row.original.number_of_sqm}</Text>
            </Stack>

            <Stack>
              <Text as="span">{t('pallets')}</Text>
              <Text as="span">{t('sqM')}</Text>
            </Stack>
          </HStack>
        );
      },
      header: t('totalSpace'),
      enableSorting: false,
    }),
  ];

  const columns = [
    columnHelper.accessor('warehouse_name', {
      cell: ({ getValue }) => getValue(),
      header: t('warehouseName'),
      meta: {
        mobileHeader: 'left',
      },
      enableSorting: false,
    }),
    ...detailsColumns,
    columnHelper.accessor('status', {
      cell: ({ getValue }) => {
        const status = getValue();

        return (
          <StatusTag colorScheme={statusTagColors[status]} size="sm">
            {t(quoteStatusTranslations[status] ?? status)}
          </StatusTag>
        );
      },
      header: t('status'),
      enableSorting: false,
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<DotsHorizontalIcon />}
              variant="ghost"
              isDisabled={isDownloadPDFLoading}
            />
            <Portal>
              <MenuList>
                <MenuItem
                  icon={<EyeIcon width="20" height="20" />}
                  textTransform="capitalize"
                  onClick={() => {
                    setQuoteToManage(row.original);
                    onOpen();
                  }}
                >
                  {t('view')}
                </MenuItem>
                <MenuItem
                  icon={<EditIcon width="24" height="24" />}
                  textTransform="capitalize"
                  onClick={() => {
                    navigate(`/warehouses/quote/${row.original.id}`);
                  }}
                >
                  {t('edit')}
                </MenuItem>
                <MenuItem
                  icon={<StyledDownloadIcon width="20" height="20" />}
                  textTransform="capitalize"
                  onClick={() => handleDownloadPDF(row.original.id, 'en')}
                >
                  {t('downloadInEnglish')}
                </MenuItem>
                <MenuItem
                  icon={<StyledDownloadIcon width="20" height="20" />}
                  textTransform="capitalize"
                  onClick={() => handleDownloadPDF(row.original.id, 'ar')}
                >
                  {t('downloadInArabic')}
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  const servicesColumns = [
    columnHelper.accessor(isAR ? 'service_name_ar' : 'service_name', {
      cell: ({ getValue, row }) => {
        return (
          <HStack>
            <Text fontWeight={500}>{getValue()}</Text>
            <Tooltip
              label={
                isAR
                  ? row.original?.service_description_ar
                  : row.original?.service_description
              }
              icon={<InfoIcon boxSize="14px" color="#5B6B93" />}
              placement="top"
              zIndex={1402}
            />
          </HStack>
        );
      },
      header: t('service'),
    }),
    columnHelper.accessor('price_per_unit_cents', {
      cell: ({ getValue }) => (
        <Text fontWeight={500}>
          {getValue() / 100} {t('SAR')}
        </Text>
      ),
      header: t('rate'),
    }),
  ];

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        pagination={pagination}
        title={<SectionTitle title={t('quotations')} hideDivider />}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        isLoading={isLoadingTable}
        defaultSorting={defaultSorting}
        emptyTable={
          <Box>
            <EmptyTable
              illustration={<WarehouseEmptyTable />}
              title={t('emptyStateTitle', {
                value: t('quotations'),
              })}
              subtitle={t('requestQuoteAndComeBack')}
              actions={
                <Button
                  leftIcon={<WhiteQuotationsIcon />}
                  colorScheme="primary"
                  textTransform="capitalize"
                  size="lg"
                  width="full"
                  onClick={onRequestQuote}
                >
                  {t('requestQuote')}
                </Button>
              }
              noPadding
            />
          </Box>
        }
        hideSearch
      />

      <PageDrawer
        title={t('manageQuote', {
          value: quoteToManage?.warehouse_name,
        })}
        isOpen={isOpen}
        onClose={() => onClose()}
        isLoading={isApproveQuoteLoading}
        submitText="approve"
        onSubmit={() => approveQuoteMutation()}
        cancelText="close"
        hideSubmitButton={!isApproveQuoteAllowed}
      >
        <Stack spacing={10}>
          <Box>
            <Text
              fontSize="lg"
              fontWeight={600}
              mb={4}
              textTransform="capitalize"
            >
              {t('quoteDetails')}
            </Text>
            <Table
              data={[quoteToManage]}
              columns={detailsColumns}
              enableSorting={false}
              hidePagination
            />
          </Box>
          <Box>
            <Text
              fontSize="lg"
              fontWeight={600}
              mb={4}
              textTransform="capitalize"
            >
              {t('services')}
            </Text>
            <Table
              data={quoteToManage?.quote_line_items}
              columns={servicesColumns}
              enableSorting={false}
              hidePagination
            />
          </Box>
        </Stack>
      </PageDrawer>
    </>
  );
};

export default Quotations;
