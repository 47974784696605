import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import {
  Center,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  IconButton,
  useToast,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { createColumnHelper } from '@tanstack/react-table';
import ProductsTable from './ProductsTable';
import LinkSKUsDrawer from 'components/shared/LinkSKUsDrawer';
import SKUFormDrawer from 'components/Dashboard/SKUs/SKUFormDrawer';
import { mapProductToSKU } from 'api/Dashboard/integrations';
import { getSKUs, createSKU } from 'api/Dashboard/skus';
import { flattenErrorObject } from 'utils/formError';
import { ReactComponent as DotsHorizontalIcon } from 'assets/icons/dots-horizontal.svg';
import BundleListDrawer from './BundleListDrawer';
import NoImgPlaceholder from 'assets/images/no-image-placeholder-small.png';

const ActionsMenu = ({ items }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        variant="ghost"
        icon={<DotsHorizontalIcon />}
      />
      <Portal>
        <MenuList>
          {items?.map(({ onClick, label }, index) => (
            <MenuItem key={index} onClick={onClick} textTransform="capitalize">
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

const columnHelper = createColumnHelper();

const UnmappedProducts = ({
  integrationId,
  integrationName,
  data,
  search,
  isLoading,
  onSortingChange,
  onPaginationChange,
  pagination,
  onSearchChange,
  refetchData,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [selectedProduct, setSelectedProduct] = useState(null);

  const {
    isOpen: isMapProductOpen,
    onOpen: onMapProductOpen,
    onClose: onMapProductClose,
  } = useDisclosure();

  const {
    isOpen: isCreateSKUOpen,
    onOpen: onCreateSKUOpen,
    onClose: onCreateSKUClose,
  } = useDisclosure();

  const {
    isOpen: isMapBundleOpen,
    onOpen: onMapBundleOpen,
    onClose: onMapBundleClose,
  } = useDisclosure();

  const { mutate: mapProductMutation, isLoading: isMappingToSKU } = useMutation(
    (payload) => mapProductToSKU(integrationId, selectedProduct.id, payload),
    {
      onSuccess: () => {
        refetchData();
        onMapProductClose();
        onMapBundleClose();
        toast({
          title: t('integrations.productMappedSuccessfully'),
          status: 'success',
          duration: 3000,
        });
      },
    }
  );

  const handleMapProductMutation = (id, isBundle = false) => {
    if (isBundle) {
      mapProductMutation({ bundle_id: id });
    } else {
      mapProductMutation({ sku_id: id });
    }
  };

  const {
    mutate: createSKUMutation,
    isLoading: isCreatingSKU,
    error: createSKUError,
  } = useMutation((skuData) => createSKU(skuData), {
    onSuccess: (data) => {
      const skuId = data?.data?.id;

      handleMapProductMutation(skuId);
      refetchData();
      onCreateSKUClose();
    },
  });

  const handleMapProductOpen = (product) => {
    setSelectedProduct(product);
    onMapProductOpen();
  };

  const handleMapBundleOpen = (product) => {
    setSelectedProduct(product);
    onMapBundleOpen();
  };

  const handleCreateSKUOpen = (product) => {
    setSelectedProduct(product);
    onCreateSKUOpen();
  };

  const handleSubmitNewSKU = (payload) => {
    createSKUMutation(payload);
  };

  const getUnmappedProductActions = (product) => [
    {
      label: t('integrations.mapToExistingSKU'),
      onClick: () => handleMapProductOpen(product),
    },
    {
      label: t('skusPages.createNewSKU'),
      onClick: () => handleCreateSKUOpen(product),
    },
    {
      label: t('integrations.mapToExistingBundle'),
      onClick: () => handleMapBundleOpen(product),
    },
  ];

  const skuColumns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: ({ getValue, row }) => {
          const image = row.original.image;

          return (
            <HStack spacing={3}>
              <Image
                boxSize="48px"
                borderRadius="xl"
                border="1px solid"
                borderColor="gray.200"
                alt={getValue()}
                src={image}
                fallbackSrc={NoImgPlaceholder}
                backgroundColor={image ? 'white' : 'gray.100'}
                objectFit={image ? 'contain' : 'none'}
              />
              <Text fontWeight={600}>{getValue()}</Text>
            </HStack>
          );
        },
        header: t('skuName'),
        meta: {
          mobileHeader: 'left',
          hideHash: true,
        },
      }),
      columnHelper.accessor('system_sku', {
        cell: ({ getValue }) => getValue(),
        header: t('sirdabSKU'),
      }),
      columnHelper.accessor('merchant_sku', {
        cell: ({ getValue }) => getValue(),
        header: t('merchantSKU'),
      }),
    ],
    [t]
  );

  const additionalColumns = [
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const actions = getUnmappedProductActions(row.original);

        return (
          <Center>
            <ActionsMenu items={actions} />
          </Center>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <>
      <ProductsTable
        integrationName={integrationName}
        data={data}
        isLoading={isLoading}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        pagination={pagination}
        onSearchChange={onSearchChange}
        additionalColumns={additionalColumns}
        search={search}
      />
      {isMapProductOpen && (
        <LinkSKUsDrawer
          fetchKey="skus"
          fetchFunction={getSKUs}
          entityId={selectedProduct?.id}
          columns={skuColumns}
          isOpen={isMapProductOpen}
          onClose={onMapProductClose}
          title={selectedProduct?.channel_product_name}
          onSubmit={({ sku_ids }) => handleMapProductMutation(sku_ids[0])}
          isLoading={isMappingToSKU}
          singleSelection
          enableSorting
        />
      )}
      {isMapBundleOpen && (
        <BundleListDrawer
          isOpen={isMapBundleOpen}
          onClose={onMapBundleClose}
          title={selectedProduct?.channel_product_name}
          onSubmit={(data) => handleMapProductMutation(data.bundle_id, true)}
          isLoading={isMappingToSKU}
        />
      )}

      {isCreateSKUOpen && (
        <SKUFormDrawer
          isOpen={isCreateSKUOpen}
          onClose={onCreateSKUClose}
          onSubmit={handleSubmitNewSKU}
          backendErrors={flattenErrorObject(
            createSKUError?.response?.data?.errors
          )}
          isLoading={isCreatingSKU}
        />
      )}
    </>
  );
};

export default UnmappedProducts;
