import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import {
  getLocalStorageUser,
  setLocalStorageUser,
  deleteLocalStorageUser,
} from 'utils/user';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => getLocalStorageUser());

  useEffect(() => {
    const handleStorageChange = () => {
      setUser(getLocalStorageUser());
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const updateUser = (newUser) => {
    setLocalStorageUser(newUser);
    setUser(newUser);
  };

  const clearUser = () => {
    deleteLocalStorageUser();
    setUser({});
  };

  const hasUser = useMemo(
    () => user && typeof user === 'object' && Object.keys(user).length > 0,
    [user]
  );

  const isAdmin = useMemo(() => user?.role === 'admin', [user]);

  const value = {
    user,
    updateUser,
    clearUser,
    hasUser,
    isAdmin,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default UserContext;
