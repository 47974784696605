import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import VerifyOTPForm from 'components/VerifyOTP/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { getUser } from 'api/auth';
import { useQuery } from 'react-query';
import { useToast } from '@chakra-ui/react';
import { useUser } from 'context/UserContext';

const VerifyOTP = () => {
  const { confirmOTP, isConfirmOtpLoading, confirmOtpError } = useAuth();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { updateUser, user, hasUser } = useUser();

  const email = location.state?.email || t('yourEmail');

  useEffect(() => {
    if (hasUser && user?.otp_verified_at) {
      navigate('/', { replace: true });
    }
  }, [hasUser, navigate, user?.otp_verified_at]);

  const { refetch: getUserQuery } = useQuery('user-me', getUser, {
    onSuccess: (data) => {
      updateUser(data);

      if (data?.otp_verified_at) {
        toast({
          title: t('yourEmailHasBeenVerified'),
          status: 'success',
          duration: 5000,
        });
        navigate('/', { replace: true });
      }
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const handleSubmit = ({ verificationCode }) => {
    confirmOTP(verificationCode, {
      onSuccess: getUserQuery,
    });
  };

  return (
    <RegistrationLayout
      title={t('enterVerificationCode')}
      subtitle={`${t('weSentVerificationCodeTo')} ${email}`}
      content={
        <VerifyOTPForm
          onSubmit={handleSubmit}
          responseError={confirmOtpError}
          isLoading={isConfirmOtpLoading}
        />
      }
      showLogoutButton
    />
  );
};

export default VerifyOTP;
