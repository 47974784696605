import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Image,
  Text,
  HStack,
  Stack,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import {
  getConnectedIntegrations,
  getIntegrations,
} from 'api/Dashboard/integrations';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import sallaLogo from 'assets/images/salla-logo.png';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { Link } from 'react-router-dom';

const StatusIndicator = ({ connected }) => {
  const { t } = useTranslation();

  return (
    <HStack spacing={2}>
      <Box
        width="12px"
        height="12px"
        bg={connected ? 'green.500' : 'gray.500'}
        borderRadius="full"
      />
      <Text
        fontWeight={500}
        color="gray.600"
        textTransform="capitalize"
        fontSize="sm"
      >
        {t(connected ? 'integrated' : 'notIntegrated')}
      </Text>
    </HStack>
  );
};

const IntegrationsSkeleton = () => {
  return (
    <Stack spacing={5}>
      <SkeletonText skeletonHeight={4} noOfLines={1} width="220px" />
      <Flex gap={4} flexWrap="wrap">
        {[1, 2].map((key) => (
          <Card
            key={key}
            maxW="347px"
            w="full"
            variant="outline"
            borderRadius="2xl"
          >
            <CardBody padding={6}>
              <Flex gap={3} flexDirection="column" height="full">
                <HStack gap={4} alignItems="flex-start">
                  <Skeleton boxSize="56px" borderRadius="lg" />
                  <Stack spacing={3}>
                    <SkeletonText
                      width="50px"
                      noOfLines={1}
                      skeletonHeight={4}
                    />
                    <SkeletonText
                      width="80px"
                      noOfLines={1}
                      skeletonHeight={3}
                    />
                  </Stack>
                </HStack>

                <Flex
                  flex={1}
                  flexDirection="column"
                  gap={6}
                  justifyContent="space-between"
                >
                  <SkeletonText noOfLines={4} />
                  <Skeleton height="48px" width="full" />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        ))}
      </Flex>
    </Stack>
  );
};

const logos = {
  salla: sallaLogo,
};

const IntegrationCard = ({
  id,
  name,
  website_url,
  connected,
  integrationKey,
  hideStatus,
}) => {
  const { t } = useTranslation();

  const logo = logos[integrationKey];
  const description = `integrationDescription.${integrationKey}`;

  return (
    <Card
      key={id}
      maxW={{ base: 'full', sm: '347px' }}
      w="full"
      variant="outline"
      borderRadius="2xl"
    >
      <CardBody padding={6}>
        <Flex gap={3} flexDirection="column" height="full">
          <HStack gap={4} alignItems={hideStatus ? 'center' : 'flex-start'}>
            <Image
              src={logo}
              alt={`${name} logo`}
              boxSize="56px"
              objectFit="contain"
              borderRadius="lg"
            />
            <Stack spacing={1}>
              <Text fontWeight={500} color="gray.900">
                {name}
              </Text>
              {!hideStatus && <StatusIndicator connected={connected} />}
            </Stack>
          </HStack>

          <Flex
            flex={1}
            flexDirection="column"
            gap={6}
            justifyContent="space-between"
          >
            <Text color="gray.700" fontSize="sm" fontWeight={500}>
              {t(description)}
            </Text>
            <Button
              w="full"
              colorScheme="primary"
              variant={connected ? 'outline' : 'solid'}
              size="lg"
              as={connected ? Link : 'a'}
              href={connected ? undefined : website_url}
              to={connected ? `/fulfillment/integrations/${id}` : undefined}
              target={connected ? undefined : '_blank'}
              isDisabled={!connected}
            >
              {t(connected ? 'manage' : 'comingSoon')}
            </Button>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

const IntegrationList = ({ title, children }) => {
  return (
    <Stack spacing={5}>
      <Text color="gray.800" fontSize="lg" fontWeight={700}>
        {title}
      </Text>
      <Flex gap={4} flexWrap="wrap">
        {children}
      </Flex>
    </Stack>
  );
};

const AllIntegrations = () => {
  const { t } = useTranslation();

  const { data: integrations, isLoading } = useQuery(
    'integrations',
    getIntegrations
  );

  if (isLoading) {
    return <IntegrationsSkeleton />;
  }

  return (
    <IntegrationList title={t('integrations.availableIntegrations')}>
      {integrations?.map((integration) => (
        <IntegrationCard
          {...integration}
          integrationKey={integration.key}
          hideStatus
        />
      ))}
    </IntegrationList>
  );
};

const ConnectedIntegrations = () => {
  const { t } = useTranslation();

  const { data: connectedIntegrations = [], isLoading } = useQuery(
    'connected-integrations',
    getConnectedIntegrations
  );

  if (isLoading) {
    return <IntegrationsSkeleton />;
  }

  if (connectedIntegrations?.length === 0) {
    return null;
  }

  return (
    <IntegrationList title={t('integrations.connectedIntegrations')}>
      {connectedIntegrations?.map(({ id, integration, connected }) => (
        <IntegrationCard
          {...integration}
          id={id}
          integrationKey={integration.key}
          connected={connected}
        />
      ))}
    </IntegrationList>
  );
};

const Integrations = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={{ base: 4, md: 6 }} height="full">
      <PageTitle
        title={t('integrations.fulfillmentIntegrations')}
        display={{ base: 'inline', md: 'none' }}
        mt={3}
      />

      <Stack spacing={8}>
        <ConnectedIntegrations />

        <AllIntegrations />
      </Stack>
    </Stack>
  );
};

export default Integrations;
