import { useTranslation } from 'react-i18next';
import SignInForm from 'components/SignIn/Form';
import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';

import useAuth from 'hooks/useAuth';

const SignIn = () => {
  const { t } = useTranslation();

  const { signIn, isSignInLoading, signInError } = useAuth();

  const handleSubmit = (data) => {
    const { email, password } = data;

    signIn({
      email,
      password,
    });
  };

  return (
    <RegistrationLayout
      title={t('welcomeBack')}
      imageTitle={t('signInImageTitle')}
      imageContent={t('signInImageContent')}
      content={
        <SignInForm
          onSubmit={handleSubmit}
          isLoading={isSignInLoading}
          responseError={signInError}
        />
      }
    />
  );
};

export default SignIn;
