import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ForgotPasswordForm from 'components/ForgotPassword/Form';
import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import useAuth from 'hooks/useAuth';
import RegistrationHeader from 'components/shared/Registration/RegistrationHeader';
import forgotPasswordSuccess from 'assets/images/forgot-password-success.svg';
import Button from 'components/shared/Button';

const ForgotPassword = () => {
  const {
    forgetPassword,
    isForgetPasswordLoading,
    forgetPasswordError,
    isForgetPasswordSuccess: isSuccess,
  } = useAuth();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (data) => {
    const { email } = data;

    forgetPassword({ user: { email } });
  };

  return (
    <RegistrationLayout
      title={isSuccess ? '' : t('resetYourPassword')}
      subtitle={isSuccess ? '' : t('resetPasswordSubtitle')}
      imageTitle={t('signInImageTitle')}
      imageContent={t('signInImageContent')}
      content={
        isSuccess ? (
          <div className="text-center">
            <img
              className="h-50 w-50 mb-4 inline"
              src={forgotPasswordSuccess}
              alt="Forgot password success"
            />
            <RegistrationHeader
              title={t('checkYourEmail')}
              subtitle={t('forgotPasswordSuccessSubtitle')}
            />
            <Button
              label={t('backToLogin')}
              className="mt-4"
              onClick={() => {
                navigate('/sign-in');
              }}
            />
          </div>
        ) : (
          <ForgotPasswordForm
            onSubmit={handleSubmit}
            isLoading={isForgetPasswordLoading}
            responseError={forgetPasswordError?.response?.data?.errors?.email}
          />
        )
      }
    />
  );
};

export default ForgotPassword;
