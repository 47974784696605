import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import StatusTag from 'components/Dashboard/StatusTag';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { AddIcon } from '@chakra-ui/icons';
import { deleteReport, getReports } from 'api/Dashboard/reports';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { useMutation } from 'react-query';
import ReportDrawer from 'components/Dashboard/Reports/ReportDrawer';
import ExpiryReportDrawer from 'components/Dashboard/Reports/ExpiryReportDrawer';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as ReportsEmptyTable } from 'assets/images/reports-empty-table.svg';
import useIsMobile from 'hooks/useIsMobile';
import { valueFallback } from 'utils/string';

const reportStatusColors = {
  in_progress: 'primary',
  done: 'green',
};

const reportTypeColors = {
  inbounds: 'blue',
  outbounds: 'green',
  skus: 'purple',
  inventories: 'yellow',
  expiry: 'red',
};

const defaultSorting = [
  {
    id: 'created_at',
    desc: true,
  },
];

const columnHelper = createColumnHelper();

const Reports = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = useIsMobile();

  const {
    isOpen: isSKUReportOpen,
    onOpen: onSKUReportOpen,
    onClose: onSKUReportClose,
  } = useDisclosure();

  const {
    isOpen: isInboundReportOpen,
    onOpen: onInboundOpen,
    onClose: onInboundClose,
  } = useDisclosure();

  const {
    isOpen: isOutboundReportOpen,
    onOpen: onOutboundOpen,
    onClose: onOutboundClose,
  } = useDisclosure();

  const {
    isOpen: isSpaceUtilizationReportOpen,
    onOpen: onSpaceUtilizationOpen,
    onClose: onSpaceUtilizationClose,
  } = useDisclosure();

  const {
    isOpen: isExpiryReportOpen,
    onOpen: onExpiryOpen,
    onClose: onExpiryClose,
  } = useDisclosure();

  const {
    isOpen: isChargeableActivitiesOpen,
    onOpen: onChargeableActivitiesOpen,
    onClose: onChargeableActivitiesClose,
  } = useDisclosure();

  const {
    data,
    isLoading,
    selectedFilter,
    responseFilters,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
    refetch,
  } = useTable({
    fetch: getReports,
    fetchKey: 'reports',
    searchKey: 'name_cont',
    defaultState: {
      sorting: defaultSorting,
    },
  });

  const { mutate: deleteReportMutation, isLoading: isDeleteReportLoading } =
    useMutation(deleteReport, {
      onSuccess: (success) => {
        toast({
          title: t('reportDeletedSuccessfully'),
          status: 'success',
          duration: 3000,
        });
        refetch();
      },
    });

  const filters = [
    {
      label: t('reportType'),
      name: 'report_type',
      options: responseFilters.report_type?.map((type) => ({
        label: t(`${type}`),
        value: type,
      })),
    },
    {
      label: t('status'),
      name: 'status',
      options: responseFilters.status?.map((status) => ({
        label: t(`${status}`),
        value: status,
      })),
    },
  ];

  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('name'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('report_type', {
      cell: ({ getValue }) => {
        const type = getValue();

        return (
          <StatusTag colorScheme={reportTypeColors[type]}>{t(type)}</StatusTag>
        );
      },
      header: t('reportType'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('start_date', {
      cell: ({ getValue }) =>
        valueFallback(formatDate(getValue(), 'dd/MM/yyyy')),
      header: t('startDate'),
    }),
    columnHelper.accessor('end_date', {
      cell: ({ getValue }) =>
        valueFallback(formatDate(getValue(), 'dd/MM/yyyy')),
      header: t('endDate'),
    }),
    columnHelper.accessor('created_at', {
      cell: ({ getValue }) => formatDate(getValue(), 'dd/MM/yyyy hh:mm aa'),
      header: t('requestedAt'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => {
        const status = getValue();

        return (
          <StatusTag colorScheme={reportStatusColors[status]}>
            {t(`${status}`)}
          </StatusTag>
        );
      },
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const fileUrl = row.original.file_url;

        const handleDelete = () => {
          deleteReportMutation(row.original.id);
        };

        if (isMobile) {
          return (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<DotsVerticalIcon />}
                size="sm"
              />
              <MenuList>
                <MenuItem
                  textTransform="capitalize"
                  as="a"
                  href={fileUrl}
                  download={fileUrl}
                  cursor="pointer"
                >
                  {t('download')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color="red.500"
                  onClick={handleDelete}
                >
                  {t('delete')}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }

        return (
          <fieldset disabled={isDeleteReportLoading ? 'disabled' : undefined}>
            <Flex gap={3} justifyContent="center">
              <Button
                variant="outline"
                color="gray.600"
                textTransform="capitalize"
                fontWeight={500}
                size="sm"
                as="a"
                href={fileUrl}
                download={fileUrl}
                cursor="pointer"
              >
                {t('download')}
              </Button>
              <Button
                variant="outline"
                color="red.600"
                textTransform="capitalize"
                fontWeight={500}
                size="sm"
                onClick={handleDelete}
              >
                {t('delete')}
              </Button>
            </Flex>
          </fieldset>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  const handleGenerateReportSuccess = () => {
    toast({
      title: t('reportIsBeingGenerated'),
      status: 'success',
      duration: 3000,
    });
    refetch();
  };

  const GenerateReportButton = ({ fullWidth }) => (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<AddIcon />}
        colorScheme="primary"
        textTransform="capitalize"
        size="lg"
        sx={{
          display: 'grid',
          alignItems: 'center',
          gridAutoFlow: 'column',
          width: fullWidth && 'full',
        }}
      >
        {t('generateReport')}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onInboundOpen}>{t('inboundsReport')}</MenuItem>
        <MenuItem onClick={onOutboundOpen}>{t('outboundsReport')}</MenuItem>
        <MenuItem onClick={onSKUReportOpen}>{t('SKUsReport')}</MenuItem>
        <MenuItem onClick={onSpaceUtilizationOpen}>
          {t('spaceUtilizationReport')}
        </MenuItem>
        <MenuItem onClick={onExpiryOpen}>{t('expiryReport')}</MenuItem>
        <MenuItem onClick={onChargeableActivitiesOpen}>
          {t('chargeableActivitiesReport')}
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        filters={filters}
        selectedFilter={selectedFilter}
        pagination={pagination}
        title={t('allReports')}
        pageAction={<GenerateReportButton />}
        onFilterChange={onFilterChange}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('searchByName')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
        defaultSorting={defaultSorting}
        emptyTable={
          <EmptyTable
            illustration={<ReportsEmptyTable />}
            title={t('emptyStateTitle', {
              value: t('reports'),
            })}
            subtitle={t('generateReportAndViewDownload')}
            actions={<GenerateReportButton fullWidth />}
          />
        }
      />

      <ReportDrawer
        reportType="inbounds"
        title="generateInboundsReport"
        isOpen={isInboundReportOpen}
        onClose={onInboundClose}
        onSuccess={handleGenerateReportSuccess}
      />

      <ReportDrawer
        reportType="outbounds"
        title="generateOutboundsReport"
        isOpen={isOutboundReportOpen}
        onClose={onOutboundClose}
        onSuccess={handleGenerateReportSuccess}
      />

      <ReportDrawer
        reportType="skus"
        title="generateSkusReport"
        isOpen={isSKUReportOpen}
        onClose={onSKUReportClose}
        onSuccess={handleGenerateReportSuccess}
      />

      <ReportDrawer
        reportType="space_utilization"
        title="generateSpaceUtilizationReport"
        isOpen={isSpaceUtilizationReportOpen}
        onClose={onSpaceUtilizationClose}
        onSuccess={handleGenerateReportSuccess}
      />

      <ExpiryReportDrawer
        isOpen={isExpiryReportOpen}
        onClose={onExpiryClose}
        onSuccess={handleGenerateReportSuccess}
        reportType="expiry"
      />

      <ReportDrawer
        isOpen={isChargeableActivitiesOpen}
        onClose={onChargeableActivitiesClose}
        onSuccess={handleGenerateReportSuccess}
        reportType="chargeable_activities"
        title="generateChargeableActivitiesReport"
      />
    </>
  );
};

export default Reports;
