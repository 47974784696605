import { useMemo, useState } from 'react';
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Button,
  Select,
  Text,
  Flex,
  Box,
  IconButton,
  HStack,
  Skeleton,
  Checkbox,
} from '@chakra-ui/react';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';

import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import styled from '@emotion/styled';
import useLanguage from 'hooks/useLanguage';
import { ReactComponent as ArrowSortIcon } from 'assets/icons/arrow-sort.svg';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'hooks/useIsMobile';

const pageSizes = [5, 10, 20, 30, 40];
const totalVisiblePages = 3;

const PaginationButton = ({ pageNumber, onClick, isActive, isRTL }) => (
  <Button
    onClick={onClick}
    variant={isActive ? undefined : 'ghost'}
    color={isActive ? 'teal' : 'gray.500'}
    colorScheme={isActive ? 'lightTeal' : undefined}
    size={{ base: 'sm', md: 'md' }}
  >
    {isRTL ? pageNumber.toLocaleString('ar-SA') : pageNumber}
  </Button>
);

const Pagination = ({ table }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const pageCount = table.getPageCount();
  const paginationState = table.getState().pagination;

  const currentPage = paginationState.pageIndex + 1;

  const [startPageIndex, setStartPageIndex] = useState(0);

  const buttons = useMemo(
    () => Array.from({ length: pageCount }, (_, index) => index + 1),
    [pageCount]
  );

  const visibleButtons = useMemo(
    () =>
      buttons
        .slice(startPageIndex, startPageIndex + totalVisiblePages)
        .filter((page) => page !== pageCount),
    [pageCount, buttons, startPageIndex]
  );

  const handleShowMoreClick = (updatePageSize = true) => {
    if (startPageIndex + totalVisiblePages < pageCount) {
      setStartPageIndex((prev) => {
        const newState = prev + totalVisiblePages;
        updatePageSize && table.setPageIndex(newState);
        return newState;
      });
    }
  };

  const handleShowLessClick = (updatePageSize = true) => {
    if (startPageIndex - totalVisiblePages >= 0) {
      setStartPageIndex((prev) => {
        const newState = prev - totalVisiblePages;
        updatePageSize && table.setPageIndex(newState);
        return newState;
      });
    }
  };

  return (
    <Flex
      justifyContent="space-between"
      gap={2}
      p={4}
      px={0}
      alignItems={{ md: 'center' }}
      flexDirection={{ base: 'column-reverse', md: 'row' }}
    >
      <Flex alignItems="center" justifyContent="space-between" gap={1}>
        <Text color="gray.600" fontSize="sm">
          {`${t('showResult')}: `}
        </Text>
        <Box>
          <Select
            value={paginationState.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            borderRadius="md"
            p={1}
            bg="white"
            fontWeight={700}
          >
            {pageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {isRTL ? pageSize.toLocaleString('ar-SA') : pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
      <HStack spacing={{ base: 1, md: 2 }} justifyContent="center">
        <IconButton
          variant="ghost"
          onClick={() => {
            table.previousPage();
            const prevPage = currentPage - 1;
            if (!visibleButtons.find((page) => page === prevPage)) {
              handleShowLessClick(false);
            }
          }}
          isDisabled={!table.getCanPreviousPage()}
          icon={
            isRTL ? (
              <ChevronRightIcon boxSize="7" color="gray.500" />
            ) : (
              <ChevronLeftIcon boxSize="7" color="gray.500" />
            )
          }
          size={{ base: 'sm', md: 'md' }}
        />

        {startPageIndex > 0 && (
          <Button
            variant="ghost"
            onClick={handleShowLessClick}
            size={{ base: 'sm', md: 'md' }}
          >
            ...
          </Button>
        )}

        {visibleButtons.map((pageNumber) => {
          const isActive = currentPage === pageNumber;
          return (
            <PaginationButton
              key={pageNumber}
              pageNumber={pageNumber}
              onClick={() =>
                isActive ? undefined : table.setPageIndex(pageNumber - 1)
              }
              isActive={isActive}
              isRTL={isRTL}
            />
          );
        })}

        {startPageIndex + 1 + totalVisiblePages < pageCount && (
          <Button
            variant="ghost"
            onClick={handleShowMoreClick}
            size={{ base: 'sm', md: 'md' }}
          >
            ...
          </Button>
        )}

        <PaginationButton
          pageNumber={pageCount}
          onClick={() => table.setPageIndex(pageCount - 1)}
          isActive={pageCount === currentPage}
          isRTL={isRTL}
        />

        <IconButton
          variant="ghost"
          onClick={() => {
            table.nextPage();
            const nextPage = currentPage + 1;
            if (!visibleButtons.find((page) => page === nextPage)) {
              handleShowMoreClick(false);
            }
          }}
          isDisabled={!table.getCanNextPage()}
          icon={
            isRTL ? (
              <ChevronLeftIcon boxSize="7" color="gray.500" />
            ) : (
              <ChevronRightIcon boxSize="7" color="gray.500" />
            )
          }
          size={{ base: 'sm', md: 'md' }}
        />
      </HStack>
    </Flex>
  );
};

const TableContainer = styled(Box)`
  td,
  th {
    padding: 20px 8px 20px 12px;
  }

  td {
    font-size: 14px;
    border-bottom-color: #edf2f7;
  }

  thead {
    th {
      border: none;
    }
    th:first-of-type {
      border-start-start-radius: 12px;
      border-end-start-radius: 12px;
    }

    th:last-of-type {
      border-end-end-radius: 12px;
      border-start-end-radius: 12px;
    }
  }

  @media screen and (max-width: 767px) {
    table {
      border-collapse: collapse;
      word-wrap: break-word;
      display: flex;
      flex-direction: column;

      tbody {
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          padding: 8px;
          padding-top: 0;
          margin-bottom: 0;
          padding-inline: 0;
          gap: 12px;
          align-items: normal;
        }

        td {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          padding: 4px 0;
          border: none;
        }

        td:nth-of-type(1) {
          flex-basis: 100%;
        }

        table {
          tr {
            padding-inline: 0;
          }
          td:last-of-type {
            flex-basis: 100%;
            border-bottom: 1px solid #edf2f7;
            padding-bottom: 16px;
          }
        }
      }
    }
  }
`;

const TableRow = ({ row, isMobile, renderSubComponent }) => {
  const { t } = useTranslation();

  const hasSelect = row?.getCanSelect();
  const hasSelectAndIsMobile = hasSelect && isMobile;
  const isSelected = row?.getIsSelected();

  return (
    <>
      <Tr
        bg={isSelected && !isMobile ? 'primary.50' : undefined}
        sx={{
          '& > td:first-of-type': {
            borderStartRadius:
              hasSelect && !isMobile ? '2xl !important' : undefined,
          },
          '& > td:last-of-type': {
            borderEndRadius: hasSelect ? '2xl !important' : undefined,
            borderBottom: hasSelectAndIsMobile
              ? 'none !important'
              : '1px solid var(--chakra-colors-gray-200)',
          },
          borderRadius: 'xl',
          border: hasSelectAndIsMobile
            ? '1px solid var(--chakra-colors-gray-200)'
            : undefined,
          paddingBottom: hasSelectAndIsMobile ? '0 !important' : undefined,
          gap: hasSelectAndIsMobile ? '2 !important' : 3,
          position: 'relative',
        }}
      >
        {row?.getVisibleCells().map((cell) => {
          const meta = cell.column.columnDef.meta;
          const isSelectionCell = cell.column.id === 'select';

          if (isMobile && (meta?.isAction || meta?.mobileHeader)) {
            return null;
          }

          const isFullWidthAction = isMobile && meta?.fullWidthAction;

          return (
            <Td
              key={cell.id}
              isNumeric={meta?.isNumeric}
              color={{ base: 'gray.900', md: 'gray.800' }}
              style={
                meta?.fitContent && !isMobile
                  ? { width: '1%', whiteSpace: 'nowrap' }
                  : undefined
              }
              sx={{
                color: { base: 'gray.900', md: 'gray.800' },
                justifyContent: isSelectionCell
                  ? 'flex-start !important'
                  : 'space-between !important',
                flexDirection: isSelectionCell
                  ? 'row !important'
                  : 'row-reverse !important',
                borderBottom:
                  isSelectionCell || !isMobile
                    ? '1px solid var(--chakra-colors-gray-200) !important'
                    : undefined,
                paddingY: isSelectionCell ? '5 !important' : undefined,
                paddingX: hasSelectAndIsMobile ? '4 !important' : undefined,
                borderRadius: isSelectionCell ? 'none !important' : undefined,
                fontWeight: meta?.mobileHeader ? 500 : 'normal',
              }}
            >
              <Box width={isFullWidthAction ? 'full' : undefined}>
                <Text
                  noOfLines={{ base: 1, md: 2 }}
                  as="span"
                  title=""
                  width={isFullWidthAction ? 'full' : undefined}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Text>
              </Box>

              {isMobile && !isFullWidthAction && (
                <Box
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    color:
                      isSelectionCell && isMobile ? 'gray.700' : 'gray.600',
                  }}
                >
                  {isSelectionCell
                    ? t('select')
                    : flexRender(
                        cell.column.columnDef.header,
                        cell.getContext()
                      )}
                </Box>
              )}
            </Td>
          );
        })}
      </Tr>
      {row?.getIsExpanded() && <Tr>{renderSubComponent({ row })}</Tr>}
    </>
  );
};

const loaderHeight = '20px';

const TableRowMobile = ({
  row,
  isLoading,
  allColumns = [],
  renderSubComponent,
}) => {
  const rowCells = row?.getAllCells();

  const actionCell = rowCells?.find(
    (cell) => cell.column.columnDef.meta?.isAction
  );

  const mobileHeaderLeftCell = rowCells?.find(
    (cell) => cell.column.columnDef.meta?.mobileHeader === 'left'
  );

  const hideHash = mobileHeaderLeftCell?.column.columnDef.meta?.hideHash;

  const mobileHeaderRightCell = rowCells?.find(
    (cell) => cell.column.columnDef.meta?.mobileHeader === 'right'
  );

  const hasMobileHeader =
    actionCell || mobileHeaderLeftCell || mobileHeaderRightCell;

  return (
    <Tr>
      <Td>
        <ChakraTable>
          <Tbody>
            {hasMobileHeader && (
              <Tr>
                <Td>
                  <Flex gap={2} alignItems="center">
                    <Skeleton
                      isLoaded={!isLoading}
                      minHeight={loaderHeight}
                      minWidth={isLoading ? '105px' : 'auto'}
                    >
                      {flexRender(
                        mobileHeaderRightCell?.column.columnDef.cell,
                        mobileHeaderRightCell?.getContext()
                      )}
                    </Skeleton>

                    {actionCell && (
                      <Skeleton
                        isLoaded={!isLoading}
                        minHeight={loaderHeight}
                        minWidth="30px"
                      >
                        {flexRender(
                          actionCell?.column.columnDef.cell,
                          actionCell?.getContext()
                        )}
                      </Skeleton>
                    )}
                  </Flex>

                  <Skeleton
                    isLoaded={!isLoading}
                    minHeight={loaderHeight}
                    minWidth="140px"
                  >
                    <Box fontWeight="600">
                      {!hideHash && '#'}
                      {flexRender(
                        mobileHeaderLeftCell?.column.columnDef.cell,
                        mobileHeaderLeftCell?.getContext()
                      )}
                    </Box>
                  </Skeleton>
                </Td>
              </Tr>
            )}
            <Tr>
              {allColumns.map((_, index) => (
                <Td key={index}>
                  <Skeleton
                    isLoaded={!isLoading}
                    minHeight={loaderHeight}
                    minWidth="140px"
                  />
                  <Skeleton
                    isLoaded={!isLoading}
                    minHeight={loaderHeight}
                    minWidth="140px"
                  />
                </Td>
              ))}
            </Tr>
            <TableRow
              row={row}
              renderSubComponent={renderSubComponent}
              isMobile
            />
          </Tbody>
        </ChakraTable>
      </Td>
    </Tr>
  );
};

const SelectionCell = ({ checked, indeterminate, onChange }) => (
  <Flex justifyContent="center" alignItems="center" h="100%">
    <Checkbox
      isChecked={checked}
      isIndeterminate={indeterminate}
      onChange={onChange}
      colorScheme="primary"
      size="lg"
      borderColor="gray.400"
    />
  </Flex>
);

const Table = ({
  data,
  columns,
  pageCount,
  pageIndex = 0,
  pageSize = pageSizes[0],
  onSortingChange,
  onPaginationChange,
  isLoading,
  hidePagination,
  enableSorting = true,
  footer,
  defaultSorting = [],
  getRowCanExpand,
  renderSubComponent,
  emptyTable,
  enableRowSelection = false,
  onRowSelectionChange,
  rowSelection = {},
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [sorting, setSorting] = useState(defaultSorting);
  const [pagination, setPagination] = useState({
    pageSize,
    pageIndex,
  });

  useDidUpdateEffect(() => {
    onSortingChange?.(sorting);
  }, [sorting]);

  useDidUpdateEffect(() => {
    onPaginationChange?.(pagination);
  }, [pagination]);

  const columnsWithSelection = useMemo(() => {
    if (!enableRowSelection) return columns;

    return [
      {
        id: 'select',
        header: ({ table }) => (
          <SelectionCell
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => {
          if (!enableRowSelection(row)) {
            return (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  w: '100%',
                  h: '100%',
                  backgroundColor: 'gray.50',
                  opacity: 0.5,
                }}
              />
            );
          }

          return (
            <SelectionCell
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
        enableSorting: false,
        meta: {
          fitContent: true,
        },
      },
      ...columns,
    ];
  }, [columns, enableRowSelection]);

  const table = useReactTable({
    columns: columnsWithSelection,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    pageCount,
    onPaginationChange: (updater) => {
      setPagination((prevState) => {
        return updater({ ...prevState });
      });
    },
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    enableRowSelection,
    onRowSelectionChange,
    manualSorting: true,
    manualPagination: true,
    enableMultiSort: false,
    sortDescFirst: true,
    enableSorting: enableSorting && !emptyTable && data.length > 0,
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <>
      <TableContainer overflowX="auto" overflowY="hidden">
        <ChakraTable>
          <Thead whiteSpace="nowrap">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  if (emptyTable) {
                    return null;
                  }

                  const meta = header.column.columnDef.meta;
                  const canSort = header.column.getCanSort();

                  const isSelectionHeader = header.id === 'select';
                  const isSelectionHeaderAndIsMobile =
                    isSelectionHeader && isMobile && !!data.length;

                  return (
                    <Th
                      key={header.id}
                      onClick={
                        canSort
                          ? header.column.getToggleSortingHandler()
                          : undefined
                      }
                      isNumeric={meta?.isNumeric}
                      sx={{
                        cursor: canSort ? 'pointer' : 'auto',
                        bg: isSelectionHeaderAndIsMobile
                          ? undefined
                          : 'gray.50',
                        display:
                          !isMobile || isSelectionHeaderAndIsMobile
                            ? 'table-cell'
                            : 'none',
                        padding: isSelectionHeaderAndIsMobile
                          ? '10px 0 !important'
                          : '20px 8px 20px 12px',
                        color: isSelectionHeaderAndIsMobile
                          ? 'gray.700'
                          : 'gray.600',
                      }}
                    >
                      <Skeleton
                        isLoaded={!isLoading}
                        sx={{
                          display: 'flex',
                          fontWeight: '600',
                          gap: isSelectionHeaderAndIsMobile ? 3 : 1,
                          fontSize: isSelectionHeaderAndIsMobile ? 'md' : 'xs',
                          textTransform: isSelectionHeaderAndIsMobile
                            ? 'none'
                            : 'uppercase',
                          justifyContent: meta?.centerHeader
                            ? 'center'
                            : 'flex-start',
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {isSelectionHeaderAndIsMobile &&
                          t('selectAllTableHeader')}
                        {canSort && (
                          <chakra.span>
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === 'desc' ? (
                                <ArrowDownIcon
                                  aria-label="sorted descending"
                                  color="primary.500"
                                  boxSize={3}
                                />
                              ) : (
                                <ArrowUpIcon
                                  aria-label="sorted ascending"
                                  color="primary.500"
                                  boxSize={3}
                                />
                              )
                            ) : (
                              <ArrowSortIcon />
                            )}
                          </chakra.span>
                        )}
                      </Skeleton>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          {emptyTable ? (
            <Tbody>
              <Tr>
                <Td
                  colSpan={columns.length}
                  style={{
                    border: 0,
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  {emptyTable}
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody gap={2}>
              {isLoading &&
                (isMobile ? (
                  <TableRowMobile
                    allColumns={table.getAllColumns()}
                    isLoading
                  />
                ) : (
                  Array.from({ length: pagination.pageSize }).map((_, i) => (
                    <Tr mt={6} key={i}>
                      {table.getAllColumns().map((_, index) => (
                        <Td key={index}>
                          <Skeleton
                            isLoaded={!isLoading}
                            minHeight={loaderHeight}
                          />
                        </Td>
                      ))}
                    </Tr>
                  ))
                ))}

              {table.getRowModel().rows.map((row) => {
                return isMobile ? (
                  <TableRowMobile
                    key={row.id}
                    row={row}
                    renderSubComponent={renderSubComponent}
                  />
                ) : (
                  <TableRow
                    key={row.id}
                    row={row}
                    renderSubComponent={renderSubComponent}
                  />
                );
              })}
            </Tbody>
          )}
        </ChakraTable>
      </TableContainer>
      {!hidePagination && <Pagination table={table} />}
      {footer}
    </>
  );
};

export default Table;
