import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

export default function CountrySelect({ value, onChange, options, ...rest }) {
  const onChange_ = useCallback(
    (event) => {
      const value = event.target.value;
      onChange(value === 'ZZ' ? undefined : value);
    },
    [onChange]
  );

  // "ZZ" means "International".
  // (HTML requires each `<option/>` have some string `value`).
  return (
    <select {...rest} value={value || 'ZZ'} onChange={onChange_}>
      {options.map(({ value, label, divider }) => (
        <option
          key={divider ? '|' : value || 'ZZ'}
          value={divider ? '|' : value || 'ZZ'}
          disabled={divider ? true : false}
          style={divider ? DIVIDER_STYLE : undefined}
        >
          {label}
        </option>
      ))}
    </select>
  );
}

const DIVIDER_STYLE = {
  fontSize: '1px',
  backgroundColor: 'currentColor',
  color: 'inherit',
};

export function CountrySelectWithIcon({
  value,
  options,
  className,
  iconComponent: Icon,
  getIconAspectRatio,
  arrowComponent: Arrow = DefaultArrowComponent,
  unicodeFlags,
  ...rest
}) {
  const selectedOption = useMemo(() => {
    return getSelectedOption(options, value);
  }, [options, value]);

  return (
    <div className="absolute inset-y-0 ltr:left-0 rtl:right-0 flex gap-2 items-center rtl:pr-3 rtl:pl-1 ltr:pl-3 ltr:pr-1 rtl:border-l ltr:border-r border-gray-300">
      <CountrySelect
        {...rest}
        value={value}
        options={options}
        className={classNames(
          'PhoneInputCountrySelect h-full rounded-md border-0 bg-transparent p-0 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm',
          className
        )}
      />

      {/* Either a Unicode flag icon. */}
      {unicodeFlags && value && (
        <div className="PhoneInputCountryIconUnicode">
          {getUnicodeFlagIcon(value)}
        </div>
      )}

      {/* Or an SVG flag icon. */}
      {!(unicodeFlags && value) && (
        <Icon
          aria-hidden
          country={value}
          label={selectedOption && selectedOption.label}
          aspectRatio={unicodeFlags ? 1 : undefined}
        />
      )}

      <span className="text-sm">{selectedOption.value}</span>

      <Arrow />
    </div>
  );
}

function DefaultArrowComponent() {
  return <div className="PhoneInputCountrySelectArrow" />;
}

function getSelectedOption(options, value) {
  for (const option of options) {
    if (!option.divider && option.value === value) {
      return option;
    }
  }
}
