export const generateCustomerAddress = (address = {}, isCustomerPage) => {
  const {
    building_number,
    shipping_building_number,
    street_name,
    shipping_street_name,
    district_name,
    shipping_district_name,
    city,
    shipping_city,
    shipping_country,
  } = address;

  const addressFields = isCustomerPage
    ? [
        `${building_number ?? ''} ${street_name}`,
        district_name,
        city.name,
        city.country_name,
      ]
    : [
        `${shipping_building_number ?? ''} ${shipping_street_name}`,
        shipping_district_name,
        shipping_city,
        shipping_country,
      ];

  return addressFields.join(', ');
};
