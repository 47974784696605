import { api } from 'utils/axios';

export const getOrders = async (searchParams) => {
  try {
    const response = await api(`/fulfillment_orders?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const createOrder = async (fulfillment_order) => {
  try {
    const response = await api.post('/fulfillment_orders', {
      fulfillment_order,
    });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getOrderDetails = async (id) => {
  try {
    const response = await api.get(`/fulfillment_orders/${id}`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateOrderSKU = async (id, unit_base_price) => {
  try {
    const response = await api.put(`/fulfillment_order_skus/${id}`, {
      fulfillment_order_sku: {
        unit_base_price,
      },
    });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const cancelOrder = async (id) => {
  try {
    const response = await api.patch(`/fulfillment_orders/${id}/cancel`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getBundles = async () => {
  try {
    const response = await api.get('/bundles');
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
