import { useMutation, useQuery } from 'react-query';
import { updateInbound } from 'api/Dashboard/inbounds';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getWarehouseCapacity } from 'api/Dashboard/warehouses';

const useConfirmInbound = ({ id, warehouseId }, options = {}) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { onSuccess, onCapacityExceeded } = options;

  const { mutate: confirmInbound, isLoading: isConfirmInboundLoading } =
    useMutation(
      () =>
        updateInbound(id, {
          status: 'created',
        }),
      {
        onSuccess: () => {
          toast({
            title: t('inboundConfirmedSuccessfully'),
            status: 'success',
            duration: 5000,
          });
          onSuccess?.();
        },
        onError: (error) => {
          const errorMsg = error.response?.data?.errors?.status?.[0];

          toast({
            title: errorMsg || t('somethingWentWrong'),
            status: 'error',
            duration: 5000,
          });
        },
      }
    );

  const {
    data: capacityData = {},
    refetch: validateCapacity,
    isLoading: isValidateCapacityLoading,
    isRefetching: isRefetchingCapacity,
  } = useQuery(
    ['warehouseCapacity', warehouseId],
    () => getWarehouseCapacity(warehouseId),
    {
      onSuccess: (data = {}) => {
        if (data.exceeding_threshold) {
          onCapacityExceeded?.(data);
          return;
        }

        confirmInbound();
      },
      onError: () => {
        toast({
          title: t('errorCheckingWarehouseCapacity'),
          status: 'error',
          duration: 5000,
        });
      },
      enabled: false,
    }
  );

  return {
    confirmInbound,
    validateAndConfirm: validateCapacity,
    isConfirmInboundLoading,
    isValidatingCapacity: isValidateCapacityLoading || isRefetchingCapacity,
    capacityData,
  };
};

export default useConfirmInbound;
