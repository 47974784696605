import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useLinkUnlink from 'hooks/useLinkUnlink';
import useTable from 'hooks/useTable';
import { getBundles } from 'api/Dashboard/bundles';
import { Center, Button } from '@chakra-ui/react';
import PageDrawer from 'components/Dashboard/PageDrawer';
import TablePage from 'components/Dashboard/TablePage';
import { getBundleColumns } from 'pages/Dashboard/Bundles';

const BundleListDrawer = ({
  isOpen,
  onClose,
  title,
  onSubmit,
  isLoading,
  defaultSelectedBundles = [],
  tableTitle,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  const formId = 'mapToBundle';

  const {
    linked: linkedBundles,
    unlink: unlinkBundle,
    setLinked,
  } = useLinkUnlink(defaultSelectedBundles);

  const {
    data: bundles = [],
    isLoading: isBundlesLoading,
    onSearchChange,
    onPaginationChange,
    pagination,
    onSortingChange,
  } = useTable({
    fetch: getBundles,
    fetchKey: 'bundles',
    searchKey: 'name_or_name_locale_cont',
    disableURLParams: true,
  });

  const renderActions = ({ row }) => {
    const id = row.original.id;
    const isLinked = linkedBundles[0] === id;

    return (
      <Center>
        <Button
          variant="outline"
          color={isLinked ? 'red.600' : 'gray.700'}
          textTransform="capitalize"
          fontWeight={500}
          onClick={() => {
            if (isLinked) {
              unlinkBundle(id);
              return;
            }

            setLinked([id]);
          }}
        >
          {t(isLinked ? 'unlink' : 'link')}
        </Button>
      </Center>
    );
  };

  const columns = getBundleColumns({
    renderActions,
  });

  const handleFormSubmit = () => {
    onSubmit({ bundle_id: linkedBundles[0] });
  };

  return (
    <PageDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t(title)}
      size="xl"
      formId={formId}
      isLoading={isLoading}
      noPaddingTop
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate id={formId}>
        <TablePage
          data={bundles}
          title={tableTitle}
          columns={columns}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          searchPlaceholder={t('bundlePages.searchPlaceholder')}
          onSearchChange={onSearchChange}
          isLoading={isBundlesLoading}
          onSortingChange={onSortingChange}
          noPadding
        />
      </form>
    </PageDrawer>
  );
};

export default BundleListDrawer;
