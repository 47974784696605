import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardBody, CardHeader, Stack, Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import BackToListButton from 'components/Dashboard/BackToListButton';
import { LineTabs } from 'components/shared/LineTabs';
import { handleNotFoundPage } from 'utils/notFoundPage';
import MappedProducts from 'components/Dashboard/Fulfillment/Integrations/MappedProducts';
import UnmappedProducts from 'components/Dashboard/Fulfillment/Integrations/UnmappedProducts';
import {
  getIntegrationById,
  fetchMappedProducts,
  fetchUnmappedProducts,
} from 'api/Dashboard/integrations';
import useTable from 'hooks/useTable';

const showCount = (count, isLoading) => {
  if (isLoading) return '...';
  return count;
};

const IntegrationDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: connectedIntegration } = useQuery(
    ['integration-details', id],
    () => getIntegrationById(id),
    {
      enabled: !!id,
      onError: handleNotFoundPage,
    }
  );

  const integrationName = connectedIntegration?.integration?.name;

  const productTableCommonParams = {
    defaultState: {
      pageSize: 20,
    },
    searchKey:
      'channel_product_name_or_channel_product_id_or_channel_variant_id_cont',
    disableURLParams: true,
    enabled: !!id,
  };

  const {
    data: unmappedProductsData = [],
    meta: unmappedProductsMeta,
    isLoading: isLoadingUnmapped,
    isRefetching: isRefetchingUnmapped,
    onSortingChange: onUnmappedSortingChange,
    onPaginationChange: onUnmappedPaginationChange,
    pagination: unmappedPagination,
    onSearchChange: onUnmappedSearchChange,
    refetch: refetchUnmapped,
    search: unmappedSearch,
  } = useTable({
    fetch: (searchParams) => fetchUnmappedProducts(id, searchParams),
    fetchKey: ['unmapped-products', id],
    ...productTableCommonParams,
  });

  const {
    data: mappedProductsData = [],
    meta: mappedProductsMeta,
    isLoading: isLoadingMapped,
    isRefetching: isRefetchingMapped,
    onSortingChange: onMappedSortingChange,
    onPaginationChange: onMappedPaginationChange,
    pagination: mappedPagination,
    onSearchChange: onMappedSearchChange,
    refetch: refetchMapped,
    search: mappedSearch,
  } = useTable({
    fetch: (searchParams) => fetchMappedProducts(id, searchParams),
    fetchKey: ['mapped-products', id],
    ...productTableCommonParams,
  });

  const unmappedCount = unmappedProductsMeta?.pagination?.total_count ?? 0;
  const mappedCount = mappedProductsMeta?.pagination?.total_count ?? 0;

  const productTabs = [
    {
      label: `${t('integrations.unmappedProducts')} (${showCount(
        unmappedCount,
        isLoadingUnmapped
      )})`,
      content: (
        <UnmappedProducts
          integrationId={id}
          integrationName={integrationName}
          data={unmappedProductsData}
          search={unmappedSearch}
          isLoading={isLoadingUnmapped || isRefetchingUnmapped}
          onSortingChange={onUnmappedSortingChange}
          onPaginationChange={onUnmappedPaginationChange}
          pagination={unmappedPagination}
          onSearchChange={onUnmappedSearchChange}
          refetchData={() => {
            refetchUnmapped();
            refetchMapped();
          }}
        />
      ),
    },
    {
      label: `${t('integrations.mappedProducts')} (${showCount(
        mappedCount,
        isLoadingMapped
      )})`,
      content: (
        <MappedProducts
          integrationId={id}
          integrationName={integrationName}
          data={mappedProductsData}
          search={mappedSearch}
          isLoading={isLoadingMapped || isRefetchingMapped}
          onSortingChange={onMappedSortingChange}
          onPaginationChange={onMappedPaginationChange}
          pagination={mappedPagination}
          onSearchChange={onMappedSearchChange}
          refetchData={() => {
            refetchUnmapped();
            refetchMapped();
          }}
        />
      ),
    },
  ];

  const getValidTabIndex = () => {
    const tabParam = searchParams.get('tab');

    if (tabParam === null) return 0;

    const parsedIndex = parseInt(tabParam, 10);

    if (
      isNaN(parsedIndex) ||
      parsedIndex < 0 ||
      parsedIndex >= productTabs.length
    ) {
      setSearchParams({ tab: '0' });
      return 0;
    }

    return parsedIndex;
  };

  const activeTabIndex = getValidTabIndex();

  const handleTabChange = (index) => {
    setSearchParams({ tab: index.toString() });
  };

  return (
    <Box>
      <Stack spacing={6}>
        <Flex>
          <BackToListButton
            title={t('integrations.integrationsList')}
            url="/fulfillment/integrations"
          />
        </Flex>

        <Card size="lg">
          <CardHeader>
            <SectionTitle
              title={`${integrationName} ${t('products')}`}
              hideDivider
            />
          </CardHeader>
          <CardBody paddingBottom={0}>
            <LineTabs
              tabItems={productTabs}
              tabListStyle={{
                marginBottom: { md: '-90px' },
                zIndex: 1,
                position: 'relative',
                width: 'fit-content',
              }}
              defaultIndex={activeTabIndex}
              onChange={handleTabChange}
            />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};

export default IntegrationDetails;
