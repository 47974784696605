import { Stack, SimpleGrid, Button, Flex } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import Input from 'components/shared/Inputs/Input';
import AvatarInput from '../AvatarInput';
import { useTranslation } from 'react-i18next';
import { emailRegex } from 'utils/regex';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { passwordValidation } from 'constants/validation';
import { flattenErrorObject } from 'utils/formError';

const PersonalInfoForm = ({
  defaultValues,
  onSubmit,
  isLoading,
  backendErrors = [],
}) => {
  const { t } = useTranslation();

  const { handleSubmit, formState, register, control, watch } = useForm({
    defaultValues,
  });

  const watchOldPassword = watch('old_password');

  const errors = { ...formState.errors, ...flattenErrorObject(backendErrors) };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={5}>
        <Controller
          control={control}
          name="avatar"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange, value } }) => (
            <AvatarInput
              onChange={onChange}
              value={value}
              onDelete={() => {
                onChange([
                  {
                    _destroy: '1',
                  },
                ]);
              }}
              defaultAvatarName={`${defaultValues.firstname} ${defaultValues.lastname}`}
            />
          )}
        />

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
          <Input
            name="firstname"
            label={t('firstName')}
            register={register}
            errors={errors}
            placeholder={`${t('e.g')}: ${t('firstNameExample')}`}
            size="lg"
            required="thisFieldIsRequired"
          />
          <Input
            name="lastname"
            label={t('lastName')}
            register={register}
            errors={errors}
            placeholder={`${t('e.g')}: ${t('lastNameExample')}`}
            size="lg"
            required="thisFieldIsRequired"
          />
          <Input
            name="email"
            label={t('email')}
            placeholder={`${t('e.g')}: ${t('email@gmail.com')}`}
            register={register}
            errors={errors}
            pattern={{
              value: emailRegex,
              message: 'validation.invalidEmail',
            }}
            size="lg"
            disabled
          />
          <Input
            name="phone"
            label={t('phoneNumber')}
            placeholder={`${t('e.g')}: +966112212222`}
            register={register}
            errors={errors}
            required="thisFieldIsRequired"
            validate={(value) => {
              return (
                isValidPhoneNumber(value, 'SA') || 'validation.invalidNumber'
              );
            }}
            size="lg"
          />
        </SimpleGrid>
        <Input
          name="old_password"
          label={t('oldPassword')}
          register={register}
          errors={errors}
          placeholder="••••••••"
          size="lg"
          type="password"
          minLength={passwordValidation.minLength}
          maxLength={passwordValidation.maxLength}
        />
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
          <Input
            name="password"
            label={t('newPassword')}
            register={register}
            errors={errors}
            placeholder="••••••••"
            size="lg"
            type="password"
            minLength={passwordValidation.minLength}
            maxLength={passwordValidation.maxLength}
            disabled={!watchOldPassword || errors.old_password}
          />
          <Input
            name="password_confirmation"
            label={t('confirmPassword')}
            register={register}
            errors={errors}
            placeholder="••••••••"
            size="lg"
            type="password"
            minLength={passwordValidation.minLength}
            maxLength={passwordValidation.maxLength}
            disabled={!watchOldPassword || errors.old_password}
          />
        </SimpleGrid>
      </Stack>

      <Flex justifyContent="end">
        <Button
          mt={8}
          colorScheme="primary"
          type="submit"
          size="lg"
          textTransform="capitalize"
          isLoading={isLoading}
        >
          {t('saveChanges')}
        </Button>
      </Flex>
    </form>
  );
};

export default PersonalInfoForm;
