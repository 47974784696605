import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Center, Spinner, useToast } from '@chakra-ui/react';
import { connectSallaIntegration } from 'api/Dashboard/integrations';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { showErrorToast } from 'utils/toast';

const SallaCallback = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const [searchParams] = useSearchParams();
  const hasExecuted = useRef(false);

  const { mutate: connectSalla, isLoading } = useMutation(
    () => {
      const params = {
        code: searchParams.get('code'),
        scope: searchParams.get('scope'),
        state: searchParams.get('state'),
      };

      return connectSallaIntegration(params);
    },
    {
      onSuccess: () => {
        toast({
          title: t('integrations.connectionSuccess'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => showErrorToast(t('integrations.connectionError')),
      onSettled: () => navigate('/fulfillment/integrations'),
    }
  );

  useEffect(() => {
    if (!hasExecuted.current) {
      hasExecuted.current = true;
      connectSalla();
    }
  }, [connectSalla]);

  return (
    <Center h="100vh">
      {isLoading && <Spinner size="xl" color="primary.500" thickness="4px" />}
    </Center>
  );
};

export default SallaCallback;
