import {
  Box,
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useState } from 'react';
import TablePage from 'components/Dashboard/TablePage';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import SKUsTableForm from 'components/Dashboard/Orders/SKUsTableForm';
import EmptyTable from 'components/Dashboard/EmptyTable';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { roundNumber } from 'utils/number';
import { Controller, useForm } from 'react-hook-form';
import { NUMBER_MAX_VALUE } from 'constants/validation';
import { valueFallback } from 'utils/string';
import PageDrawer from 'components/Dashboard/PageDrawer';
import Table from 'components/Dashboard/Table';

const columnHelper = createColumnHelper();

export const BundleSKUsDrawer = ({ isOpen, onClose, bundleSKUs }) => {
  const { t } = useTranslation();

  const bundleSKUColumns = [
    columnHelper.accessor('sku.name', {
      cell: ({ getValue, row }) => (
        <Text fontWeight={600} color="gray.800">
          {getValue()}
        </Text>
      ),
      header: t('skuName'),
    }),
    columnHelper.accessor('sku.system_sku', {
      cell: ({ getValue }) => (
        <Text fontWeight={600} color="gray.800">
          {valueFallback(getValue())}
        </Text>
      ),
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('sku.merchant_sku', {
      cell: ({ getValue }) => (
        <Text fontWeight={600} color="gray.800">
          {valueFallback(getValue())}
        </Text>
      ),
      header: t('merchantSKU'),
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue }) => (
        <Center fontWeight={600} color="gray.800">
          {getValue()}
        </Center>
      ),
      header: t('quantity'),
      meta: {
        centerHeader: true,
      },
    }),
  ];

  return (
    <PageDrawer
      title={t('viewBundleSKUs')}
      isOpen={isOpen}
      onClose={onClose}
      cancelText="close"
      hideSubmitButton
    >
      <Table
        data={bundleSKUs}
        columns={bundleSKUColumns}
        enableSorting={false}
        hidePagination
        hideSearch
      />
    </PageDrawer>
  );
};

const OrderSKUCart = ({ skus, onSKUDelete, onSKUChange, onSKUSubmit }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: isViewSKUsOpen,
    onOpen: onViewSKUsOpen,
    onClose: onViewSKUsClose,
  } = useDisclosure();

  const queryClient = useQueryClient();

  const [editSKUId, setEditSKUId] = useState(undefined);
  const [selectedBundleSKUs, setSelectedBundleSKUs] = useState(null);

  const handleSkuChange = (id, { key, value }) => {
    onSKUChange(id, { key, value });
  };

  const handleSkuSubmit = (skuOrBundle, onSuccessCallback) => {
    const isBundle = !!skuOrBundle.isBundle;
    const entityKey = isBundle ? 'bundles' : 'skus';
    const entityIdKey = isBundle ? 'bundle_id' : 'sku_id';

    const isItemAlreadyInCart = skus.some(
      (item) => item.id === skuOrBundle[entityIdKey]
    );

    if (isItemAlreadyInCart) {
      toast({
        title: t(isBundle ? 'bundleAlreadyInCart' : 'skuAlreadyInCart'),
        status: 'error',
      });
      return;
    }

    const selectedItemData = queryClient
      .getQueriesData([entityKey])[0]?.[1]
      ?.data.find((data) => data.id === skuOrBundle[entityIdKey]);

    const selectedItem = {
      ...selectedItemData,
      quantity: +skuOrBundle.quantity,
      price: +skuOrBundle.price,
      isBundle,
    };

    onSKUSubmit(selectedItem);
    onSuccessCallback();
  };

  const { control, trigger } = useForm();

  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => (
        <Text fontWeight={600} color="gray.800">
          {getValue()}
        </Text>
      ),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('system_sku', {
      cell: ({ getValue }) => (
        <Text fontWeight={500} color="gray.800">
          {valueFallback(getValue())}
        </Text>
      ),
      header: t('sirdabID'),
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;
        const isBundle = !!row.original.isBundle;

        if (editSKUId && id === editSKUId) {
          const defaultValue = getValue();

          const maxQuantity = isBundle ? undefined : row.original.on_hand.units;
          const name = `${row.original.id}-quantity`;

          return (
            <Box maxWidth={{ base: '90px', md: '100px' }}>
              <Controller
                control={control}
                name={name}
                rules={{
                  required: t('thisFieldIsRequired'),
                  max: {
                    value: maxQuantity,
                    message: `${t('maxIs')} ${maxQuantity}`,
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DebouncedNumberInput
                    placeholder="quantity"
                    defaultValue={defaultValue}
                    min={1}
                    max={maxQuantity}
                    errorMessage={error?.message}
                    value={value}
                    onChange={async (value) => {
                      onChange(value);
                      await trigger(name);

                      handleSkuChange(id, {
                        key: 'quantity',
                        value: +value,
                      });
                    }}
                  />
                )}
              />
            </Box>
          );
        }
        return (
          <Text color="gray.800" fontWeight={500}>
            {getValue()}
          </Text>
        );
      },
      header: t('quantity'),
    }),
    columnHelper.accessor('price', {
      cell: ({ getValue, row }) => {
        const id = row.original.id;

        if (editSKUId && id === editSKUId) {
          const defaultValue = getValue();
          const name = `${row.original.id}-price`;

          return (
            <Box maxWidth={{ base: '130px', md: '150px' }}>
              <Controller
                control={control}
                name={name}
                rules={{
                  required: t('thisFieldIsRequired'),
                  max: {
                    value: NUMBER_MAX_VALUE,
                    message: `${t('maxIs')} ${NUMBER_MAX_VALUE}`,
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DebouncedNumberInput
                    placeholder={t('unitPrice')}
                    defaultValue={defaultValue}
                    min={1}
                    max={NUMBER_MAX_VALUE}
                    errorMessage={error?.message}
                    onChange={async (value) => {
                      onChange(value);
                      await trigger(name);

                      handleSkuChange(id, {
                        key: 'price',
                        value,
                      });
                    }}
                  />
                )}
              />
            </Box>
          );
        }
        return (
          <Center color="gray.800" fontWeight={500}>{`${roundNumber(
            getValue()
          )} ${t('SAR')}`}</Center>
        );
      },
      header: t('unitPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('total_price', {
      cell: ({ row }) => {
        const { quantity, price } = row.original;
        const total = roundNumber(price * quantity);

        return (
          <Text fontWeight={500} textAlign="center">{`${total} ${t(
            'SAR'
          )}`}</Text>
        );
      },
      header: t('totalPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const id = row.original.id;
        const bundleSKUs = row.original.bundle_skus;
        const isBundle = !!bundleSKUs;

        const handleDelete = () => {
          onSKUDelete(row.original.id);
        };

        const handleEdit = () => {
          setEditSKUId((prevValue) => (prevValue === id ? undefined : id));
        };

        const handleViewBundleSKUs = () => {
          setSelectedBundleSKUs(bundleSKUs);
          onViewSKUsOpen();
        };

        return (
          <Menu>
            <Center>
              <MenuButton as={IconButton} icon={<DotsVerticalIcon />} />
            </Center>
            <Portal>
              <MenuList>
                {isBundle && (
                  <MenuItem
                    textTransform="capitalize"
                    onClick={handleViewBundleSKUs}
                  >
                    {t('viewBundleSKUs')}
                  </MenuItem>
                )}
                <MenuItem textTransform="capitalize" onClick={handleEdit}>
                  {t('edit')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color="red.500"
                  onClick={handleDelete}
                >
                  {t('remove')}
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
    }),
  ];

  return (
    <>
      <TablePage
        title={
          <SectionTitle
            title={`${t('skuCart')} (${skus.length})`}
            hideDivider
          />
        }
        data={skus}
        columns={columns}
        footer={
          <Box pb={6} mt={2}>
            <SKUsTableForm onSubmit={handleSkuSubmit} isEcommerce />
          </Box>
        }
        emptyTable={
          <EmptyTable
            illustration={<SKUsEmptyTable />}
            title={t('noSKUsYet')}
            subtitle={`${t('createNewSKU')} ${t('or')} ${t('selectExisting', {
              value: t('theInbound'),
            })}`}
            noPadding
          />
        }
        showEmptyInsideBody
        hideSearch
        hidePagination
        enableSorting={false}
      />
      <BundleSKUsDrawer
        isOpen={isViewSKUsOpen}
        onClose={onViewSKUsClose}
        bundleSKUs={selectedBundleSKUs}
      />
    </>
  );
};

export default OrderSKUCart;
