import { Stack, Skeleton, SkeletonText } from '@chakra-ui/react';

const FormSkeleton = ({ numberOfInputs = 4, spacing = 4 }) => {
  return (
    <Stack spacing={spacing} w="full">
      {Array.from({ length: numberOfInputs }).map((_, index) => (
        <Stack key={index} spacing={2} width="full">
          <SkeletonText noOfLines={1} skeletonHeight={4} width="100px" />
          <SkeletonText noOfLines={1} skeletonHeight={8} width="full" />
        </Stack>
      ))}

      <Skeleton height="50px" borderRadius="md" mt={2} />
    </Stack>
  );
};

export default FormSkeleton;
