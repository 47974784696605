import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  useToast,
  Stack,
} from '@chakra-ui/react';
import Select from 'components/Dashboard/Select';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreateButtonsContainer from '../CreateButtonsContainer';
import { Link } from 'react-router-dom';
import { getFormSelectDefaultValue } from 'utils/select';
import PageDrawer from '../PageDrawer';
import { useMutation, useQuery } from 'react-query';
import { createSupplier } from 'api/Dashboard/suppliers';
import SupplierForm from '../Suppliers/SupplierForm';
import { flattenErrorObject } from 'utils/formError';
import { createAddress } from 'api/Dashboard/addresses';
import WithAddNewButton from '../WithAddNewButton';
import AddressFormDrawer from '../Orders/AddressFormDrawer';
import POCDetailsSection from '../Orders/POCDetailsSection';
import FormLocationSection from '../Orders/FormLocationSection';
import { getWarehouseTimeSlots } from 'api/Dashboard/warehouses';
import { formatDate } from 'utils/date';
import { getPickupTimeSlotsOptions } from 'utils/orders';
import UrgentModal from '../Orders/UrgentModal';

const InboundForm = ({
  onSubmit,
  defaultValues = {},
  inboundTypeOptions,
  originOptions,
  warehouseOptions,
  supplierOptions,
  isLoading,
  backendErrors,
  onAddNewSuccess,
  isEdit,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    handleSubmit,
    formState,
    control,
    register,
    setValue,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      ...defaultValues,
      time_of_pickup: undefined,
    },
  });

  const {
    isOpen: isSupplierOpen,
    onOpen: onSupplierOpen,
    onClose: onSupplierClose,
  } = useDisclosure();

  const {
    isOpen: isAddressOpen,
    onOpen: onAddressOpen,
    onClose: onAddressClose,
  } = useDisclosure();

  const {
    isOpen: isUrgentModalOpen,
    onOpen: onUrgentModalOpen,
    onClose: onUrgentModalClose,
  } = useDisclosure();

  const {
    mutate: createSupplierMutation,
    isLoading: isCreateSupplierLoading,
    error: createSupplierError,
  } = useMutation(createSupplier, {
    onSuccess: async (response) => {
      await onAddNewSuccess();
      setValue('supplier_id', response.data?.data?.id);
      onSupplierClose();
    },
    onError: () => {
      toast({
        title: t('createSupplierFailed'),
        status: 'error',
        duration: 5000,
      });
    },
  });

  const errors = { ...formState.errors, ...backendErrors };

  const watchScheduleOn = watch('schedule_at');

  const watchOrigin = watch('origin_id');
  const watchDestination = warehouseOptions?.find(
    (warehouseOption) => warehouseOption.value === watch('warehouse_id')
  );

  const enablePickupTime = !!watchDestination && !!watchScheduleOn;

  const {
    data: warehouseTimeSlots = {},
    isLoading: isWarehouseTimeSlotsLoading,
  } = useQuery(
    ['warehouse-time-slots', watchDestination, watchScheduleOn],
    () =>
      getWarehouseTimeSlots({
        warehouse_id: watchDestination.value,
        selected_date: formatDate(watchScheduleOn, 'dd/MM/yyyy'),
      }),
    {
      enabled: enablePickupTime,
    }
  );

  const pickupTimeSlots = warehouseTimeSlots.time_slots;

  const pickupTimeOptions = getPickupTimeSlotsOptions(pickupTimeSlots);

  const pickupTimeDefaultValue = getFormSelectDefaultValue(
    defaultValues.time_of_pickup,
    pickupTimeOptions
  );

  const {
    mutate: createAddressMutation,
    isLoading: isCreateAddressLoading,
    error: createAddressError,
  } = useMutation(createAddress, {
    onSuccess: async (response) => {
      await onAddNewSuccess();
      setValue('origin_id', response.data.data.id);
      onAddressClose();
    },
  });

  const isSubmitted = formState.isSubmitted;

  const handleUrgentModalClose = () => {
    setValue('time_of_pickup', null);

    onUrgentModalClose();

    if (isSubmitted) {
      trigger('time_of_pickup');
    }
  };

  const handleConfirmUrgent = () => {
    onUrgentModalClose();

    if (isSubmitted) {
      trigger('time_of_pickup');
    }
  };

  const handleTimeSlotChange = (option) => {
    setValue('time_of_pickup', option);

    const isUrgent = option.urgent;

    if (isUrgent) {
      onUrgentModalOpen();
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid templateColumns="repeat(7, 1fr)" gap={6}>
          <GridItem colSpan={{ base: 7, md: 4 }}>
            <Stack spacing={6}>
              <Card size="lg">
                <CardHeader>
                  <SectionTitle title={t('inboundDetails')} />
                </CardHeader>
                <CardBody>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    <Controller
                      control={control}
                      name="carrier_id"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('inboundType')}
                          placeholder={t('pleaseSelectYourInboundType')}
                          options={inboundTypeOptions}
                          onChange={({ value }) => onChange(value)}
                          error={errors?.carrier_id}
                          defaultValue={getFormSelectDefaultValue(
                            defaultValues.carrier_id,
                            inboundTypeOptions
                          )}
                          isDisabled={isEdit || isDisabled}
                          required
                        />
                      )}
                    />
                    <WithAddNewButton
                      onClick={onAddressOpen}
                      isDisabled={isDisabled}
                    >
                      <Controller
                        control={control}
                        name="origin_id"
                        rules={{ required: 'thisFieldIsRequired' }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={t('origin')}
                            placeholder={t('pleaseSelectTheOrigin')}
                            options={originOptions}
                            onChange={({ value }) => onChange(value)}
                            error={errors?.origin_id}
                            defaultValue={getFormSelectDefaultValue(
                              defaultValues.origin_id,
                              originOptions
                            )}
                            value={getFormSelectDefaultValue(
                              value,
                              originOptions
                            )}
                            isDisabled={isDisabled}
                            required
                          />
                        )}
                      />
                    </WithAddNewButton>
                    <Controller
                      control={control}
                      name="warehouse_id"
                      rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('warehouse')}
                          placeholder={t('pleaseSelectTheWarehouse')}
                          options={warehouseOptions}
                          onChange={({ value }) => onChange(value)}
                          error={errors?.warehouse_id}
                          defaultValue={getFormSelectDefaultValue(
                            defaultValues.warehouse_id,
                            warehouseOptions
                          )}
                          isDisabled={isEdit || isDisabled}
                          required
                        />
                      )}
                    />

                    <DatePicker
                      name="schedule_at"
                      label={t('scheduledOn')}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      required="thisFieldIsRequired"
                      minDate={new Date()}
                      formValue={watchScheduleOn}
                      control={control}
                      isDisabled={isDisabled}
                      onChange={async (date) => {
                        setValue('time_of_pickup', '');
                        await trigger('time_of_pickup');
                        await trigger('schedule_at');
                      }}
                    />

                    {((defaultValues.time_of_pickup &&
                      !isWarehouseTimeSlotsLoading) ||
                      !defaultValues.time_of_pickup) && (
                      <Controller
                        control={control}
                        name="time_of_pickup"
                        rules={{ required: 'thisFieldIsRequired' }}
                        defaultValue={pickupTimeDefaultValue}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={t('pickupTime')}
                            placeholder={t('timeOfPickup')}
                            options={pickupTimeOptions}
                            onChange={async (option) => {
                              handleTimeSlotChange(option);
                              await trigger('time_of_pickup');
                            }}
                            value={value}
                            error={errors?.time_of_pickup}
                            isDisabled={!enablePickupTime || isDisabled}
                            defaultValue={pickupTimeDefaultValue}
                            isLoading={isWarehouseTimeSlotsLoading}
                            noOptionsMessage="noAvailableSlotsOnTheSelectedDate"
                            required
                          />
                        )}
                      />
                    )}

                    <WithAddNewButton
                      onClick={onSupplierOpen}
                      isDisabled={isDisabled}
                    >
                      <Controller
                        control={control}
                        name="supplier_id"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={t('supplier')}
                            placeholder={t('pleaseSelectTheSupplier')}
                            options={supplierOptions}
                            onChange={({ value }) => onChange(value)}
                            defaultValue={getFormSelectDefaultValue(
                              defaultValues.supplier_id,
                              supplierOptions
                            )}
                            value={getFormSelectDefaultValue(
                              value,
                              supplierOptions
                            )}
                            isDisabled={isDisabled}
                            error={errors?.supplier_id}
                          />
                        )}
                      />
                    </WithAddNewButton>
                  </SimpleGrid>
                </CardBody>
              </Card>

              {watchOrigin && (
                <POCDetailsSection id={watchOrigin} isDisabled={isDisabled} />
              )}

              {watchDestination && (
                <POCDetailsSection id={watchDestination.id} isWarehouse />
              )}
            </Stack>
          </GridItem>
          <GridItem colSpan={{ base: 7, md: 3 }}>
            <FormLocationSection
              originId={watchOrigin}
              destinationId={watchDestination?.id}
            />
          </GridItem>
        </Grid>

        <CreateButtonsContainer>
          <Button
            colorScheme="red"
            variant="outline"
            bgColor="white"
            size="lg"
            minWidth="124px"
            isDisabled={isLoading}
            as={Link}
            to="/inbounds"
          >
            {t('cancel')}
          </Button>
          <Button
            colorScheme="primary"
            size="lg"
            minWidth="124px"
            type="submit"
            textTransform="capitalize"
            isLoading={isLoading}
          >
            {t('next')}
          </Button>
        </CreateButtonsContainer>
      </form>

      <PageDrawer
        title={t('suppliersPage.addNewSupplier')}
        isOpen={isSupplierOpen}
        onClose={() => onSupplierClose()}
        formId="supplierForm"
        isLoading={isCreateSupplierLoading}
      >
        <SupplierForm
          onSubmit={(values) => createSupplierMutation(values)}
          isDisabled={isCreateSupplierLoading}
          backendErrors={flattenErrorObject(
            createSupplierError?.response.data.errors
          )}
        />
      </PageDrawer>

      <AddressFormDrawer
        isOpen={isAddressOpen}
        onClose={onAddressClose}
        isLoading={isCreateAddressLoading}
        onSubmit={(data) => createAddressMutation(data)}
        backendErrors={createAddressError}
      />

      <UrgentModal
        isOpen={isUrgentModalOpen}
        onConfirm={handleConfirmUrgent}
        onClose={handleUrgentModalClose}
      />
    </>
  );
};

export default InboundForm;
