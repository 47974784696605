import {
  Stack,
  Button,
  Card,
  CardHeader,
  CardBody,
  useDisclosure,
  Hide,
  Flex,
  Box,
  Show,
} from '@chakra-ui/react';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import TablePage from 'components/Dashboard/TablePage';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import Input from 'components/shared/Inputs/Input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import useTable from 'hooks/useTable';
import { useCallback } from 'react';
import {
  getSKUGroupSKUs,
  getSKUGroupUsers,
  updateSKUGroup,
} from 'api/Dashboard/skuGroups';
import { createColumnHelper } from '@tanstack/react-table';
import { useMutation } from 'react-query';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import BackToListButton from '../BackToListButton';
import { AddIcon } from '@chakra-ui/icons';
import PageDrawer from '../PageDrawer';
import useLinkUnlink from 'hooks/useLinkUnlink';
import StatusTag from '../StatusTag';
import LinkSKUsDrawer from 'components/shared/LinkSKUsDrawer';

const columnHelper = createColumnHelper();

const UnLinkedUsersTable = ({ skuGroupId, onSubmit, columns }) => {
  const { t } = useTranslation();

  const { handleSubmit } = useForm();

  const {
    linked: linkedUsers,
    link: linkUser,
    unlink: unlinkUser,
  } = useLinkUnlink();

  const actionColumn = columnHelper.accessor('linked', {
    cell: ({ getValue, row }) => {
      const id = row.original.user_id;
      const isLinked = !!linkedUsers.find((userId) => userId === id);

      return (
        <Button
          variant="outline"
          color={isLinked ? 'red.600' : 'gray.700'}
          textTransform="capitalize"
          fontWeight={500}
          onClick={() => {
            if (isLinked) {
              unlinkUser(id);
              return;
            }

            linkUser(id);
          }}
          size="sm"
        >
          {t(isLinked ? 'unlink' : 'link')}
        </Button>
      );
    },
    header: t('actions'),
    meta: {
      isAction: true,
    },
  });

  const fetchFn = useCallback(
    async (searchParams) =>
      await getSKUGroupUsers(
        `${searchParams}&sku_group_id=${skuGroupId}&linked=false`
      ),
    [skuGroupId]
  );

  const {
    data: users = [],
    isLoading: isUsersLoading,
    onSearchChange,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['sku_group_users_unlinked', skuGroupId],
    searchKey: 'firstname_or_lastname_or_email_cont',
    disableURLParams: true,
  });

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onSubmit({ user_ids: linkedUsers });
      })}
      id="linkUsers"
      noValidate
    >
      <TablePage
        data={users}
        columns={[...columns, actionColumn]}
        pagination={pagination}
        title={t('usersList')}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('suppliersPage.tableSearchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isUsersLoading}
        enableSorting={false}
        noPadding
      />
    </form>
  );
};

const SKUGroupsFormPage = ({
  onSubmit,
  isLoading,
  defaultValues = {},
  skuGroupId,
  isFetching,
}) => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();

  const {
    isOpen: isLinkSKUOpen,
    onOpen: onLinkSKUOpen,
    onClose: onLinkSKUClose,
  } = useDisclosure();

  const {
    linked: linkedSKUs,
    link: linkSKU,
    unlink: unlinkSKU,
    setLinked: setLinkedSKUs,
  } = useLinkUnlink(defaultValues.sku_ids);

  const {
    isOpen: isLinkUserOpen,
    onOpen: onLinkUserOpen,
    onClose: onLinkUserClose,
  } = useDisclosure();

  const {
    linked: linkedUsers,
    link: linkUser,
    unlink: unlinkUser,
    setLinked: setLinkedUsers,
  } = useLinkUnlink(defaultValues.user_ids);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      name: defaultValues.name,
    },
  });

  const skusFetchFn = useCallback(
    async (searchParams) =>
      await getSKUGroupSKUs(
        `${searchParams}&sku_group_id=${skuGroupId}&linked=true`
      ),
    [skuGroupId]
  );

  const {
    data: skus = [],
    isLoading: isSKUsLoading,
    refetch: refetchSKUs,
    onSearchChange: onSKUsSearch,
    onPaginationChange: onSKUsPaginationChange,
    pagination: skusPagination,
  } = useTable({
    fetch: skusFetchFn,
    fetchKey: ['sku_group_skus', skuGroupId],
    searchKey: 'name_or_merchant_sku_or_system_sku_cont',
    disableURLParams: true,
  });

  const skusColumns = [
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => getValue(),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('sirdab_sku', {
      cell: ({ getValue }) => getValue(),
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('merchant_sku', {
      cell: ({ getValue }) => getValue(),
      header: t('merchantSKU'),
    }),
  ];

  const skusActionColumn = columnHelper.accessor('linked', {
    cell: ({ getValue, row }) => {
      const id = row.original.sku_id;
      const isLinked = !!linkedSKUs.find((skuId) => skuId === id);

      return (
        <Button
          variant="outline"
          color={isLinked ? 'red.600' : 'gray.700'}
          textTransform="capitalize"
          fontWeight={500}
          onClick={() => {
            if (isLinked) {
              unlinkSKU(id);
              return;
            }

            linkSKU(id);
          }}
          size="sm"
        >
          {t(isLinked ? 'unlink' : 'link')}
        </Button>
      );
    },
    header: t('actions'),
    meta: {
      isAction: true,
    },
  });

  const { mutate: updateSKUGroupMutation, isLoading: isUpdateSKUGroupLoading } =
    useMutation((payload) => updateSKUGroup(skuGroupId, payload), {
      onSuccess: ({ payload }) => {
        onLinkSKUClose();
        onLinkUserClose();
        refetchSKUs();
        refetchUsers();
        if (payload.sku_ids) {
          setLinkedSKUs((prevValue) => [
            ...new Set([...prevValue, ...payload.sku_ids]),
          ]);
        }
        if (payload.user_ids) {
          setLinkedUsers((prevValue) => [
            ...new Set([...prevValue, ...payload.user_ids]),
          ]);
        }
      },
    });

  const handleLinkSKUs = (data) => {
    updateSKUGroupMutation({ sku_ids: [...data.sku_ids, ...linkedSKUs] });
  };

  const usersFetchFn = useCallback(
    async (searchParams) =>
      await getSKUGroupUsers(
        `${searchParams}&sku_group_id=${skuGroupId}&linked=true`
      ),
    [skuGroupId]
  );

  const {
    data: users = [],
    isLoading: isUsersLoading,
    refetch: refetchUsers,
    onSearchChange: onUsersSearch,
    onPaginationChange: onUsersPaginationChange,
    pagination: usersPagination,
  } = useTable({
    fetch: usersFetchFn,
    fetchKey: ['sku_group_users', skuGroupId],
    searchKey: 'firstname_or_lastname_or_email_cont',
    disableURLParams: true,
  });

  const handleLinkUsers = (data) => {
    updateSKUGroupMutation({ user_ids: [...data.user_ids, ...linkedUsers] });
  };

  const usersColumns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('role', {
      cell: ({ getValue }) => t(getValue()),
      header: t('role'),
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => {
        const value = getValue();
        const isActive = value === 'Active';

        return (
          <StatusTag colorScheme={isActive ? 'green' : 'red'} size="sm">
            {t(value)}
          </StatusTag>
        );
      },
      header: t('status'),
      meta: {
        fitContent: true,
      },
    }),
  ];

  const usersActionColumn = columnHelper.accessor('linked', {
    cell: ({ getValue, row }) => {
      const id = row.original.user_id;
      const isLinked = !!linkedUsers.find((userId) => userId === id);

      return (
        <Button
          variant="outline"
          color={isLinked ? 'red.600' : 'gray.700'}
          textTransform="capitalize"
          fontWeight={500}
          onClick={() => {
            if (isLinked) {
              unlinkUser(id);
              return;
            }

            linkUser(id);
          }}
          size="sm"
        >
          {t(isLinked ? 'unlink' : 'link')}
        </Button>
      );
    },
    header: t('actions'),
    meta: {
      isAction: true,
    },
  });

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton
              title={t('permissionGroupList')}
              url="/sku-groups/permission-groups"
            />
          </Hide>
        </Flex>
      </Box>
      <Box>
        <Stack spacing={6}>
          <Card size="lg">
            <CardHeader>
              <SectionTitle title={t('permissionGroupDetails')} />
            </CardHeader>

            <CardBody>
              <form
                onSubmit={handleSubmit((values) => {
                  onSubmit({
                    ...values,
                    sku_ids: linkedSKUs,
                    user_ids: linkedUsers,
                  });
                })}
                noValidate
              >
                <Input
                  name="name"
                  label={t('skuGroupName')}
                  register={register}
                  errors={errors}
                  placeholder={t('skuGroupNamePlaceholder')}
                  size="lg"
                  required="thisFieldIsRequired"
                />

                <CreateButtonsContainer>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    bgColor="white"
                    size="lg"
                    minWidth="124px"
                    isDisabled={isLoading}
                    as={Link}
                    to="/sku-groups/permission-groups"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    colorScheme="primary"
                    size="lg"
                    minWidth="124px"
                    type="submit"
                    textTransform="capitalize"
                    isLoading={isLoading}
                  >
                    {t('confirm')}
                  </Button>
                </CreateButtonsContainer>
              </form>
            </CardBody>
          </Card>

          {skuGroupId && (
            <>
              {!isFetching && !isUpdateSKUGroupLoading && (
                <>
                  <TablePage
                    data={users}
                    columns={[...usersColumns, usersActionColumn]}
                    pagination={usersPagination}
                    title={
                      <SectionTitle title={t('linkedUsers')} hideDivider />
                    }
                    tableAction={
                      <Button
                        leftIcon={<AddIcon />}
                        colorScheme="primary"
                        textTransform="capitalize"
                        size="lg"
                        onClick={onLinkUserOpen}
                      >
                        {t('linkUsers')}
                      </Button>
                    }
                    onPaginationChange={onUsersPaginationChange}
                    searchPlaceholder={t(
                      'suppliersPage.tableSearchPlaceholder'
                    )}
                    onSearchChange={onUsersSearch}
                    isLoading={isUsersLoading}
                    enableSorting={false}
                  />
                  <TablePage
                    data={skus}
                    columns={[...skusColumns, skusActionColumn]}
                    pagination={skusPagination}
                    title={<SectionTitle title={t('linkedSKUs')} hideDivider />}
                    tableAction={
                      <Button
                        leftIcon={<AddIcon />}
                        colorScheme="primary"
                        textTransform="capitalize"
                        size="lg"
                        onClick={onLinkSKUOpen}
                      >
                        {t('linkSKUs')}
                      </Button>
                    }
                    onPaginationChange={onSKUsPaginationChange}
                    searchPlaceholder={t(
                      'suppliersPage.tableSearchPlaceholder'
                    )}
                    onSearchChange={onSKUsSearch}
                    isLoading={isSKUsLoading}
                    enableSorting={false}
                  />
                </>
              )}
              <LinkSKUsDrawer
                fetchKey="sku_group_skus_unlinked"
                fetchFunction={getSKUGroupSKUs}
                entityId={skuGroupId}
                fetchParams={{ sku_group_id: skuGroupId, linked: false }}
                columns={skusColumns}
                isOpen={isLinkSKUOpen}
                onClose={onLinkSKUClose}
                onSubmit={handleLinkSKUs}
                isLoading={isUpdateSKUGroupLoading}
              />
              <PageDrawer
                isOpen={isLinkUserOpen}
                onClose={onLinkUserClose}
                title={t('linkUsers')}
                size="xl"
                formId="linkUsers"
                isLoading={isUpdateSKUGroupLoading}
                noPaddingTop
              >
                <UnLinkedUsersTable
                  skuGroupId={skuGroupId}
                  onSubmit={handleLinkUsers}
                  columns={usersColumns}
                />
              </PageDrawer>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default SKUGroupsFormPage;
