import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/shared/Button';
import CodeVerification from 'components/shared/Inputs/CodeVerification';
import { useCountdown } from 'hooks/useCountdown';
import useAuth from 'hooks/useAuth';

export const COUNT_DOWN_INTERVAL = 30;

const Form = ({ onSubmit, responseError, isLoading }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm();
  const { isResendDisabled, resendCountdown, startCountdown } =
    useCountdown(COUNT_DOWN_INTERVAL);
  const { resendOTP } = useAuth();

  const errors = formState.errors;

  const handleFromSubmit = (data) => {
    onSubmit({
      verificationCode: data.verificationCode,
    });
  };

  const handleResendClick = () => {
    resendOTP();
    startCountdown();
  };

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit(handleFromSubmit)}
      autoComplete="off"
      noValidate
      data-testid="verify-otp-form"
    >
      <div className="mb-4">
        <CodeVerification name="verificationCode" control={control} />
        {(errors.verificationCode || responseError) && (
          <p className="text-red-700 mt-2">{t('codeYouEnteredIncorrect')}</p>
        )}
      </div>
      <Button
        type="submit"
        label={t('verifyAccount')}
        className="mt-2"
        isLoading={isLoading}
        data-testid="verify-otp-submit-button"
      />

      <p className="mt-2 text-sm leading-6 text-gray-500">
        {t('didNotReceiveEmail')}{' '}
        <button
          className="font-semibold text-primary hover:text-primary/[.85] disabled:text-gray-400 disabled:cursor-not-allowed"
          type="button"
          onClick={handleResendClick}
          disabled={isResendDisabled}
        >
          {isResendDisabled
            ? `${t('resend')} (${resendCountdown})`
            : t('resend')}
        </button>
      </p>
    </form>
  );
};

export default Form;
