import { useRef, useCallback } from 'react';

const useDebouncedCallback = (callback, delay = 500) => {
  const timeoutRef = useRef(null);

  return useCallback(
    (value) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(value);
      }, delay);
    },
    [callback, delay]
  );
};

export default useDebouncedCallback;
