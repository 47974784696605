import { Button, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';

const StyledEditIcon = styled(EditIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.primary[500]};
  }

  path:last-of-type {
    stroke: ${({ theme }) => theme.colors.primary[500]};
  }
`;

const EditActionButtons = ({
  isEditing,
  onEdit,
  onCancel,
  onConfirm,
  isSubmitting,
  size = 'sm',
}) => {
  const { t } = useTranslation();

  if (isEditing) {
    return (
      <HStack>
        <Button
          colorScheme="red"
          variant="outline"
          size={size}
          type="button"
          onClick={onCancel}
          isDisabled={isSubmitting}
        >
          {t('cancel')}
        </Button>
        <Button
          colorScheme="primary"
          size={size}
          type="submit"
          isLoading={isSubmitting}
          onClick={onConfirm}
        >
          {t('confirm')}
        </Button>
      </HStack>
    );
  }

  return (
    <Button
      colorScheme="primary"
      variant="ghost"
      size={size}
      onClick={onEdit}
      leftIcon={<StyledEditIcon width="24px" height="24px" />}
    >
      {t('edit')}
    </Button>
  );
};

export default EditActionButtons;
