import { Spinner } from '@chakra-ui/react';
import { createSKU, getOrganizationThreshold } from 'api/Dashboard/skus';
import SKUFormPage from 'components/Dashboard/SKUs/SKUFormPage';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { flattenErrorObject } from 'utils/formError';
import { useUser } from 'context/UserContext';

const SKUCreate = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const organizationId = user?.organization?.id;

  const { data: expiryThresholdData, isLoading: isThresholdLoading } = useQuery(
    ['organizationThreshold', organizationId],
    () => getOrganizationThreshold(organizationId),
    {
      enabled: !!organizationId,
      refetchOnMount: true,
    }
  );

  const expiryThresholdDefaultValue =
    expiryThresholdData &&
    expiryThresholdData.organization_expiry_threshold_in_days !== null
      ? parseInt(expiryThresholdData.organization_expiry_threshold_in_days, 10)
      : 90;

  const {
    mutate: createSKUMutation,
    isLoading: isCreateSKULoading,
    error: createSKUErrors,
  } = useMutation(createSKU, {
    onSuccess: ({ data }) => {
      navigate(`/skus/${data.id}`);
    },
  });

  const backendErrors = flattenErrorObject(
    createSKUErrors?.response?.data?.errors || {}
  );

  if (isThresholdLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const onSubmit = (values) => {
    const skuImage = values.image;

    const payload = {
      ...values,
      category_ids: values.category_ids?.map(({ value }) => value),
      image: skuImage
        ? {
            ...skuImage,
            base64: skuImage.base64.split(',')[1],
          }
        : skuImage,
    };

    createSKUMutation(payload);
  };

  const defaultValues = {
    near_expiry_threshold: expiryThresholdDefaultValue,
  };

  return (
    <SKUFormPage
      onSubmit={onSubmit}
      backendErrors={backendErrors}
      isLoading={isCreateSKULoading}
      defaultValues={defaultValues}
    />
  );
};

export default SKUCreate;
