import { Spinner } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const Validate = () => {
  const [searchParams] = useSearchParams();
  const magicLinkToken = searchParams.get('token');

  const {
    passwordlessLogin,
    isPasswordlessLoginLoading,
    passwordlessLoginError,
  } = useAuth();

  useEffect(() => {
    passwordlessLogin({ token: magicLinkToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPasswordlessLoginLoading) {
    return <Spinner size="lg" color="primary.500" />;
  }

  return passwordlessLoginError?.response?.data?.error;
};

export default Validate;
