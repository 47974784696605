import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
  useDisclosure,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as AlertIcon } from 'assets/images/alert-illustration.svg';
import styled from '@emotion/styled';
import { usePaymentDue } from 'context/PaymentDueContext';
import { useUser } from 'context/UserContext';

const StyledAlertIcon = styled(AlertIcon)`
  width: 112px;
  height: 112px;
  path {
    stroke: #e53e3e;
  }
`;

const UnpaidInvoiceModal = () => {
  const { t } = useTranslation();

  const { isPaymentDueForModal } = usePaymentDue();

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: isPaymentDueForModal,
  });

  const { isAdmin } = useUser();

  if (!isPaymentDueForModal) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent padding={0} borderRadius="2xl">
        <ModalHeader
          padding={6}
          borderBottom="1px solid"
          borderColor="gray.200"
          textTransform="capitalize"
        >
          {t('unpaidInvoices')}
        </ModalHeader>
        <ModalCloseButton mt={4} />

        <ModalBody padding={6}>
          <Stack spacing={6} align="center" textAlign="center">
            <StyledAlertIcon />
            <Box>
              {isAdmin
                ? t('invoiceModalDescriptionAdmin')
                : t('invoiceModalDescription')}
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter padding={6} borderTop="1px solid" borderColor="gray.200">
          <Flex gap={3} width="full" justifyContent="flex-end">
            <Button
              colorScheme={isAdmin ? undefined : 'red'}
              borderColor="gray.300"
              variant="outline"
              size="lg"
              minW="160px"
              onClick={onClose}
            >
              {t(isAdmin ? 'cancel' : 'close')}
            </Button>

            {isAdmin && (
              <Button
                as={RouterLink}
                to="/settings/billing"
                colorScheme="primary"
                size="lg"
                minW="160px"
                onClick={onClose}
              >
                {t('goToSettingsPage')}
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnpaidInvoiceModal;
