import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useMutation, useQuery } from 'react-query';
import { getOrganization, getUser } from 'api/auth';
import OrganizationInfoForm from 'components/Dashboard/Organization/OrganizationInfoForm';
import { updateOrganization } from 'api/Dashboard/organization';
import VerifyBusiness from './Billing/VerifyBusiness';
import SectionDataItem from 'components/Dashboard/SectionDataItem';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useUnverifiedOrganizationAlert } from 'context/UnverifiedOrganizationAlert';
import { getBillingAccountDetails } from 'api/Dashboard/settings';
import { useUser } from 'context/UserContext';
import { showIntercom } from 'utils/intercom';

const Organization = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { user, updateUser } = useUser();
  const { hideUnverifiedOrganizationAlert } = useUnverifiedOrganizationAlert();

  const { data: organizationData = {}, isLoading: isOrganizationDataLoading } =
    useQuery(['organization'], () => getOrganization(user.id), {
      refetchOnMount: true,
      cacheTime: 100,
    });

  const { refetch: refetchUser } = useQuery('user-me', getUser, {
    onSuccess: (data) => {
      updateUser(data);

      if (data.organization.verified) {
        hideUnverifiedOrganizationAlert();
      }
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: billingAccountDetails = {},
    refetch: refetchBillingAccountDetails,
  } = useQuery('billing-account-details', getBillingAccountDetails);

  const mobileNumber = organizationData.mobile_number;
  const expiryThreshold = organizationData.inventory_expiry_threshold_in_days;

  const personalInfoDefaultValues = {
    logo: [{ base64: organizationData?.logo }],
    name: organizationData.name ?? '',
    mobile_number: mobileNumber ? '' + mobileNumber : '',
    inventory_expiry_threshold_in_days: expiryThreshold
      ? '' + expiryThreshold
      : '',
  };

  const handleUpdateSuccess = () => {
    toast({
      title: t('organizationUpdatedSuccessfully'),
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const {
    mutate: updateOrganizationInfo,
    isLoading: isUpdateOrganizationInfo,
    error: updateOrganizationInfoError,
  } = useMutation(
    (organization) => updateOrganization(organizationData.id, organization),
    {
      onSuccess: handleUpdateSuccess,
    }
  );

  const isOrganizationVerified = billingAccountDetails.verified;
  const cr_number = billingAccountDetails.cr_number;
  const vat_number = billingAccountDetails.vat_number;

  const handleOrganizationSubmit = (data) => {
    const newLogo = data.logo[0];

    const logoPayload =
      newLogo.base64 === organizationData?.logo
        ? undefined
        : { ...newLogo, base64: newLogo.base64?.split(',')[1] };

    const organization = {
      ...data,
      logo: logoPayload,
    };

    updateOrganizationInfo(organization);
  };

  const handleDeleteLogo = () => {
    const organization = {
      logo: {
        _destroy: '1',
      },
    };

    updateOrganizationInfo(organization);
  };

  const onVerifyBusinessSuccess = () => {
    handleUpdateSuccess();
    refetchUser();
    refetchBillingAccountDetails();
  };

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={6} paddingTop={2}>
      <GridItem colSpan={{ base: 6, md: 6 }}>
        <Card size="lg">
          <CardHeader>
            <SectionTitle title={t('organizationInformation')} />
          </CardHeader>
          <CardBody>
            {isOrganizationDataLoading ? (
              <Spinner color="primary.500" />
            ) : (
              <OrganizationInfoForm
                onSubmit={handleOrganizationSubmit}
                defaultValues={personalInfoDefaultValues}
                isLoading={isUpdateOrganizationInfo}
                onDelete={handleDeleteLogo}
                backendErrors={
                  updateOrganizationInfoError?.response.data.errors
                }
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem colSpan={{ base: 6, md: 6 }}>
        <Card borderRadius="xl" size="lg">
          <CardHeader>
            <SectionTitle title={t('crVatNumbers')} />
          </CardHeader>
          <CardBody>
            {isOrganizationVerified ? (
              <Grid templateColumns="repeat(2, 1fr)" columnGap={16} rowGap={4}>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SectionDataItem label={t('crNumber')} value={cr_number} />
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SectionDataItem label={t('vatNumber')} value={vat_number} />
                </GridItem>
                <GridItem colSpan={2}>
                  <Text color="green.500" fontWeight="500">
                    <CheckCircleIcon color="green.500" marginEnd={1} />
                    {t('verificationSuccess')}
                    <Button
                      onClick={showIntercom}
                      display="inline"
                      variant="link"
                      color="green.600"
                      marginStart={1}
                      textTransform="capitalize"
                      textDecoration="underline"
                      fontWeight={700}
                    >
                      {t('customerSupport')}.
                    </Button>
                  </Text>
                </GridItem>
              </Grid>
            ) : (
              <VerifyBusiness
                onSuccess={onVerifyBusinessSuccess}
                defaultValues={{
                  cr_number,
                  vat_number,
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
};

export default Organization;
