import { useTranslation } from 'react-i18next';

const PAYMENT_METHODS = Object.freeze({
  CREDIT_CARD: 'credit_card',
  COD: 'cod',
  BANK: 'bank',
  APPLE_PAY: 'apple_pay',
  MADA: 'mada',
  STCPAY: 'stcpay',
  TABBY: 'tabby_installment',
  TAMARA: 'tamara_installment',
});

const usePaymentMethodOptions = () => {
  const { t } = useTranslation();

  const options = Object.keys(PAYMENT_METHODS).map((key) => ({
    value: PAYMENT_METHODS[key],
    label: t(`paymentMethodsEnum.${key}`),
  }));

  return options;
};

export default usePaymentMethodOptions;
