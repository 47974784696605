import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Hide,
  Show,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  confirmTransportationOrder,
  getTransportationOrder,
} from 'api/Dashboard/transportation';
import BackToListButton from 'components/Dashboard/BackToListButton';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import SectionDataItem from 'components/Dashboard/SectionDataItem';
import TablePage from 'components/Dashboard/TablePage';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import MapWithDirections from 'components/shared/MapWithDirections';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { formatDate } from 'utils/date';
import { translatePickupTime } from 'utils/orders';
import { handleNotFoundPage } from 'utils/notFoundPage';
import { useUser } from 'context/UserContext';

const columnHelper = createColumnHelper();

const TransportationSummary = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const { user } = useUser();

  const { data = {}, isLoading } = useQuery(
    ['transportation-order', id],
    () => getTransportationOrder(user.organization.id, id),
    {
      onError: handleNotFoundPage,
    }
  );

  const {
    mutate: confirmTransportationOrderMutation,
    isLoading: isConfirmTransportationOrderLoading,
  } = useMutation(() => confirmTransportationOrder(user.organization.id, id), {
    onSuccess: (data) => {
      toast({
        title: t('transportationOrderConfirmedSuccessfully'),
        status: 'success',
        duration: 2000,
      });
      navigate(`/transportations/${data.id}`);
    },
  });

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const columns = [
    columnHelper.accessor('sku.name', {
      cell: ({ getValue }) => <Text fontWeight={600}>{getValue()}</Text>,
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('sku.system_sku', {
      cell: ({ getValue }) => <Text fontWeight={500}>{getValue()}</Text>,
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('packaging', {
      cell: ({ getValue }) => <Text fontWeight={500}>{t(getValue())}</Text>,
      header: t('selectedPackaging'),
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue }) => <Text fontWeight={500}>{t(getValue())}</Text>,
      header: t('quantity'),
    }),
  ];

  const transportationOrder = data.transportation_order;

  const summaryOfOrderItems = [
    {
      label: t('originAddress'),
      value: data.origin?.name,
    },
    {
      label: t('destinationAddress'),
      value: data.destination?.name,
    },
    {
      label: t('truckSize'),
      value: t(`transportationOrderEnums.${transportationOrder?.truck_size}`),
    },
    {
      label: t('truckType'),
      value: t(`transportationOrderEnums.${transportationOrder?.truck_type}`),
    },
    {
      label: t('scheduledOn'),
      value: formatDate(data.date_of_trip, 'EEEE, dd MMM yyy'),
    },
    {
      label: t('pickupTime'),
      value: translatePickupTime(data.time_of_pickup),
    },
    {
      label: t('numberOfTrucks'),
      value: data.transportation_order?.trucks,
    },
    {
      label: t('numberOfLaborers'),
      value: data.transportation_order?.laborers,
    },
  ];

  const pocDetailsItems = [
    {
      label: t('name'),
      value: data.origin?.main_poc?.name,
    },
    {
      label: t('phoneNumber'),
      value: data.origin?.main_poc?.phone_number,
    },
  ];

  return (
    <>
      <Stack spacing={6} mb={{ base: 16, md: 0 }}>
        <Box>
          <Show below="md">
            <Box mb={2} mt={4}>
              <PageTitle title={pageTitle} fontWeight={600} wrap />
            </Box>
          </Show>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Hide below="md">
              <BackToListButton
                title={t('transportationsList')}
                url="/transportations"
              />
            </Hide>
          </Flex>
        </Box>

        <Grid templateColumns="repeat(7, 1fr)" gap={6}>
          <GridItem colSpan={{ base: 7, md: 5 }}>
            <Stack spacing={6}>
              <Card size="lg">
                <CardHeader>
                  <SectionTitle title={t('summaryOfOrder')} />
                </CardHeader>
                <CardBody>
                  <Stack spacing={4}>
                    {summaryOfOrderItems.map(({ label, value }, index) => (
                      <SectionDataItem
                        key={index}
                        label={label}
                        value={value}
                        wrapOnMobile
                      />
                    ))}
                  </Stack>
                </CardBody>
              </Card>

              <Card size="lg">
                <CardHeader>
                  <SectionTitle title={t('addressesPage.pocDetails')} />
                </CardHeader>
                <CardBody>
                  <Stack spacing={4}>
                    {pocDetailsItems.map(({ label, value }, index) => (
                      <SectionDataItem
                        key={index}
                        label={label}
                        value={value}
                      />
                    ))}
                  </Stack>
                </CardBody>
              </Card>
            </Stack>
          </GridItem>

          <GridItem colSpan={{ base: 7, md: 2 }} rowSpan={1}>
            <Card size="lg" height="full">
              <CardHeader>
                <SectionTitle title={t('mapView')} />
              </CardHeader>
              <CardBody>
                {data.origin && (
                  <MapWithDirections
                    containerStyle={{
                      width: '100%',
                      height: '100%',
                      minHeight: '300px',
                      borderRadius: '16px',
                    }}
                    origin={{
                      lat: data.origin?.latitude,
                      lng: data.origin?.longitude,
                    }}
                    destination={{
                      lat: data.destination?.latitude,
                      lng: data.destination?.longitude,
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>

          <GridItem colSpan={7}>
            <TablePage
              data={data.ab_trip_skus ?? []}
              columns={columns}
              title={<SectionTitle title={t('packingList')} hideDivider />}
              hidePagination
              hideSearch
              enableSorting={false}
              footer={<Box pb={6}></Box>}
            />
          </GridItem>
        </Grid>
      </Stack>
      <CreateButtonsContainer>
        <Flex
          flexFlow={{ base: 'column-reverse', md: 'row' }}
          width="full"
          gap={3}
        >
          <Button
            colorScheme="red"
            variant="outline"
            bgColor="white"
            size="lg"
            minWidth="124px"
            width="full"
            isDisabled={isConfirmTransportationOrderLoading}
            as={Link}
            to="/transportations"
          >
            {t('cancel')}
          </Button>
          <Button
            colorScheme="primary"
            size="lg"
            minWidth="124px"
            type="submit"
            width="full"
            textTransform="capitalize"
            isLoading={isConfirmTransportationOrderLoading}
            onClick={confirmTransportationOrderMutation}
          >
            {t('confirm')}
          </Button>
        </Flex>
      </CreateButtonsContainer>
    </>
  );
};

export default TransportationSummary;
