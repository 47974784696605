import api from 'utils/axios';

export const getInbounds = async (searchParams) => {
  const response = await api(`/merchants/inbounds?${searchParams}`);
  const { data } = response;

  return { data: data.data, meta: data.meta };
};

export const getInboundById = async (id) => {
  const response = await api(`/merchants/inbounds/${id}`);
  const { data } = response;

  return {
    data: data.data,
    chargeable_activities: data.chargeable_activities,
  };
};

export const getInboundSKUs = async (id, searchParams) => {
  const response = await api(
    `/merchants/inbounds/${id}/skus${searchParams ? `?${searchParams}` : ''}`
  );
  const { data } = response;

  return data;
};

export const cancelInbound = async (id) => {
  const response = await api.patch(`/merchants/inbounds/${id}/cancel`);
  const { data } = response;

  return data;
};

export const downloadReceivingSlip = async (id) => {
  const response = await api(`/merchants/inbounds/${id}/receiving_slip`, {
    responseType: 'blob',
  });

  return response;
};

export const downloadSKULabels = async (id, size) => {
  const response = await api(
    `/merchants/inbounds/${id}/generated_labels?size=${size}`
  );

  return response;
};

export const addSkuToInbound = async (id, inbound_sku) => {
  const response = await api.post(`/merchants/inbounds/${id}/add_sku`, {
    inbound_sku: {
      sku_id: inbound_sku.sku_id,
      merchant_sku_quantity: inbound_sku.quantity,
      merchant_sku_packaging: inbound_sku.packaging,
      merchant_sku_expiry: inbound_sku.expiry_date,
    },
  });

  return response.data;
};

export const editInboundSKU = async ({ id, skuID, inbound_sku }) => {
  const response = await api.patch(
    `/merchants/inbounds/${id}/update_sku/${skuID}`,
    { inbound_sku }
  );

  return response.data;
};

export const removeInboundSKU = async ({ id, skuID }) => {
  const response = await api.delete(
    `/merchants/inbounds/${id}/remove_sku/${skuID}`
  );

  return response.data;
};

export const requestNewInbound = async (id) => {
  const response = await api('/merchants/inbounds/new');

  return response.data;
};

export const createInbound = async (inbound) => {
  const response = await api.post('/merchants/inbounds', {
    inbound,
  });

  return response.data;
};

export const updateInbound = async (id, inbound) => {
  const response = await api.patch(`/merchants/inbounds/${id}`, {
    inbound,
  });

  return response.data;
};

export const submitInboundForReview = async (id) => {
  const response = await api.post(`/merchants/inbounds/${id}/submit`);

  return response.data;
};

export const getChargeableActivitiesSummary = async (id, searchParams) => {
  const response = await api(
    `/merchants/inbounds/${id}/chargeable_summary_tab?${searchParams}`
  );
  const { data } = response;

  return { data: data.data, meta: data.meta };
};

export const getChargeableActivitiesByInbound = async (id, searchParams) => {
  const response = await api(
    `/merchants/inbounds/${id}/chargeable_by_inbound_tab?${searchParams}`
  );
  const { data } = response;

  return { data: data.data, meta: data.meta };
};

export const getChargeableActivitiesBySKU = async (id, searchParams) => {
  const response = await api(
    `/merchants/inbounds/${id}/chargeable_by_sku_tab?${searchParams}`
  );
  const { data } = response;

  return { data: data.data, meta: data.meta };
};

export const getTransportationChargeableActivities = async (
  id,
  searchParams
) => {
  const response = await api(
    `/merchants/inbounds/${id}/chargeable_by_transportation_tab?${searchParams}`
  );
  const { data } = response;

  return { data: data.data, meta: data.meta };
};

export const importInboundSKU = async (id, file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await api.post(
    `/merchants/inbounds/${id}/import_skus`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
};

export const createInboundRate = async (id, review) => {
  const response = await api.post(`/merchants/inbounds/${id}/reviews`, {
    review,
  });

  return response.data;
};
