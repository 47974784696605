import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import TablePage from 'components/Dashboard/TablePage';
import { valueFallback } from 'utils/string';

const searchKey =
  'channel_product_name_or_channel_product_id_or_channel_variant_id_cont';

const columnHelper = createColumnHelper();

const ProductsTable = ({
  integrationName,
  data = [],
  search,
  isLoading,
  onSortingChange,
  onPaginationChange,
  pagination,
  onSearchChange,
  additionalColumns = [],
}) => {
  const { t } = useTranslation();

  const defaultColumns = [
    columnHelper.accessor('channel_product_name', {
      cell: ({ getValue }) => getValue(),
      header: `${integrationName} ${t('productName')}`,
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('integration_type', {
      cell: ({ row }) => {
        const variantId = row.original.channel_variant_id;
        const integrationType = !!variantId ? 'variant' : 'product';

        return t(`integrations.${integrationType}`);
      },
      header: t('type'),
      enableSorting: false,
    }),
    columnHelper.accessor('channel_product_id', {
      cell: ({ getValue }) => valueFallback(getValue()),
      header: t('productId'),
    }),
    columnHelper.accessor('channel_variant_id', {
      cell: ({ getValue }) => valueFallback(getValue()),
      header: t('variantId'),
    }),
  ];

  const tableColumns = [...defaultColumns, ...additionalColumns];

  return (
    <TablePage
      data={data}
      columns={tableColumns}
      pagination={pagination}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
      onSearchChange={onSearchChange}
      searchKey={searchKey}
      searchPlaceholder={t('integrations.searchProducts')}
      selectedSearch={search}
      noPadding
    />
  );
};

export default ProductsTable;
