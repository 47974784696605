import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Center,
  useDisclosure,
  useToast,
  Button,
  Text,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import Link from 'components/shared/Link';
import ProductsTable from './ProductsTable';
import { createColumnHelper } from '@tanstack/react-table';
import { mapProductToSKU } from 'api/Dashboard/integrations';
import { getSKUs } from 'api/Dashboard/skus';
import LinkSKUsDrawer from 'components/shared/LinkSKUsDrawer';
import BundleListDrawer from './BundleListDrawer';

const columnHelper = createColumnHelper();

const DrawerTableTitle = ({ product, isBundle = false }) => {
  const { t } = useTranslation();

  return (
    <Text>
      <Text as="span" fontSize="sm" color="gray.600">
        {`${t(isBundle ? 'linkedBundle' : 'linkedSKU')}: `}
      </Text>
      <Text as="span" textTransform="initial">
        {isBundle ? product?.bundle?.name : product?.sku?.name}
      </Text>
    </Text>
  );
};

const MappedProducts = ({
  integrationId,
  integrationName,
  data,
  isLoading,
  onSortingChange,
  onPaginationChange,
  pagination,
  onSearchChange,
  refetchData,
  search,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedProduct, setSelectedProduct] = useState(null);

  const {
    isOpen: isMapProductOpen,
    onOpen: onMapProductOpen,
    onClose: onMapProductClose,
  } = useDisclosure();

  const {
    isOpen: isMapBundleOpen,
    onOpen: onMapBundleOpen,
    onClose: onMapBundleClose,
  } = useDisclosure();

  const { mutate: mapProductMutation, isLoading: isMappingToSKU } = useMutation(
    (payload) => mapProductToSKU(integrationId, selectedProduct.id, payload),
    {
      onSuccess: () => {
        refetchData();
        onMapProductClose();
        onMapBundleClose();
        toast({
          title: t('integrations.productMappedSuccessfully'),
          status: 'success',
          duration: 3000,
        });
      },
    }
  );

  const handleMapProductMutation = (id, isBundle = false) => {
    if (isBundle) {
      mapProductMutation({ bundle_id: id });
    } else {
      mapProductMutation({ sku_id: id });
    }
  };

  const skuColumns = [
    columnHelper.accessor('name', {
      cell: ({ getValue, row }) => getValue(),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('system_sku', {
      cell: ({ getValue }) => getValue(),
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('merchant_sku', {
      cell: ({ getValue }) => getValue(),
      header: t('merchantSKU'),
    }),
  ];

  const additionalColumns = [
    columnHelper.accessor('linked_item', {
      cell: ({ row }) => {
        const { bundle_id, sku_id, bundle, sku } = row.original;

        const url = bundle_id
          ? `/sku-groups/bundles/${bundle_id}`
          : `/skus/${sku_id}`;

        const displayName = bundle_id ? bundle?.name : sku?.name;

        return <Link url={url}>{displayName}</Link>;
      },
      header: t('linkedSkuOrBundle'),
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const isBundle = row.original.bundle_id !== null;

        const handleUpdate = () => {
          setSelectedProduct(row.original);
          if (isBundle) {
            onMapBundleOpen();
          } else {
            onMapProductOpen();
          }
        };

        return (
          <Center>
            <Button
              size="sm"
              variant="outline"
              color="gray.600"
              onClick={handleUpdate}
            >
              {t('manage')}
            </Button>
          </Center>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <>
      <ProductsTable
        integrationName={integrationName}
        data={data}
        isLoading={isLoading}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        pagination={pagination}
        onSearchChange={onSearchChange}
        additionalColumns={additionalColumns}
        search={search}
      />

      {isMapProductOpen && (
        <LinkSKUsDrawer
          fetchKey="skus"
          fetchFunction={getSKUs}
          entityId={selectedProduct?.id}
          columns={skuColumns}
          isOpen={isMapProductOpen}
          onClose={onMapProductClose}
          title={selectedProduct?.channel_product_name}
          onSubmit={({ sku_ids }) => handleMapProductMutation(sku_ids[0])}
          isLoading={isMappingToSKU}
          defaultSelectedSKUs={[selectedProduct?.sku_id]}
          tableTitle={<DrawerTableTitle product={selectedProduct} />}
          singleSelection
          enableSorting
        />
      )}

      {isMapBundleOpen && (
        <BundleListDrawer
          isOpen={isMapBundleOpen}
          onClose={onMapBundleClose}
          title={selectedProduct?.channel_product_name}
          onSubmit={(data) => handleMapProductMutation(data.bundle_id, true)}
          isLoading={isMappingToSKU}
          defaultSelectedBundles={[selectedProduct?.bundle_id]}
          tableTitle={<DrawerTableTitle product={selectedProduct} isBundle />}
        />
      )}
    </>
  );
};

export default MappedProducts;
