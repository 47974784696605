import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { Button, Center } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import TablePage from 'components/Dashboard/TablePage';
import PageDrawer from 'components/Dashboard/PageDrawer';
import useLinkUnlink from 'hooks/useLinkUnlink';
import useTable from 'hooks/useTable';

const columnHelper = createColumnHelper();

const LinkSKUsDrawer = ({
  fetchKey,
  fetchFunction,
  searchKey = 'name_or_merchant_sku_or_system_sku_cont',
  entityId,
  fetchParams = {},
  columns = [],
  isOpen,
  onClose,
  title = 'linkSKUs',
  tableTitle,
  onSubmit,
  isLoading,
  singleSelection = false,
  enableSorting = false,
  defaultSelectedSKUs = [],
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  const formId = 'linkSKUs';

  const {
    linked: linkedSKUs,
    link: linkSKU,
    unlink: unlinkSKU,
    setLinked,
  } = useLinkUnlink(defaultSelectedSKUs);

  const handleLinkSKU = (id) => {
    if (singleSelection) {
      setLinked([id]);
    } else {
      linkSKU(id);
    }
  };

  const actionColumn = columnHelper.accessor('linked', {
    cell: ({ getValue, row }) => {
      const id = row.original.sku_id || row.original.id;
      const isLinked = !!linkedSKUs.find((skuId) => skuId === id);

      return (
        <Center>
          <Button
            variant="outline"
            color={isLinked ? 'red.600' : 'gray.700'}
            textTransform="capitalize"
            fontWeight={500}
            onClick={() => {
              if (isLinked) {
                unlinkSKU(id);
                return;
              }

              handleLinkSKU(id);
            }}
          >
            {t(isLinked ? 'unlink' : 'link')}
          </Button>
        </Center>
      );
    },
    header: t('actions'),
    meta: {
      isAction: true,
      centerHeader: true,
    },
    enableSorting: false,
  });

  const fetchFn = useCallback(
    async (searchParams) => {
      const params = { ...fetchParams };

      if (entityId) {
        params.entity_id = entityId;
      }

      const paramsString = new URLSearchParams(params).toString();

      return await fetchFunction(`${searchParams}&${paramsString}`);
    },
    [fetchFunction, entityId, fetchParams]
  );

  const {
    data: skus = [],
    isLoading: isSKUsLoading,
    onSearchChange,
    onPaginationChange,
    pagination,
    onSortingChange,
  } = useTable({
    fetch: fetchFn,
    fetchKey: [fetchKey, entityId],
    searchKey,
    disableURLParams: true,
  });

  const handleFormSubmit = (values) => {
    onSubmit({ sku_ids: linkedSKUs });
  };

  return (
    <PageDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t(title)}
      size="xl"
      formId={formId}
      isLoading={isLoading}
      noPaddingTop
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate id={formId}>
        <TablePage
          data={skus}
          title={tableTitle}
          columns={[...columns, actionColumn]}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          searchPlaceholder={t('skusPages.searchPlaceholder')}
          onSearchChange={onSearchChange}
          isLoading={isSKUsLoading}
          enableSorting={enableSorting}
          onSortingChange={onSortingChange}
          noPadding
        />
      </form>
    </PageDrawer>
  );
};

export default LinkSKUsDrawer;
