import { Skeleton, Stack, Grid, GridItem, SimpleGrid } from '@chakra-ui/react';
import { cardSpacing } from 'pages/Dashboard/Overview';

const OverviewSkeleton = () => {
  return (
    <Stack spacing={4}>
      <Stack spacing={cardSpacing} alignItems="stretch">
        <Grid
          templateRows={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
          templateColumns="repeat(6, 1fr)"
          gap={cardSpacing}
        >
          <GridItem
            colSpan={{ base: 6, md: 2 }}
            display={{ base: 'none', md: 'block' }}
          >
            <Skeleton height="180px" borderRadius="xl" />
          </GridItem>
          <GridItem
            colSpan={{ base: 6, md: 2 }}
            display={{ base: 'none', md: 'block' }}
          >
            <Skeleton height="180px" borderRadius="xl" />
          </GridItem>
          <GridItem
            colSpan={{ base: 6, md: 2 }}
            display={{ base: 'none', md: 'block' }}
          >
            <Skeleton height="180px" borderRadius="xl" />
          </GridItem>

          <GridItem colSpan={{ base: 6, md: 4 }} rowSpan={2}>
            <Skeleton height="400px" borderRadius="xl" />
          </GridItem>

          <GridItem
            colSpan={{ base: 6, md: 2 }}
            display={{ base: 'none', md: 'block' }}
          >
            <Skeleton height="180px" borderRadius="xl" />
          </GridItem>
          <GridItem
            colSpan={{ base: 6, md: 2 }}
            display={{ base: 'none', md: 'block' }}
          >
            <Skeleton height="180px" borderRadius="xl" />
          </GridItem>
        </Grid>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={cardSpacing}>
          <Skeleton height="300px" borderRadius="xl" />
          <Skeleton height="300px" borderRadius="xl" />
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default OverviewSkeleton;
