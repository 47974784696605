import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import InputField from 'components/shared/Inputs/InputField';
import Button from 'components/shared/Button';
import { passwordValidation } from 'constants/validation';

const Form = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = watch('password');

  const submit = (data) => {
    onSubmit(data);
  };

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit(submit)}
      data-testid="create-password-form"
      noValidate
    >
      <InputField
        id="password"
        name="password"
        type="password"
        label={t('password')}
        placeholder="••••••••"
        register={register}
        errors={errors}
        required={'validation.passwordRequired'}
        minLength={passwordValidation.minLength}
        maxLength={passwordValidation.maxLength}
      />
      <InputField
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label={t('confirmPassword')}
        placeholder="••••••••"
        register={register}
        errors={errors}
        required={'validation.confirmPasswordRequired'}
        minLength={passwordValidation.minLength}
        maxLength={passwordValidation.maxLength}
        validate={(value) =>
          value === password || 'validation.passwordsNotSame'
        }
      />
      <Button
        label={t('resetPassword')}
        type="submit"
        disabled={errors.agree?.message}
        isLoading={isLoading}
        data-testid="create-password-submit"
      />

      <p className="mt-2">
        <Link
          to="/sign-in"
          className="font-semibold text-sm text-primary hover:text-primary/[.85]"
        >
          {t('returnToLogin')}
        </Link>
      </p>
    </form>
  );
};

export default Form;
