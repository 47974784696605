import {
  Stack,
  Box,
  Show,
  Flex,
  Button,
  Hide,
  SimpleGrid,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import BackToListButton from 'components/Dashboard/BackToListButton';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { useMutation, useQuery } from 'react-query';
import {
  cancelInbound,
  createInboundRate,
  downloadReceivingSlip,
  getInboundById,
  getInboundSKUs,
  submitInboundForReview,
} from 'api/Dashboard/inbounds';
import StatusSection from 'components/Dashboard/Inbounds/StatusSection';
import DetailsSection from 'components/Dashboard/Inbounds/DetailsSection';
import LocationSection from 'components/Dashboard/Inbounds/LocationSection';
import SKUsSection from 'components/Dashboard/Inbounds/SKUsSection';
import CommentsSection from 'components/Dashboard/Inbounds/CommentsSection';
import AttachmentsSection from 'components/Dashboard/Orders/AttachmentsSection';
import OperationalActivitiesSummary from 'components/Dashboard/Inbounds/OperationalActivitiesSummary';
import StatusHistorySection from 'components/Dashboard/Orders/StatusHistorySection';
import { openPDF } from 'utils/pdf';
import { useContext, useRef } from 'react';
import { AbilityContext } from 'context/AbilityContext';
import ChargeableActivities from 'components/Dashboard/Orders/ChargeableActivities';
import InboundOutboundSkeleton from 'components/Dashboard/Orders/InboundOutboundSkeleton';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';
import RatingModal from 'components/Dashboard/Orders/RatingModal';
import { handleNotFoundPage } from 'utils/notFoundPage';
import useConfirmInbound from 'hooks/useConfirmInbound';
import CapacityWarningModal from 'components/Dashboard/Inbounds/CapacityWarningModal';
import { showErrorToast } from 'utils/toast';

const StyledEditIcon = styled(EditIcon)`
  fill: #03989e;
  max-width: 20px;
  max-height: 20px;
  path {
    stroke: #fff;
  }

  path:last-of-type {
    stroke: #03989e;
  }
`;

const oneItemStatuses = [
  {
    value: 'created',
    label: 'inboundDetailsPage.inboundStatus.created',
    color: 'purple',
  },
  {
    value: 'in_review',
    label: 'inboundDetailsPage.inboundStatus.in_review',
    color: 'yellow',
  },
  {
    value: 'draft',
    label: 'inboundDetailsPage.inboundStatus.draft',
    color: 'gray',
  },
  {
    value: 'on_hold',
    label: 'orderStatus.on_hold',
    color: 'gray',
  },
  {
    value: 'cancelled',
    label: 'inboundDetailsPage.inboundStatus.cancelled',
    color: 'red',
  },
  {
    value: 'pending_approval',
    label: 'orderStatus.pending_approval',
    color: 'pink',
  },
];

const InboundDetails = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const toast = useToast();

  const {
    isOpen: isRatingModalOpen,
    onOpen: onRatingModalOpen,
    onClose: onRatingModalClose,
  } = useDisclosure();

  const {
    isOpen: isCapacityModalOpen,
    onOpen: onCapacityModalOpen,
    onClose: onCapacityModalClose,
  } = useDisclosure();

  const isFirstFetch = useRef(true);

  const ability = useContext(AbilityContext);
  const canConfirm = ability.can('confirm', 'inbound');

  const {
    data: { data: inboundDetails = {} } = {},
    refetch,
    isLoading,
  } = useQuery(['inbound-details', id], () => getInboundById(id), {
    onSuccess: (data) => {
      const hasRating = data.data.review_rating;
      const isCompleted = data.data.status === 'complete';

      if (isFirstFetch.current && !hasRating && isCompleted) {
        onRatingModalOpen();
        isFirstFetch.current = false;
      }
    },
    onError: handleNotFoundPage,
    refetchOnMount: true,
  });

  const { data: inboundSKUs = { data: [] }, refetch: refetchInboundSKUs } =
    useQuery(['inbound-skus', id], () => getInboundSKUs(id));

  const {
    confirmInbound,
    validateAndConfirm,
    isConfirmInboundLoading,
    isValidatingCapacity,
    capacityData,
  } = useConfirmInbound(
    { id, warehouseId: inboundDetails?.warehouse_id },
    {
      onSuccess: refetch,
      onCapacityExceeded: onCapacityModalOpen,
    }
  );

  const { mutate: submitInbound, isLoading: isSubmitInboundLoading } =
    useMutation(() => submitInboundForReview(id), {
      onSuccess: () => {
        toast({
          title: t('submittedSuccessfully'),
          status: 'success',
          duration: 3000,
        });
        refetch();
      },
    });

  const { mutate: cancelInboundMutation, isLoading: isCancelInboundLoading } =
    useMutation(cancelInbound, {
      onSuccess: () => {
        refetch();
      },
    });

  const {
    refetch: downloadReceivingSlipQuery,
    isLoading: isDownloadReceivingSlipLoading,
  } = useQuery(
    ['download-receiving-slip', id],
    () => downloadReceivingSlip(id),
    {
      enabled: false,
      onSuccess: (response) => openPDF(response?.data),
    }
  );

  const { mutate: rateInboundMutation, isLoading: isRateInboundLoading } =
    useMutation((review) => createInboundRate(id, review), {
      onSuccess: () => {
        refetch();
        onRatingModalClose();
      },
    });

  const onRateSubmit = (data) => {
    rateInboundMutation(data);
  };

  const handleDeleteInbound = () => {
    cancelInboundMutation(id);
  };

  const isDraft = inboundDetails.status === 'draft';

  const activeOneItemStatus = oneItemStatuses.find(
    (item) => item.value === inboundDetails.status
  );

  const handleConfirmOrSubmit = () => {
    if (inboundSKUs.data.length === 0) {
      return showErrorToast('skuError');
    }

    if (canConfirm) {
      validateAndConfirm();
    } else {
      submitInbound();
    }
  };

  const handleProceedAnyway = () => {
    onCapacityModalClose();
    confirmInbound();
  };

  if (isLoading) {
    return <InboundOutboundSkeleton />;
  }

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mb={8} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton title={t('inboundsList')} url="/inbounds" />
          </Hide>
          <Flex gap={4} flex={1} justifyContent="flex-end" flexWrap="wrap">
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="gray"
              textTransform="capitalize"
              size="lg"
              bgColor="white"
              color="gray.600"
              width={{ base: 'full', md: 'auto' }}
              onClick={() => {
                downloadReceivingSlipQuery();
              }}
              isLoading={isDownloadReceivingSlipLoading}
            >
              {t('downloadReceivingSlip')}
            </Button>

            {isDraft && (
              <Button
                size="lg"
                colorScheme="gray"
                bgColor="white"
                color="red.500"
                textTransform="capitalize"
                width={{ base: 'full', md: 'auto' }}
                isLoading={isCancelInboundLoading}
                onClick={handleDeleteInbound}
                leftIcon={
                  <CloseIcon
                    border="2px solid"
                    borderColor="red.500"
                    borderRadius="full"
                    padding={1}
                    boxSize="20px"
                  />
                }
              >
                {t('cancelInbound')}
              </Button>
            )}

            {isDraft && (
              <Button
                variant="outline"
                colorScheme="primary"
                backgroundColor="white"
                size="lg"
                width={{ base: 'full', md: 'auto' }}
                leftIcon={<StyledEditIcon />}
                as={Link}
                to="edit"
              >
                {t('editInbound')}
              </Button>
            )}

            {isDraft && (
              <Button
                colorScheme="primary"
                size="lg"
                textTransform="capitalize"
                minWidth="124px"
                width={{ base: 'full', md: 'auto' }}
                onClick={handleConfirmOrSubmit}
                isLoading={
                  canConfirm
                    ? isConfirmInboundLoading || isValidatingCapacity
                    : isSubmitInboundLoading
                }
              >
                {t(canConfirm ? 'confirmInbound' : 'submitForReview')}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>

      {!activeOneItemStatus && !isLoading && (
        <StatusSection activeStatus={inboundDetails.status} />
      )}

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <Flex gap={6} flexDirection="column">
          <DetailsSection
            inboundDetails={inboundDetails}
            onSuccess={refetch}
            activeOneItemStatus={activeOneItemStatus}
          />
        </Flex>

        <LocationSection inboundDetails={inboundDetails} />
      </SimpleGrid>

      <SKUsSection
        inboundId={id}
        status={inboundDetails.status}
        onUpdateSuccess={refetchInboundSKUs}
      />

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <CommentsSection
          id={id}
          comments={inboundDetails.comments}
          onSuccess={refetch}
          isDisabled={!isDraft}
        />

        {!isLoading && (
          <AttachmentsSection
            id={id}
            attachments={inboundDetails.attachments}
            onSuccess={refetch}
            attachmentType="Manifest"
          />
        )}
      </SimpleGrid>

      <ChargeableActivities orderId={id} />

      <OperationalActivitiesSummary activities={inboundDetails.activities} />

      <StatusHistorySection statusHistories={inboundDetails.status_histories} />

      <CapacityWarningModal
        isOpen={isCapacityModalOpen}
        onClose={onCapacityModalClose}
        capacityData={capacityData}
        onProceed={handleProceedAnyway}
      />

      <RatingModal
        isOpen={isRatingModalOpen}
        onClose={onRatingModalClose}
        onSubmit={onRateSubmit}
        isLoading={isRateInboundLoading}
      />
    </Stack>
  );
};

export default InboundDetails;
