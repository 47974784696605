import {
  Flex,
  Image,
  useBreakpointValue,
  useOutsideClick,
  useTheme,
  Link as ChakraLink,
  IconButton,
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import sirdabLogo from 'assets/images/Sirdab_Logo-01.png';
import { useRef } from 'react';

import styled from '@emotion/styled';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as SidebarIcon } from 'assets/icons/sidebar.svg';
import { ReactComponent as ReferIcon } from 'assets/icons/refer.svg';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/shared/Tooltip';
import useIsMobile from 'hooks/useIsMobile';
import { useUser } from 'context/UserContext';

const navActiveStyle = {
  bg: '#F1F5F8',
  color: 'primary.500',
  fontWeight: '600',
};

const NavItem = ({
  activeLink,
  icon: Icon,
  children,
  path,
  isOpen,
  isActive,
  isStrokeIcon,
  target,
  isAccordion,
  subItems,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname: currentPath } = useLocation();
  const { isMobile } = useIsMobile();

  const primaryColor = theme.colors.primary[500];

  const ActiveIcon =
    Icon &&
    styled(Icon)`
      path {
        stroke: ${isStrokeIcon ? primaryColor : ''};
        fill: ${isStrokeIcon ? '' : primaryColor};
      }
    `;

  const NavItemContent = (
    <Flex
      align="center"
      padding={3}
      gap={isOpen ? 4 : 0}
      borderRadius="xl"
      role="group"
      cursor="pointer"
      color="gray.600"
      fontWeight="500"
      width="full"
      _hover={navActiveStyle}
      sx={isActive ? navActiveStyle : undefined}
      {...rest}
    >
      {Icon && <Box>{isActive ? <ActiveIcon /> : <Icon />}</Box>}

      <Text
        hidden={!isOpen}
        noOfLines={1}
        textTransform="capitalize"
        flex={isAccordion ? '1' : undefined}
      >
        {children}
      </Text>

      {isAccordion && isOpen && <AccordionIcon />}
    </Flex>
  );

  if (isAccordion && isOpen) {
    const activeSubItem = subItems?.find((subitem) =>
      currentPath?.startsWith(activeLink?.path + subitem.path)
    );

    return (
      <Accordion defaultIndex={activeSubItem ? 0 : undefined} allowToggle>
        <AccordionItem border="none">
          <AccordionButton as={Box} p={0} _hover={{ bg: 'transparent' }}>
            {NavItemContent}
          </AccordionButton>
          <AccordionPanel pl={7}>
            <Stack
              spacing={3}
              paddingInlineStart={2}
              borderInlineStartWidth="1px"
              borderColor="gray.200"
            >
              {subItems?.map((subitem) => {
                const isSubitemActive = activeSubItem?.path === subitem.path;

                if (subitem.isInnerPage) {
                  return null;
                }

                return (
                  <ChakraLink
                    key={subitem.name}
                    as={ReactRouterLink}
                    to={path + subitem.path}
                    _hover={{ textDecoration: 'none' }}
                    padding={2}
                    paddingInlineStart={4}
                    fontSize="sm"
                    fontWeight={500}
                    onClick={isMobile ? onClose : undefined}
                  >
                    <Text
                      color={isSubitemActive ? 'primary.500' : 'gray.600'}
                      fontWeight={isSubitemActive ? '600' : '500'}
                      _hover={{ color: 'primary.500' }}
                      textTransform="capitalize"
                    >
                      {t(subitem.name)}
                    </Text>
                  </ChakraLink>
                );
              })}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <ChakraLink
      as={ReactRouterLink}
      to={path}
      _hover={{ textDecoration: 'none' }}
      target={target}
    >
      {isOpen ? (
        NavItemContent
      ) : (
        <Tooltip label={children} placement="left">
          {NavItemContent}
        </Tooltip>
      )}
    </ChakraLink>
  );
};

const Sidebar = ({
  size,
  isOpen,
  onClose,
  onToggle,
  linkItems,
  activeLink,
  ...rest
}) => {
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const { user } = useUser();

  const isMobile = useIsMobile();

  const clickOutsideHandler = useBreakpointValue({
    md: undefined,
    base: onClose,
  });

  const ref = useRef();

  useOutsideClick({
    ref,
    handler: clickOutsideHandler,
  });

  const handleSignOut = () => {
    signOut();
  };

  const topLinkItems = linkItems.filter((item) => !item.bottom);
  const bottomLinkItems = linkItems.filter((item) => item.bottom);

  return (
    <Flex
      bg="white"
      w={isOpen ? size : undefined}
      transition=".2s"
      h="full"
      direction="column"
      position={{ base: 'fixed', md: 'static' }}
      display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }}
      zIndex={3}
      ref={ref}
      {...rest}
    >
      <Box px={4} flexShrink="0">
        <Flex
          alignItems="center"
          py={{ base: 4, md: 6 }}
          px={isOpen ? 3 : 0}
          justifyContent={isOpen ? 'space-between' : 'center'}
          borderBottom="1px"
          borderBottomColor="gray.200"
        >
          <ReactRouterLink to="/">
            <Image
              src={sirdabLogo}
              alt="Sirdab Logo"
              height={{ base: '25px', md: '30px' }}
              hidden={!isOpen}
            />
          </ReactRouterLink>

          <IconButton
            size="lg"
            onClick={onToggle}
            icon={<SidebarIcon />}
            variant="ghost"
          />
        </Flex>
      </Box>

      <Flex
        direction="column"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
        padding={4}
        gap={3}
        overflowY="auto"
      >
        <Flex gap="1" direction="column" w="full">
          {topLinkItems.map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              isOpen={isOpen}
              isActive={link.name === activeLink?.name}
              isStrokeIcon={link.isStrokeIcon}
              path={link.path}
              onClick={isMobile && !link.isAccordion ? onClose : undefined}
              isAccordion={link.isAccordion}
              subItems={link.children}
              activeLink={activeLink}
              onClose={onClose}
            >
              {t(link.name)}
            </NavItem>
          ))}
        </Flex>
        <Flex gap="1" direction="column" w="full">
          <NavItem
            target="_blank"
            path={`https://airtable.com/app4pShtVmQecBM59/shrbTN8Y2N74PqnTj?prefill_Name=${user.name}`}
            icon={ReferIcon}
            isOpen={isOpen}
            fontSize="xs"
            bg="primary.100"
            color="primary.500"
            _hover={{
              bg: 'primary.100',
              color: 'primary.500',
            }}
          >
            {t('referAndGet300Sar')}
          </NavItem>
          {bottomLinkItems.map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              isOpen={isOpen}
              isActive={link.name === activeLink?.name}
              isStrokeIcon={link.isStrokeIcon}
              path={link.path}
              onClick={isMobile ? onClose : undefined}
              onClose={onClose}
            >
              {t(link.name)}
            </NavItem>
          ))}
          <NavItem
            as="button"
            icon={Logout}
            onClick={handleSignOut}
            isOpen={isOpen}
          >
            {t('logout')}
          </NavItem>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
