import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Portal,
  Flex,
  useBoolean,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';
import SKUsTableForm from 'components/Dashboard/Orders/SKUsTableForm';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import Input from 'components/shared/Inputs/Input';
import TablePage from 'components/Dashboard/TablePage';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import { useState } from 'react';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { NUMBER_MAX_VALUE } from 'constants/validation';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import { showErrorToast } from 'utils/toast';
import useIsMobile from 'hooks/useIsMobile';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { ARABIC_LETTERS_PATTERN, ENGLISH_LETTERS_PATTERN } from 'utils/regex';
import BackToListButton from '../BackToListButton';
import { valueFallback } from 'utils/string';
import EditActionButtons from 'components/shared/EditActionButtons';
import Link from 'components/shared/Link';

const columnHelper = createColumnHelper();

const BundleFormPage = ({
  onSubmit,
  isSubmitting,
  isFetching,
  isSKUChangeLoading,
  defaultValues = {},
  bundleSkus,
  onSKUSubmit,
  onSKUDelete,
  onSKUEdit,
}) => {
  const { t } = useTranslation();

  const [editSKUId, setEditSKUId] = useState(undefined);

  const isEdit = !!Object.keys(defaultValues).length;

  const [disableInputs, disableInputsActions] = useBoolean(isEdit);

  const { control, trigger } = useForm();

  const isMobile = useIsMobile();

  const formDefaultValues = {
    name: defaultValues.name,
    name_locale: defaultValues.name_locale,
    merchant_sku: defaultValues.merchant_sku,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: formDefaultValues });

  const handleFormSubmit = (data) => {
    if (bundleSkus.length === 0) {
      showErrorToast('skuError');
      return;
    }

    const payload = {
      ...data,
      bundle_skus_attributes: bundleSkus.map((bundleSku) => ({
        sku_id: bundleSku.sku.id,
        quantity: bundleSku.quantity,
      })),
    };

    onSubmit(payload, disableInputsActions.on);
  };

  const columns = [
    columnHelper.accessor('sku', {
      cell: ({ getValue }) => {
        const sku = getValue();
        const url = `/skus/${sku.id}`;

        return <Link url={url}>{sku.name}</Link>;
      },
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('sku.system_sku', {
      cell: ({ getValue }) => (
        <Text fontWeight={500} color="gray.800">
          {valueFallback(getValue())}
        </Text>
      ),
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('sku.merchant_sku', {
      cell: ({ getValue }) => (
        <Text fontWeight={500} color="gray.800">
          {valueFallback(getValue())}
        </Text>
      ),
      header: t('merchantSKU'),
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue, row }) => {
        const id = row.original.sku.id;

        if (editSKUId && id === editSKUId) {
          const defaultValue = getValue();
          const name = `${row.original.sku.id}-quantity`;

          return (
            <Box maxWidth={{ base: '90px', md: '100px' }}>
              <Controller
                control={control}
                name={name}
                rules={{
                  required: t('thisFieldIsRequired'),
                  max: {
                    value: NUMBER_MAX_VALUE,
                    message: `${t('maxIs')} ${NUMBER_MAX_VALUE}`,
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DebouncedNumberInput
                    placeholder="quantity"
                    defaultValue={defaultValue}
                    min={1}
                    max={NUMBER_MAX_VALUE}
                    errorMessage={error?.message}
                    value={value}
                    isDisabled={isSKUChangeLoading}
                    onChange={async (value) => {
                      onChange(value);
                      await trigger(name);

                      onSKUEdit(id, {
                        key: 'quantity',
                        value: +value,
                      });
                    }}
                  />
                )}
              />
            </Box>
          );
        }
        return (
          <Text color="gray.800" fontWeight={500}>
            {getValue()}
          </Text>
        );
      },
      header: t('quantity'),
    }),
    isEdit
      ? null
      : columnHelper.accessor('actions', {
          cell: ({ row }) => {
            const id = row.original.sku?.id;

            const handleEdit = () => {
              setEditSKUId((prevValue) => (prevValue === id ? undefined : id));
            };

            const handleDelete = () => {
              onSKUDelete(id);
              setEditSKUId(undefined);
            };

            if (isMobile) {
              return (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<DotsVerticalIcon />}
                    size="sm"
                  />
                  <Portal>
                    <MenuList>
                      <MenuItem
                        textTransform="capitalize"
                        onClick={handleEdit}
                        isDisabled={isSKUChangeLoading}
                      >
                        {t('edit')}
                      </MenuItem>
                      <MenuItem
                        textTransform="capitalize"
                        color="red.500"
                        onClick={handleDelete}
                        isDisabled={isSKUChangeLoading}
                      >
                        {t('unlink')}
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              );
            }

            return (
              <HStack gap={3} justifyContent="center">
                <Button
                  variant="outline"
                  color="red.600"
                  fontWeight={500}
                  onClick={handleDelete}
                  isDisabled={isSKUChangeLoading}
                >
                  {t('unlink')}
                </Button>
                <Button
                  variant="outline"
                  color="gray.600"
                  fontWeight={500}
                  onClick={handleEdit}
                  isDisabled={isSKUChangeLoading}
                >
                  {t('edit')}
                </Button>
              </HStack>
            );
          },
          header: t('actions'),
          meta: {
            isAction: true,
            centerHeader: true,
          },
        }),
  ].filter(Boolean);

  return (
    <Stack spacing={6}>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <BackToListButton
          title={t('skusBundleList')}
          url="/sku-groups/bundles"
        />
      </Flex>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Stack spacing={6}>
          <Card>
            <CardHeader>
              <SectionTitle
                title={
                  <Flex
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text as="span">{t('skuBundleDetails')}</Text>
                    {isEdit && (
                      <EditActionButtons
                        isEditing={!disableInputs}
                        onEdit={disableInputsActions.off}
                        onCancel={() => {
                          disableInputsActions.on();
                          reset(formDefaultValues);
                        }}
                        onConfirm={handleSubmit(handleFormSubmit)}
                        isSubmitting={isSubmitting}
                      />
                    )}
                  </Flex>
                }
              />
            </CardHeader>
            <CardBody as={Stack} spacing={5}>
              <HStack
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="flex-start"
                spacing={5}
              >
                <Input
                  name="name"
                  label={t('englishName')}
                  placeholder={t('bundleNamePlaceholder', {
                    type: t('theEnglish'),
                  })}
                  register={register}
                  errors={errors}
                  required={t('thisFieldIsRequired')}
                  size="lg"
                  disabled={isFetching || disableInputs}
                  defaultValue={defaultValues.name}
                  pattern={{
                    value: ENGLISH_LETTERS_PATTERN,
                    message: t('englishLettersOnly'),
                  }}
                />

                <Input
                  name="name_locale"
                  label={t('arabicName')}
                  placeholder={t('bundleNamePlaceholder', {
                    type: t('theArabic'),
                  })}
                  register={register}
                  errors={errors}
                  pattern={{
                    value: ARABIC_LETTERS_PATTERN,
                    message: t('arabicLettersOnly'),
                  }}
                  size="lg"
                  disabled={isFetching || disableInputs}
                  defaultValue={defaultValues.name_locale}
                />
              </HStack>

              <Input
                name="merchant_sku"
                label={t('merchantSKU')}
                placeholder={t('merchantSKUPlaceholder')}
                register={register}
                errors={errors}
                size="lg"
                disabled={isFetching || disableInputs}
                defaultValue={defaultValues.merchant_sku}
              />
            </CardBody>
          </Card>

          {!isEdit && (
            <CreateButtonsContainer>
              <Button
                colorScheme="red"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                as={ReactRouterLink}
                to="/sku-groups/bundles"
                isDisabled={isSubmitting || isFetching}
              >
                {t('cancel')}
              </Button>
              <Button
                colorScheme="primary"
                size="lg"
                minWidth="124px"
                type="submit"
                textTransform="capitalize"
                isLoading={isSubmitting}
                isDisabled={isFetching}
              >
                {t('confirm')}
              </Button>
            </CreateButtonsContainer>
          )}
        </Stack>
      </form>

      <TablePage
        data={bundleSkus}
        columns={columns}
        title={<SectionTitle title={t('linkedSKUs')} hideDivider />}
        footer={
          !isEdit && (
            <Box pb={6} mt={2}>
              <SKUsTableForm
                onSubmit={onSKUSubmit}
                hideExpiry
                isLinkToBundle
                isDisabled={isFetching}
              />
            </Box>
          )
        }
        emptyTable={
          <EmptyTable
            illustration={<SKUsEmptyTable />}
            title={t('noLinkedSKUsYet')}
            subtitle={t('pleaseSelectSKUFromTheBelowList')}
            noPadding
          />
        }
        showEmptyInsideBody
        hideSearch
        hidePagination
        enableSorting={false}
        isLoading={isFetching}
      />
    </Stack>
  );
};

export default BundleFormPage;
