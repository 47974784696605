import { useEffect, useMemo, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import { setAmplitudeUserData } from 'utils/amplitude';
import { deleteLocalStorage } from 'utils/localStorage';
import { deleteToken } from 'utils/token';
import { updateIntercom } from 'utils/intercom';
import Sentry from 'utils/sentry';
import { useUser } from 'context/UserContext';

const initializeTracking = (user) => {
  setAmplitudeUserData({
    email: user.email,
    role: user.role,
    company: user.organization.name,
    companyID: user.organization.id,
  });

  updateIntercom({
    id: user.id,
    name: user.name,
    email: user.email,
    phone: user.phone,
    organization_id: user.organization.id,
    organization_name: user.organization.name,
    phone_number: user.phone,
    organization_type: user.role === 'lead' ? 'LeadOrg' : 'MerchantOrg',
    user_role: user.role,
    verified: user.organization.verified,
    has_active_quote: user.organization?.has_active_quote,
    org_created_at: user.organization?.created_at,
  });

  Sentry.setUser({
    id: user.id,
    email: user.email,
  });
};

const verifyOtpRoute = '/sign-up/verify-otp';

const AuthenticatedLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const trackingInitialized = useRef(false);

  const { token } = useAuth();
  const { user, hasUser } = useUser();

  const isUserAuthenticated = useMemo(
    () => !!(token && hasUser),
    [token, hasUser]
  );

  const isLeadQuoteRoute = useMemo(
    () => location.pathname.includes('/lead-quote'),
    [location.pathname]
  );

  const isNotVerified = useMemo(
    () => !user.otp_verified_at,
    [user.otp_verified_at]
  );

  const isOnVerifyRoute = useMemo(
    () => location.pathname.includes(verifyOtpRoute),
    [location.pathname]
  );

  const isNotVerifiedAndNotOnVerifyRoute = useMemo(
    () => isNotVerified && !isOnVerifyRoute,
    [isNotVerified, isOnVerifyRoute]
  );

  useEffect(() => {
    if (isLeadQuoteRoute) {
      return;
    }

    if (!isUserAuthenticated) {
      deleteLocalStorage('user');
      deleteToken();

      const returnTo = encodeURIComponent(location.pathname + location.search);
      navigate(`/sign-in?returnTo=${returnTo}`, { replace: true });
      return;
    }

    if (!trackingInitialized.current) {
      initializeTracking(user);
      trackingInitialized.current = true;
    }

    if (isNotVerified) {
      if (!isOnVerifyRoute) navigate(verifyOtpRoute, { replace: true });
      return;
    }

    if (!user.onboarding_finished) {
      navigate('/warehouses', { replace: true });
      return;
    }
  }, [
    user,
    isLeadQuoteRoute,
    isNotVerified,
    isOnVerifyRoute,
    isUserAuthenticated,
    location.pathname,
    location.search,
    navigate,
  ]);

  if (
    !isLeadQuoteRoute &&
    (!isUserAuthenticated || isNotVerifiedAndNotOnVerifyRoute)
  ) {
    return null;
  }

  return (
    <Flex direction="column" width="100%">
      <Outlet />
    </Flex>
  );
};

export default AuthenticatedLayout;
