import { useTranslation } from 'react-i18next';

export const ORDER_STATUSES = Object.freeze({
  CREATED: 'created',
  PREPARING: 'preparing',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
  QUARANTINED: 'quarantined',
});

const ORDER_STATUS_COLORS = Object.freeze({
  [ORDER_STATUSES.CREATED]: 'blue',
  [ORDER_STATUSES.PREPARING]: 'primary',
  [ORDER_STATUSES.SHIPPED]: 'pink',
  [ORDER_STATUSES.DELIVERED]: 'green',
  [ORDER_STATUSES.CANCELLED]: 'red',
  [ORDER_STATUSES.QUARANTINED]: 'gray',
});

const useOrderStatusOptions = () => {
  const { t } = useTranslation();

  const options = Object.values(ORDER_STATUSES).map((status) => ({
    value: status,
    label: t(`fulfillmentOrderStatus.${status}`),
  }));

  return {
    options,
    colors: ORDER_STATUS_COLORS,
    statuses: ORDER_STATUSES,
  };
};

export default useOrderStatusOptions;
