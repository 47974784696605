import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { useUser } from 'context/UserContext';

const UnauthenticatedLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { token } = useAuth();
  const { hasUser } = useUser();

  const isUserAuthenticated = useMemo(
    () => !!(token && hasUser),
    [token, hasUser]
  );

  useEffect(() => {
    if (isUserAuthenticated) {
      const searchParams = new URLSearchParams(location.search);
      const returnTo = searchParams.get('returnTo');
      const navigateTo = returnTo ? decodeURIComponent(returnTo) : '/';

      navigate(navigateTo, { replace: true });
      return;
    }
  }, [isUserAuthenticated, location.search, navigate]);

  return <Outlet />;
};

export default UnauthenticatedLayout;
