import { Button, Center } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/Dashboard/ActionsButton';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { Link, useNavigate } from 'react-router-dom';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { getBundles } from 'api/Dashboard/bundles';
import { AddIcon } from '@chakra-ui/icons';
import { valueFallback } from 'utils/string';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import i18n from 'utils/i18n';

const columnHelper = createColumnHelper();

const defaultSorting = [
  {
    id: 'created_at',
    desc: true,
  },
];

const CreateBundleButton = () => {
  const { t } = useTranslation();

  return (
    <Button
      colorScheme="primary"
      size="lg"
      as={Link}
      to="/sku-groups/bundles/create"
      leftIcon={<AddIcon />}
    >
      {t('bundlePages.createNewBundle')}
    </Button>
  );
};

export const getBundleColumns = ({ renderActions }) => {
  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: i18n.t('englishName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('name_locale', {
      cell: ({ getValue }) => valueFallback(getValue()),
      header: i18n.t('arabicName'),
    }),
    columnHelper.accessor('merchant_sku', {
      cell: ({ getValue }) => valueFallback(getValue()),
      header: i18n.t('merchantSKU'),
    }),
    columnHelper.accessor('skus_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: i18n.t('totalSKUs'),
      meta: {
        fitContent: true,
        centerHeader: true,
      },
    }),
  ];

  if (typeof renderActions === 'function') {
    columns.push(
      columnHelper.accessor('actions', {
        cell: renderActions,
        header: i18n.t('actions'),
        meta: {
          isAction: true,
          centerHeader: true,
        },
        enableSorting: false,
      })
    );
  }

  return columns;
};

const Bundles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getBundles,
    fetchKey: 'bundles',
    defaultState: {
      sorting: defaultSorting,
    },
    searchKey: 'name_or_name_locale_cont',
  });

  const renderActions = ({ row }) => {
    return (
      <Center>
        <ActionsButton
          onClick={() => {
            navigate(`/sku-groups/bundles/${row.original.id}`);
          }}
        >
          {t('view')}
        </ActionsButton>
      </Center>
    );
  };

  const columns = getBundleColumns({
    renderActions,
  });

  return (
    <TablePage
      data={data}
      columns={columns}
      pagination={pagination}
      title={t('bundlePages.skuBundles')}
      pageAction={<CreateBundleButton />}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      searchPlaceholder={t('bundlePages.searchPlaceholder')}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      defaultSorting={defaultSorting}
      emptyTable={
        <EmptyTable
          illustration={<SKUsEmptyTable />}
          title={t('emptyStateTitle', { value: t('bundlePages.skuBundles') })}
          subtitle={t('emptyStateSubtitle', { value: t('newBundle') })}
          actions={<CreateBundleButton />}
        />
      }
    />
  );
};

export default Bundles;
