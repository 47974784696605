import {
  Stack,
  Box,
  Show,
  Flex,
  Button,
  SimpleGrid,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Text,
  HStack,
  Hide,
  Center,
  Image,
  Menu,
  MenuButton,
  IconButton,
  Portal,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import BackToListButton from 'components/Dashboard/BackToListButton';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { useMutation, useQuery } from 'react-query';
import {
  cancelOrder,
  getOrderDetails,
  updateOrderSKU,
} from 'api/Dashboard/fulfillment/orders';
import Comments from 'components/Dashboard/Comments';
import StatusHistorySection from 'components/Dashboard/Orders/StatusHistorySection';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import OrderStatusDetails from 'components/Dashboard/OrderStatusDetails';
import { createColumnHelper } from '@tanstack/react-table';
import Table from 'components/Dashboard/Table';
import OrderDetailsSkeleton from 'components/Dashboard/Orders/OrderDetailsSkeleton';
import { handleNotFoundPage } from 'utils/notFoundPage';
import { ReactComponent as UserSquareIcon } from 'assets/icons/user-square.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as HashIcon } from 'assets/icons/hashtag-up.svg';
import { ReactComponent as ElementPlusIcon } from 'assets/icons/element-plus.svg';
import { ReactComponent as ArticleIcon } from 'assets/icons/article.svg';
import { ReactComponent as GlobalIcon } from 'assets/icons/global.svg';
import { ReactComponent as MapPinIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as MapLocationIcon } from 'assets/icons/map-location.svg';
import { ReactComponent as ZipCodeIcon } from 'assets/icons/zip-code.svg';
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/location-outline.svg';
import { ReactComponent as BuildingIcon } from 'assets/icons/building.svg';
import { ReactComponent as BillIcon } from 'assets/icons/bill.svg';
import { formatDate } from 'utils/date';
import { valueFallback } from 'utils/string';
import ActionsButton from 'components/Dashboard/ActionsButton';
import { useState } from 'react';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import OrderStatusTag from 'components/Dashboard/OrderStatusTag';
import { addComment } from 'api/Dashboard/comments';
import { roundNumber } from 'utils/number';
import sallaLogo from 'assets/images/salla-logo.png';
import shopifyLogo from 'assets/images/shopify-logo.png';
import zidLogo from 'assets/images/zid-logo.png';
import { ECOMMERCE_CHANNELS } from './OrderCreate';
import TruncatedText from 'components/shared/TruncatedText';
import { ReactComponent as DollarSendCircleIcon } from 'assets/icons/dollar-circle.svg';
import { BundleSKUsDrawer } from 'components/Dashboard/Fulfillment/Orders/OrderSKUCart';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import usePaymentMethodOptions from 'hooks/usePaymentMethodOptions';
import { ORDER_STATUSES } from 'hooks/useOrderStatusOptions';

const columnHelper = createColumnHelper();

const orderStatusOptions = [
  { value: ORDER_STATUSES.CREATED, label: 'fulfillmentOrderStatus.created' },
  {
    value: ORDER_STATUSES.PREPARING,
    label: 'fulfillmentOrderStatus.preparing',
  },
  { value: ORDER_STATUSES.SHIPPED, label: 'fulfillmentOrderStatus.shipped' },
  {
    value: ORDER_STATUSES.DELIVERED,
    label: 'fulfillmentOrderStatus.delivered',
  },
];

const oneItemStatuses = [
  {
    value: ORDER_STATUSES.CANCELLED,
    label: 'fulfillmentOrderStatus.cancelled',
    color: 'red',
  },
  {
    value: ORDER_STATUSES.QUARANTINED,
    label: 'fulfillmentOrderStatus.quarantined',
    color: 'yellow',
  },
];

const StatusSection = ({ activeStatus }) => {
  const { t } = useTranslation();

  const isOneItemStatus = oneItemStatuses.some(
    (status) => status.value === activeStatus
  );

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('orders.orderStatus')} />
      </CardHeader>
      <CardBody>
        {isOneItemStatus ? (
          <OrderStatusDetails
            statuses={oneItemStatuses.filter(
              (status) => status.value === activeStatus
            )}
            activeStatus={activeStatus}
            statusColor="red"
          />
        ) : (
          <OrderStatusDetails
            statuses={orderStatusOptions}
            activeStatus={activeStatus}
          />
        )}
      </CardBody>
    </Card>
  );
};

const DetailsFieldList = ({ fields }) => {
  return (
    <Stack spacing={4}>
      {fields.map((item, index) => (
        <Flex
          key={index}
          gap={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack color="primary.700" spacing={3}>
            <Box width="24px" height="24px">
              {item.icon}
            </Box>
            <Text fontWeight={500} color="gray.600" textTransform="capitalize">
              {item.label}
            </Text>
          </HStack>
          <Text as="div" fontWeight={500}>
            {item.value || '-'}
          </Text>
        </Flex>
      ))}
    </Stack>
  );
};

const channelLogos = {
  [ECOMMERCE_CHANNELS.SALLA]: sallaLogo,
  [ECOMMERCE_CHANNELS.SHOPIFY]: shopifyLogo,
  [ECOMMERCE_CHANNELS.ZID]: zidLogo,
  [ECOMMERCE_CHANNELS.OFFLINE]: null,
};

const OrderDetailsSection = ({ orderDetails }) => {
  const { t } = useTranslation();

  const channelLogo = channelLogos[orderDetails.channel];

  const paymentMethodOptions = usePaymentMethodOptions();

  const paymentMethod = paymentMethodOptions.find(
    (option) => option.value === orderDetails?.payment_method
  );

  const detailsFields = [
    {
      icon: <HashIcon />,
      label: (
        <Text as="span" textTransform="uppercase">
          {t('id')}
        </Text>
      ),
      value: orderDetails.identifier,
    },
    {
      icon: <CalendarIcon />,
      label: t('createdAt'),
      value: formatDate(orderDetails.created_at),
    },
    {
      icon: <UserSquareIcon />,
      label: t('customerName'),
      value: `${orderDetails.customer_first_name} ${orderDetails.customer_last_name}`,
    },
    {
      icon: <MobileIcon />,
      label: t('customerPhoneNumber'),
      value: orderDetails.customer_phone,
    },
    {
      icon: <DollarSendCircleIcon />,
      label: t('paymentMethod'),
      value: (
        <Text as="span" textTransform="capitalize">
          {valueFallback(paymentMethod?.label)}
        </Text>
      ),
    },
    {
      icon: <ElementPlusIcon />,
      label: t('salesChannel'),
      value: (
        <HStack>
          <Text as="span" textTransform="capitalize">
            {orderDetails.channel}
          </Text>

          {channelLogo && (
            <Image src={channelLogo} alt={orderDetails.channel} height="24px" />
          )}
        </HStack>
      ),
    },
    {
      icon: <ArticleIcon />,
      label: t('salesChannelOrderId'),
      value: (
        <TruncatedText text={valueFallback(orderDetails.channel_order_id)} />
      ),
    },
    {
      icon: <ArticleIcon />,
      label: t('externalReferenceId'),
      value: (
        <TruncatedText
          text={valueFallback(orderDetails.external_reference_id)}
        />
      ),
    },
    {
      icon: <BillIcon />,
      label: t('totalPrice'),
      value: `${roundNumber(orderDetails.total)} ${t('SAR')}`,
    },
  ];

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('orders.orderDetails')} />
      </CardHeader>
      <CardBody>
        <DetailsFieldList fields={detailsFields} />
      </CardBody>
    </Card>
  );
};

const LocationSection = ({ orderDetails }) => {
  const { t } = useTranslation();

  const addressFields = [
    {
      icon: <GlobalIcon />,
      label: t('country'),
      value: valueFallback(orderDetails?.shipping_country),
    },
    {
      icon: <MapPinIcon />,
      label: t('city'),
      value: valueFallback(orderDetails?.shipping_city),
    },
    {
      icon: <MapLocationIcon />,
      label: t('districtName'),
      value: valueFallback(orderDetails?.shipping_district_name),
    },
    {
      icon: <LocationOutlineIcon />,
      label: t('streetName'),
      value: valueFallback(orderDetails?.shipping_street_name),
    },
    {
      icon: <BuildingIcon />,
      label: t('buildingNumber'),
      value: valueFallback(orderDetails?.shipping_building_number),
    },
    {
      icon: <ZipCodeIcon />,
      label: t('zipCode'),
      value: valueFallback(orderDetails?.shipping_zip),
    },
    {
      icon: <LocationOutlineIcon />,
      label: t('additionalDetails'),
      value: valueFallback(orderDetails?.shipping_additional_details),
    },
  ];

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('locationDetails')} />
      </CardHeader>
      <CardBody>
        <DetailsFieldList fields={addressFields} />
      </CardBody>
    </Card>
  );
};

const OrderSKUsSection = ({ orderSKUs = [], onSuccess }) => {
  const { t } = useTranslation();
  const [editSKUId, setEditSKUId] = useState(null);
  const [selectedBundleSKUs, setSelectedBundleSKUs] = useState(null);
  const toast = useToast();

  const {
    isOpen: isViewSKUsOpen,
    onOpen: onViewSKUsOpen,
    onClose: onViewSKUsClose,
  } = useDisclosure();

  const { mutate: updateSKUMutation, isLoading: isUpdatingSKU } = useMutation(
    ({ id, unit_base_price }) => updateOrderSKU(id, unit_base_price),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        toast({
          title: error?.response?.data?.message || t('somethingWentWrong'),
          status: 'error',
          duration: 3000,
        });
      },
    }
  );

  const handleSkuChange = (id, { key, value }) => {
    updateSKUMutation({ id, unit_base_price: +value });
  };

  const columns = [
    columnHelper.accessor('', {
      cell: ({ getValue, row }) => {
        const { sku, bundle } = row.original;

        return (
          <Text color="gray.800" fontWeight={600}>
            {bundle ? bundle.name : sku.name}
          </Text>
        );
      },
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('sku.system_sku', {
      cell: ({ getValue }) => (
        <Text color="gray.800" fontWeight={500}>
          {valueFallback(getValue())}
        </Text>
      ),
      header: t('sirdabSKU'),
    }),
    columnHelper.accessor('sku.merchant_sku', {
      cell: ({ getValue }) => (
        <Text color="gray.800" fontWeight={500}>
          {valueFallback(getValue())}
        </Text>
      ),
      header: t('merchantSKU'),
    }),
    columnHelper.accessor('quantity', {
      cell: ({ getValue }) => (
        <Text color="gray.800" fontWeight={500}>
          {getValue()}
        </Text>
      ),
      header: t('quantity'),
    }),
    columnHelper.accessor('unit_base_price', {
      cell: ({ getValue, row }) => {
        const { id } = row.original;

        if (editSKUId && id === editSKUId) {
          const defaultValue = getValue();

          return (
            <Center>
              <Box maxWidth={{ base: '130px', md: '150px' }}>
                <DebouncedNumberInput
                  placeholder={t('unitPrice')}
                  name="price"
                  defaultValue={defaultValue}
                  min={1}
                  onChange={(value) => {
                    handleSkuChange(id, {
                      key: 'price',
                      value,
                    });
                  }}
                  isDisabled={isUpdatingSKU}
                />
              </Box>
            </Center>
          );
        }

        return (
          <Center color="gray.800" fontWeight={500}>
            {`${roundNumber(getValue())} ${t('SAR')}`}
          </Center>
        );
      },
      header: t('unitPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('total_price', {
      cell: ({ row }) => {
        const { quantity, unit_base_price } = row.original;

        const total = roundNumber(unit_base_price * quantity);

        return (
          <Text fontWeight={500} textAlign="center">{`${total} ${t(
            'SAR'
          )}`}</Text>
        );
      },
      header: t('totalPrice'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('action', {
      cell: ({ row }) => {
        const { id, bundle } = row.original;
        const isBundle = !!bundle;
        const bundleSKUs = bundle?.bundle_skus;

        const handleViewBundleSKUs = () => {
          setSelectedBundleSKUs(bundleSKUs);
          onViewSKUsOpen();
        };

        return (
          <Menu>
            <Center>
              <MenuButton as={IconButton} icon={<DotsVerticalIcon />} />
            </Center>
            <Portal>
              <MenuList>
                {isBundle && (
                  <MenuItem
                    textTransform="capitalize"
                    onClick={handleViewBundleSKUs}
                  >
                    {t('viewBundleSKUs')}
                  </MenuItem>
                )}
                <MenuItem
                  textTransform="capitalize"
                  onClick={() => {
                    setEditSKUId((prevValue) =>
                      prevValue === id ? undefined : id
                    );
                  }}
                >
                  {t('editPrice')}
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
    }),
  ];

  return (
    <>
      <Card size="lg">
        <CardHeader>
          <SectionTitle title={t('orders.orderSKUs')} hideDivider />
        </CardHeader>
        <CardBody mt={2}>
          <Table
            data={orderSKUs}
            columns={columns}
            enableSorting={false}
            hidePagination
          />
        </CardBody>
      </Card>
      <BundleSKUsDrawer
        isOpen={isViewSKUsOpen}
        onClose={onViewSKUsClose}
        bundleSKUs={selectedBundleSKUs}
      />
    </>
  );
};

const LinkedOutboundsSection = ({ outbounds }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('schedule_at', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('scheduledAt'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('warehouse_name', {
      cell: ({ getValue }) => valueFallback(getValue()),
      header: t('warehouse'),
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => <OrderStatusTag status={getValue()} />,
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => {
              navigate(`/outbounds/${row.original.id}`);
            }}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('orders.linkedOutbounds')} hideDivider />
      </CardHeader>
      <CardBody mt={2}>
        <Table
          data={outbounds}
          columns={columns}
          enableSorting={false}
          hidePagination
        />
      </CardBody>
    </Card>
  );
};

const CommentsSection = ({ id, comments = [], onSuccess }) => {
  const { t } = useTranslation();
  const { mutate: addCommentMutation, isLoading: isAddingComment } =
    useMutation(addComment, {
      onSuccess: (data) => {
        onSuccess();
      },
    });

  const handleCommentSubmit = ({ comment }) => {
    addCommentMutation({
      commentable_id: id,
      commentable_type: 'FulfillmentOrder',
      body: comment,
    });
  };

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={`${t('comments')} (${comments.length})`} />
      </CardHeader>
      <CardBody>
        <Comments
          comments={comments}
          onSubmit={handleCommentSubmit}
          isLoading={isAddingComment}
        />
      </CardBody>
    </Card>
  );
};

const OrderDetails = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const toast = useToast();

  const {
    data: { data: orderDetails = {} } = {},
    refetch,
    isLoading,
  } = useQuery(['fulfillment-order-details', id], () => getOrderDetails(id), {
    onError: handleNotFoundPage,
  });

  const { mutate: cancelOrderMutation, isLoading: isCancelOrderLoading } =
    useMutation(() => cancelOrder(id), {
      onSuccess: () => {
        toast({
          title: t('orderCancelledSuccessfully'),
          status: 'success',
          duration: 3000,
        });
        refetch();
      },
      onError: (error) => {
        toast({
          title: error?.response?.data?.errors?.[0] || t('somethingWentWrong'),
          status: 'error',
          duration: 3000,
        });
      },
    });

  if (isLoading) {
    return <OrderDetailsSkeleton />;
  }

  return (
    <Stack spacing={6}>
      <Box>
        <Show below="md">
          <Box mb={8} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap" gap={3}>
          <Hide below="md">
            <BackToListButton
              title={t('orders.orders')}
              url="/fulfillment/orders"
            />
          </Hide>
          {orderDetails.status === 'created' && (
            <Button
              size="lg"
              colorScheme="gray"
              bgColor="white"
              color="red.500"
              textTransform="capitalize"
              width={{ base: 'full', md: 'auto' }}
              isLoading={isCancelOrderLoading}
              onClick={cancelOrderMutation}
              leftIcon={
                <CloseIcon
                  border="2px solid"
                  borderColor="red.500"
                  borderRadius="full"
                  padding={1}
                  boxSize="20px"
                />
              }
            >
              {t('cancelOrder')}
            </Button>
          )}
        </Flex>
      </Box>

      <StatusSection activeStatus={orderDetails.status} />

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <OrderDetailsSection orderDetails={orderDetails} />
        <LocationSection orderDetails={orderDetails} />
      </SimpleGrid>

      <OrderSKUsSection
        orderSKUs={orderDetails.fulfillment_order_skus}
        onSuccess={() => {
          toast({
            title: t('priceUpdatedSuccessfully'),
            status: 'success',
            duration: 3000,
          });
          refetch();
        }}
      />

      <LinkedOutboundsSection outbounds={orderDetails.outbounds} />

      <CommentsSection
        id={id}
        comments={orderDetails.comments ?? []}
        onSuccess={() => {
          toast({
            title: t('commentAddedSuccessfully'),
            status: 'success',
            duration: 3000,
          });
          refetch();
        }}
      />

      <StatusHistorySection statusHistories={orderDetails.status_histories} />
    </Stack>
  );
};

export default OrderDetails;
