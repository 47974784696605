import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import SignUpForm from 'components/SignUp/Form';

import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
import { getUser, updateLead } from 'api/auth';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import { useUser } from 'context/UserContext';
import FormSkeleton from 'components/shared/FormSkeleton';

const SignUp = () => {
  const { t } = useTranslation();
  const { token, signUp, isSignUpLoading, signUpError } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateUser } = useUser();

  const quoteId = location.state?.quoteId;

  const urlUTMTags = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (Object.keys(urlUTMTags).length > 0) {
      setLocalStorage('utmTags', urlUTMTags);
    }
  }, [urlUTMTags]);

  const { data: user, isLoading } = useQuery('user-me', getUser, {
    enabled: !!token,
  });

  const {
    mutate: updateLeadMutation,
    isLoading: isUpdateLeadLoading,
    error: updateLeadError,
  } = useMutation(updateLead);

  const navigateToOTP = (email) => {
    navigate(
      {
        pathname: 'verify-otp',
        search: location.search,
      },
      { state: { email }, replace: true }
    );
  };

  const handleSubmit = (data) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phoneNumber,
      companyName,
    } = data;

    const utmTags = getLocalStorage('utmTags');

    if (utmTags) {
      delete utmTags.deviceId;
    }

    const payload = {
      firstname: firstName,
      lastname: lastName,
      email,
      password,
      password_confirmation: confirmPassword,
      phone: phoneNumber,
      company_name: companyName,
      onboarding_state: {
        utmTags,
      },
    };

    if (user?.role === 'lead') {
      updateLeadMutation(
        { ...payload, quote_id: +quoteId },
        {
          onSuccess: (data) => {
            updateUser(data.data);
            navigateToOTP(email);
          },
        }
      );
    } else {
      signUp(
        { ...payload },
        {
          onSuccess: () => {
            navigateToOTP(email);
          },
        }
      );
    }
  };

  const [firstName, lastName] = (user?.name ?? '').split(' ');

  const defaultValues = {
    firstName,
    lastName,
    email: user?.email,
  };

  return (
    <RegistrationLayout
      title={t('createAccount')}
      imageTitle={t('signUpImageTitle')}
      imageContent={t('signUpImageContent')}
      content={
        <>
          {isLoading ? (
            <FormSkeleton numberOfInputs={7} />
          ) : (
            <SignUpForm
              onSubmit={handleSubmit}
              isLoading={isSignUpLoading || isUpdateLeadLoading}
              responseError={
                signUpError?.response?.data?.error ||
                updateLeadError?.response?.data?.error
              }
              defaultValues={defaultValues}
            />
          )}
        </>
      }
    />
  );
};

export default SignUp;
